const reservationsActions = {
  //  Reservation page
  GET_RESERVATIONS: 'GET_RESERVATIONS',
  GET_STATS: 'GET_STATS',
  SET_RESERVATIONS_LOADING: 'SET_RESERVATIONS_LOADING',
  SET_STATS_LOADING: 'SET_STATS_LOADING',
  SET_RESERVATIONS_LOADED: 'SET_RESERVATIONS_LOADED',
  SET_STATS_LOADED: 'SET_STATS_LOADED',
  SET_RESERVATIONS: 'SET_RESERVATIONS',
  SET_STATS: 'SET_STATS',
  SET_FILTER: 'SET_FILTER',

  //  Reservarion Details page
  GET_RESERVATION_DETAILS: 'GET_RESERVATION_DETAILS',
  SET_RESERVATION_DETAILS_LOADING: 'SET_RESERVATION_DETAILS_LOADING',
  SET_RESERVATION_DETAILS: 'SET_RESERVATION_DETAILS',
  SET_RESERVATION_DETAILS_LOADED: 'SET_RESERVATION_DETAILS_LOADED',

  SAVE_RESERVATION_DETAILS: 'SAVE_RESERVATION_DETAILS',
  EMAIL_CLEANER: 'EMAIL_CLEANER',
  EMAIL_CLEANER_CANCELLATION: 'EMAIL_CLEANER_CANCELLATION',
  EMAIL_CHECKIN: 'EMAIL_CHECKIN',
  EMAIL_CONFIRMATION: 'EMAIL_CONFIRMATION',
  CANCEL_RESERVATION: 'CANCEL_RESERVATION',
  SHOW_GUEST_REVIEW_MODAL: 'SHOW_GUEST_REVIEW_MODAL',
  HIDE_GUEST_REVIEW_MODAL: 'HIDE_GUEST_REVIEW_MODAL',

  GET_RESERVATIONS_MONTHLY: 'GET_RESERVATIONS_MONTHLY',
  SET_RESERVATIONS_MONTHLY: 'SET_RESERVATIONS_MONTHLY',

  GET_PUBLIC_COMMENT_TEMPLATES: 'GET_PUBLIC_COMMENT_TEMPLATES',
  SET_PUBLIC_COMMENT_TEMPLATES: 'SET_PUBLIC_COMMENT_TEMPLATES',
  GET_PRIVATE_COMMENT_TEMPLATES: 'GET_PRIVATE_COMMENT_TEMPLATES',
  SET_PRIVATE_COMMENT_TEMPLATES: 'SET_PRIVATE_COMMENT_TEMPLATES',
  SAVE_GUEST_REVIEW: 'SAVE_GUEST_REVIEW',
  SET_GUEST_REVIEW_RESPONSE: 'SET_GUEST_REVIEW_RESPONSE',

  GET_DAILY_STATS: 'GET_DAILY_STATS',
  SET_DAILY_STATS: 'SET_DAILY_STATS',

  getPublicCommentTemplates(customer_reservation_id) {
    return {
      type: reservationsActions.GET_PUBLIC_COMMENT_TEMPLATES,
      customer_reservation_id,
    };
  },
  getPrivateCommentTemplates(customer_reservation_id) {
    return {
      type: reservationsActions.GET_PRIVATE_COMMENT_TEMPLATES,
      customer_reservation_id,
    };
  },
  getReservations(filterStates = {}) {
    return {
      type: reservationsActions.GET_RESERVATIONS,
      filterStates,
    };
  },
  getReservationsMonthly(date) {
    return {
      type: reservationsActions.GET_RESERVATIONS_MONTHLY,
      date,
    };
  },

  getPropertyStats(property) {
    return {
      type: reservationsActions.GET_STATS,
      property,
    };
  },

  getDailyStats(date) {
    return {
      type: reservationsActions.GET_DAILY_STATS,
      date,
    };
  },

  setFilter(filterStates) {
    return {
      type: reservationsActions.SET_FILTER,
      filterStates,
    };
  },
  getReservationDetails(reservationID) {
    return {
      type: reservationsActions.GET_RESERVATION_DETAILS,
      reservationID,
    };
  },
  emailCleanerCancellation() {
    return {
      type: reservationsActions.EMAIL_CLEANER_CANCELLATION,
    };
  },
  emailCleaner() {
    return {
      type: reservationsActions.EMAIL_CLEANER,
    };
  },
  emailCheckin() {
    return {
      type: reservationsActions.EMAIL_CHECKIN,
    };
  },
  emailConfirmation() {
    return {
      type: reservationsActions.EMAIL_CONFIRMATION,
    };
  },
  cancelReservation() {
    return {
      type: reservationsActions.CANCEL_RESERVATION,
    };
  },
  saveReservation(reservationDetails) {
    return {
      type: reservationsActions.SAVE_RESERVATION_DETAILS,
      reservationDetails,
    };
  },
  saveGuestReview(guestReview, customerReservationId) {
    return {
      type: reservationsActions.SAVE_GUEST_REVIEW,
      guestReview,
      customerReservationId,
    };
  },
  showGuestReviewModal() {
    return {
      type: reservationsActions.SHOW_GUEST_REVIEW_MODAL,
    };
  },
  hideGuestReviewModal() {
    return {
      type: reservationsActions.HIDE_GUEST_REVIEW_MODAL,
    };
  },
};

export default reservationsActions;

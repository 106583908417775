import SecureAxios from './SecureAxios';

class PaymentService {
  createPayment(paymentMethod, amount) {
    return SecureAxios.post('/server/api/v1/payments/', {
      paymentMethod: paymentMethod,
      amount: amount,
    });
  }
}

export default new PaymentService();

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faExLink from '@fortawesome/fontawesome-free-solid/faExternalLinkSquareAlt';
import { Link } from 'react-router-dom';

import _ from 'lodash';

//  Actions
import tasksActions from '../../redux/tasks/actions';
import { isMobile } from '../../redux/app/actions';
//  Components
import HeaderSort from '../../components/common/HeaderSort';
import LoadingAnimation from '../../components/common/LoadingAnimation';
//  Styles
import '../../scss/tasks.scss';

const { getTasks, setFilter } = tasksActions;

class Tasks extends Component {
  state = {
    tasksSearch: '',
  };

  /**
   * Fetch data on mount
   */
  componentDidMount() {
    this.getTasks();
  }

  /**
   * Update data
   */
  componentDidUpdate(prevProps, prevState) {
    const { tasksSearch } = this.state;
    const { tasksSearch: prevtasksSearch } = prevState;
    const { user, filterStates } = this.props;
    const { user: prevUser } = prevProps;

    if (!_.isEqual(user, prevUser)) {
      this.getTasks();
    }

    if (tasksSearch !== prevtasksSearch) {
      filterStates.criteria.search = tasksSearch;
      this.setFilter(filterStates);
    }
  }

  getTasks = () => {
    const { getTasks } = this.props;
    getTasks();
  };

  setFilter = filterStates => {
    const { setFilter } = this.props;
    setFilter(filterStates);
  };

  /**
   * Handle search input
   *
   * @param {object} event
   */
  handleSearchChange = event => {
    if (event.target.value.length === 0) {
      this.setState({
        tasksSearch: '',
      });
    } else {
      this.setState({
        tasksSearch: event.target.value,
      });
    }
  };

  render() {
    const { tasks, filterStates, tasksLoading, view } = this.props;
    const { tasksSearch } = this.state;

    const mobile = isMobile(view);

    const cardClass = `relative rounded-lg bg-white shadow-vp-default flex flex-col ${
      mobile ? 'overflow-hidden w-auto p-8' : 'p-50px m-2 mb-0'
    }`;
    const titleClassName = `flex justify-between  ${
      mobile ? 'flex-col pb-8' : 'pb-50px'
    }`;
    const tableContainerClass = `${
      mobile ? 'w-full overflow-x-scroll -ml-50px' : 'm-4 w-full'
    }`;
    const tableClass = `text-left ${mobile ? '' : 'min-w-full'}`;
    const rowClass = `cursor-pointer`;
    const cellClass = `border-b border-grey-light ${
      mobile ? 'py-2 px-4' : 'py-4 px-6'
    }`;

    const sorting = filterStates.sorts;

    const filteredSorted = _(tasks ? tasks : [])
      .filter(task => {
        //  Return true if search is not defined
        if (tasksSearch.length === 0) {
          return true;
        }

        const name = String(
          task.customer.first_name + ' ' + task.customer.last_name
        ).toLowerCase();
        const id = String(task.id);
        const arrival = moment(task.arrival)
          .utc()
          .format('MM-DD-YYYY');
        const departure = moment(task.departure)
          .utc()
          .format('MM-DD-YYYY');
        const market = task.market.name.toLowerCase();
        const propName = task.property.name.toLowerCase();

        if (
          name.indexOf(tasksSearch) !== -1 ||
          id.indexOf(tasksSearch) !== -1 ||
          arrival.indexOf(tasksSearch) !== -1 ||
          departure.indexOf(tasksSearch) !== -1 ||
          market.indexOf(tasksSearch) !== -1 ||
          propName.indexOf(tasksSearch) !== -1
        ) {
          return true;
        } else {
          return false;
        }
      })
      .orderBy([sorting.col], [sorting.direction])
      .value();

    // console.log(filteredSorted);
    // console.log(filteredSorted.length > 0);
    const tasksTable =
      filteredSorted &&
      filteredSorted.length > 0 &&
      filteredSorted.map(task => (
        <tr
          key={task.id}
          className={rowClass + ' hover:bg-grey-backdrop-transparent '}
          // onClick={() => {
          //   this.reservationDetail(reservation.id);
          // }}
        >
          <td className={cellClass}>{task.customer.last_name}</td>
          <td className={cellClass}>
            {moment(task.arrival)
              .utc()
              .format('MM-DD-YYYY')}
          </td>
          <td className={cellClass}>
            {moment(task.departure)
              .utc()
              .format('MM-DD-YYYY')}
          </td>
          <td className={cellClass}>{task.property.name}</td>
          <td className={cellClass}>
            {task.market && task.market.name === 'AirBnb' && (
              <a
                className="d-flex justify-content-between"
                href={task.market.url + 'z/q/' + task.airbnb_thread_id}
                target="_blank"
                rel="noopener noreferrer"
              >
                {task.market.name} <FontAwesomeIcon icon={faExLink} />
              </a>
            )}
            {task.market && task.market.name === 'VRBO' && (
              <a
                className="d-flex justify-content-between"
                href={task.vrbo_thread_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {task.market.name} <FontAwesomeIcon icon={faExLink} />
              </a>
            )}
          </td>
          <td className={cellClass}>
            <Link to={`/reservations/${task.id}`}>{task.action}</Link>
          </td>
        </tr>
      ));

    return (
      <div className="flex flex-col w-full h-full overflow-x-hidden">
        <div className={cardClass + ' flex-no-shrink'}>
          <div className={titleClassName}>
            <h1>Tasks</h1>
            <input
              type="text"
              placeholder="search..."
              className={`form-control ${mobile ? 'h-8 mt-4' : ''}`}
              value={tasksSearch}
              onChange={this.handleSearchChange}
            />
          </div>

          {tasksLoading && <LoadingAnimation />}

          {tasks && tasks.length > 0 && (
            <>
              <div className={tableContainerClass}>
                <table className={tableClass}>
                  <thead>
                    <tr className={rowClass}>
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="Last Name"
                        column="customer.last_name"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="Check In"
                        column="arrival"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="Check Out"
                        column="departure"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="Property"
                        column="id"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="Source"
                        column="id"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="Action"
                        column="id"
                      />
                    </tr>
                  </thead>
                  <tbody>{tasksTable}</tbody>
                </table>
              </div>
            </>
          )}

          {tasks && tasks.length <= 0 && <h4>No tasks found</h4>}
        </div>

        <div
          className={
            'flex justify-between align-start my-2.5 ' +
            (mobile ? 'flex-wrap' : 'mb-7')
          }
        ></div>
      </div>
    );
  }
}

export default connect(
  state => ({
    //  Current user
    user: state.Auth.currentUser,
    //  View
    view: state.App.view,
    //  Tasks state
    ...state.Tasks,
  }),
  { getTasks, setFilter }
)(withRouter(Tasks));

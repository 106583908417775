import React, { Component } from 'react';
import { connect } from 'react-redux';
import MomentUtils from '@date-io/moment';
import { Link } from 'react-router-dom';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { isMobile } from '../../redux/app/actions';
import { withRouter } from 'react-router';
import _ from 'lodash';
import moment from 'moment';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import reportsActions from '../../redux/reports/actions';
import LoadingAnimation from '../../components/common/LoadingAnimation';

import ReportGraph from '../../components/graphs/report-graph/ReportGraph';

//  Icons
import { faChevronLeft } from '@fortawesome/fontawesome-free-solid';

const { runReport } = reportsActions;

function isNull(value) {
  return value == null;
}

export class ReportDetails extends Component {
  state = {
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: new Date(new Date().getFullYear() + 1, 0, 1),
  };

  runReport = () => {
    const { runReport } = this.props;
    const { startDate, endDate } = this.state;
    const { id } = this.props.match.params;

    runReport(
      id,
      moment(startDate).format('YYYY-MM-DD'),
      moment(endDate).format('YYYY-MM-DD')
    );
  };

  constructor(props) {
    super(props);

    this.actionsAnchor = React.createRef();
  }

  componentDidMount() {
    this.runReport();
  }

  componentDidUpdate(prevProps, prevState) {
    const { user } = this.props;
    const { startDate, endDate } = this.state;
    const { user: prevUser } = prevProps;
    const { startDate: prevStartDate, endDate: prevEndDate } = prevState;

    if (
      !_.isEqual(user, prevUser) ||
      !_.isEqual(startDate, prevStartDate) ||
      !_.isEqual(endDate, prevEndDate)
    ) {
      this.runReport();
    }
  }

  handleStartChange = date => {
    this.setState({
      startDate: date,
    });
  };

  handleEndChange = date => {
    this.setState({
      endDate: date,
    });
  };

  render() {
    const { reportDetails, reportDetailsLoading, view } = this.props;
    const { startDate, endDate } = this.state;

    const mobile = isMobile(view);

    const cardClass = `relative rounded-lg bg-white shadow-vp-default flex flex-col ${
      mobile ? 'overflow-hidden w-auto p-8 mb-2.5' : 'p-50px m-2 mb-7'
    }`;
    const titleClassName = `flex justify-between  ${
      mobile ? 'flex-col pb-8' : 'pb-50px'
    }`;
    const textFieldClass = `mb-8  ${mobile ? 'w-full' : 'pr-8 w-1/3'}`;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className="flex flex-col w-full h-full overflow-x-hidden">
          <div
            className={`w-full flex-no-shrink ${
              mobile ? 'mb-2.5 text-lg' : 'mb-6'
            }`}
          >
            <Link to={`/reports`}>
              <FontAwesomeIcon icon={faChevronLeft} /> Back to Reports
            </Link>
          </div>

          {/* View section */}
          <div className={cardClass + ' flex-no-shrink'}>
            <div className={titleClassName}>
              {/* Title */}
              <h1>
                {reportDetailsLoading || isNull(reportDetails) ? (
                  'Loading...'
                ) : (
                  <>{reportDetails.report.name}</>
                )}
              </h1>

              <div className={textFieldClass}>
                <KeyboardDatePicker
                  id="start-date"
                  label="Start Date"
                  placeholder="2018/10/10"
                  className={'w-full'}
                  value={moment(startDate).utc()}
                  onChange={this.handleStartChange}
                  format="MM-DD-YYYY"
                />
              </div>
              <div className={textFieldClass}>
                <KeyboardDatePicker
                  id="end-date"
                  label="End Date"
                  placeholder="2018/10/10"
                  className={'w-full'}
                  value={moment(endDate).utc()}
                  onChange={this.handleEndChange}
                  format="MM-DD-YYYY"
                />
              </div>
            </div>

            {reportDetailsLoading || isNull(reportDetails) ? (
              <LoadingAnimation />
            ) : (
              <>
                <div className="w-full flex flex-wrap"></div>
                <ReportGraph
                  data={reportDetails.data}
                  report={reportDetails.report}
                />
              </>
            )}
          </div>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default connect(
  state => ({
    user: state.Auth.currentUser,
    reportDetails: state.Reports.reportDetails,
    reportDetailsLoading: state.Reports.reportDetailsLoading,
    view: state.App.view,
  }),
  {
    runReport,
  }
)(withRouter(ReportDetails));

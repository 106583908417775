import { all } from 'redux-saga/effects';
import appSagas from './app/saga';
import authSagas from './auth/saga';
// import settingsSagas from './settings/saga';
import propertiesSagas from './properties/saga';
// import listingsSagas from './listings/saga';
// import cardsSagas from './card/saga';
// import competitionSaga from './competition/saga';
// import filterSagas from "./filter/saga";
// import statSagas from "./map/saga";

import reservationsSagas from './reservations/saga';
import tasksSagas from './tasks/saga';
import payoutsSagas from './payouts/saga';
import reportsSagas from './reports/saga';
import taxesSagas from './taxes/saga';

export default function* rootSaga(getState) {
  yield all([
    appSagas(),
    authSagas(),
    reservationsSagas(),
    taxesSagas(),
    propertiesSagas(),
    payoutsSagas(),
    reportsSagas(),
    tasksSagas(),
    // settingsSagas(),
    // cardsSagas(),
    // listingsSagas(),
    // competitionSaga(),
    // filterSagas(),
    // statSagas()
  ]);
}

const taxesActions = {
    //  Taxes page
    GET_TAXES: "GET_TAXES",
    SET_TAXES: "SET_TAXES",
    SET_TAXES_LOADING: "SET_TAXES_LOADING",
    SET_TAXES_LOADED: "SET_TAXES_LOADED",

    getTaxes() {
        return {
            type: taxesActions.GET_TAXES
        };
    }
};

export default taxesActions;
import _ from 'lodash';
import actions from './actions';

const initState = {
  taxes: null,
  taxesLoading: true,
};

/**
 *  Taxes data comes in columns.
 *
 *  Values are mapped to [ key, value ] array pairs to preserve
 *  order during iteration
 *
 *  @param {object} taxes - taxes response from API
 */
function processData(taxes) {
  let totals = [];
  let properties = {};

  //  Iterate over taxes data
  _.each(taxes, (yearData, key) => {
    const year = parseInt(key, 10);
    //  Group for columns by year and quarter
    _.each(yearData, (quarterData, key) => {
      if (key === 'totals') {
        //  Push to totals array
        totals.push([`${year} Total`, _.toPairs(quarterData)]);

        _.each(quarterData.properties, (propertyData, key) => {
          if (typeof properties[key] === 'undefined') {
            properties[key] = [];
          }
          //  Structure of property data by ID is identical to totals data
          properties[key].push([
            `${year} Total`,
            _.toPairs(propertyData.fields),
          ]);
        });
      } else {
        const quarter = parseInt(key, 10);
        //  Push to totals array
        totals.push([`Q${quarter} ${year}`, _.toPairs(quarterData.totals)]);
        //  Also group properties by their ID
        _.each(quarterData.totals.properties, (propertyData, key) => {
          if (typeof properties[key] === 'undefined') {
            properties[key] = [];
          }
          //  Structure of property data by ID is identical to totals data
          properties[key].push([
            `Q${quarter} ${year}`,
            _.toPairs(propertyData.fields),
          ]);
        });
      }
    });
  });

  return { totals, properties };
}

export default function taxesReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_TAXES:
      return {
        ...state,
        taxes: processData(action.taxes),
      };
    case actions.SET_TAXES_LOADED:
      return {
        ...state,
        taxesLoading: false,
      };
    case actions.SET_TAXES_LOADING:
      return {
        ...state,
        taxesLoading: true,
      };
    default:
      return {
        ...state,
      };
  }
}

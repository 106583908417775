import React from 'react';
import { connect } from 'react-redux';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faCaretUp from '@fortawesome/fontawesome-free-solid/faCaretUp';
import faCaretDown from '@fortawesome/fontawesome-free-solid/faCaretDown';

//  Actions
import propertyActions from '../../redux/properties/actions';

const { setDetailFilter } = propertyActions;

class HeaderPropertyDetailSort extends React.Component {
  sortColumn = event => {
    const {
      name,
      column: col,
      filterDetailStates,
      setDetailFilter,
    } = this.props;

    const newFilter = {
      ...filterDetailStates,
      sorts: {
        name,
        col,
        direction:
          filterDetailStates.sorts.name === name
            ? filterDetailStates.sorts.direction === 'asc'
              ? 'desc'
              : 'asc'
            : 'asc',
      },
    };

    setDetailFilter(newFilter);
  };

  render() {
    const { className, name, filterDetailStates } = this.props;

    const selected = name === filterDetailStates.sorts.name;
    const asc = filterDetailStates.sorts.direction === 'asc';

    return (
      <th className={className} nowrap="nowrap" onClick={this.sortColumn}>
        {name}{' '}
        {selected && <FontAwesomeIcon icon={asc ? faCaretDown : faCaretUp} />}
      </th>
    );
  }
}

export default connect(
  state => ({
    filterDetailStates: state.Properties.filterDetailStates,
  }),
  { setDetailFilter }
)(HeaderPropertyDetailSort);

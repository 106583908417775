// eslint-disable-next-line
import React from 'react';

export const messages = {
  "sidebar.email": "البريد الإلكتروني",
  "sidebar.chat": "Chat",
  "sidebar.ecommerce": "التجارة الإلكترونية",
  "sidebar.shop": "متجر",
  "sidebar.cart": "عربة التسوق",
  "sidebar.checkout": "الدفع",
  "sidebar.cards": "بطاقات",
  "sidebar.maps": "خرائط",
  "sidebar.googleMap": "خرائط جوجل",
  "sidebar.leafletMap": "خريطة النشرة",
  "sidebar.calendar": "التقويم",
  "sidebar.notes": "ملاحظات",
  "sidebar.todos": "تودوس",
  "sidebar.contacts": "جهات الاتصال",
  "sidebar.shuffle": "خلط",
  "sidebar.charts": "الرسوم البيانية",
  "sidebar.googleCharts": "عربات غوغل",
  "sidebar.recharts": "Recharts",
  "sidebar.reactVis": "رد فيس",
  "sidebar.reactChart2": "الرد-مخطط-2",
  "sidebar.reactTrend": "الرد-تريند",
  "sidebar.eChart": "Echart",
  "sidebar.forms": "إستمارات",
  "sidebar.input": "إدخال",
  "sidebar.editor": "محرر",
  "sidebar.formsWithValidation": "نماذج مع التحقق من الصحة",
  "sidebar.progress": "تقدم",
  "sidebar.button": "زر",
  "sidebar.tab": "التبويب",
  "sidebar.checkbox": "مربع",
  "sidebar.radiobox": "Radiobox",
  "sidebar.transfer": "تحويل",
  "sidebar.autocomplete": "الإكمال التلقائي",
  "sidebar.boxOptions": "خيارات مربع",
  "sidebar.uiElements": "عناصر واجهة المستخدم",
  "sidebar.badge": "شارة",
  "sidebar.card2": "بطاقة",
  "sidebar.corusel": "إحتفال صاخب مخمور",
  "sidebar.collapse": "انهدام",
  "sidebar.popover": "البوب",
  "sidebar.tooltip": "تلميح",
  "sidebar.tag": "بطاقة",
  "sidebar.timeline": "الجدول الزمني",
  "sidebar.dropdown": "اسقاط",
  "sidebar.pagination": "ترقيم الصفحات",
  "sidebar.rating": "تقييم",
  "sidebar.tree": "شجرة",
  "sidebar.advancedElements": "العناصر المتقدمة",
  "sidebar.reactDates": "تواريخ الاستجابة",
  "sidebar.codeMirror": "كود مرآة",
  "sidebar.uppy": "وبي رافع",
  "sidebar.dropzone": "إسقاط المنطقة",
  "sidebar.feedback": "ردود الفعل",
  "sidebar.alert": "محزر",
  "sidebar.modal": "شكلي",
  "sidebar.message": "رسالة",
  "sidebar.notification": "إعلام",
  "sidebar.popConfirm": "تأكيد البوب",
  "sidebar.spin": "غزل",
  "sidebar.tables": "الجداول",
  "sidebar.antTables": "جداول النمل",
  "sidebar.pages": "صفحات",
  "sidebar.500": "500",
  "sidebar.404": "404",
  "sidebar.signIn": "تسجيل الدخول",
  "sidebar.signUp": "سجل",
  "sidebar.forgotPw": "نسيت كلمات المرور",
  "sidebar.resetPw": "إعادة تعيين كلمات المرور",
  "sidebar.invoice": "فاتورة",
  "sidebar.menuLevels": "مستويات القائمة",
  "sidebar.item1": "البند 1",
  "sidebar.item2": "البند 2",
  "sidebar.option1": "الخيار 1",
  "sidebar.option2": "الخيار 2",
  "sidebar.option3": "الخيار 3",
  "sidebar.option4": "الخيار 4",
  "sidebar.blankPage": "صفحة فارغة",
  "sidebar.githubSearch": "جيثب البحث",
  "sidebar.youtubeSearch": "بحث يوتيوب",
  "languageSwitcher.label": "غير اللغة",
  "themeSwitcher": "موضوع الجلاد",
  "themeSwitcher.Sidebar": "الشريط الجانبي",
  "themeSwitcher.Topbar": "Topbar",
  "themeSwitcher.Background": "خلفية",
  "feedback.alert.basicTitle": "العنوان الأساسي",
  "feedback.alert.successText": "نص النجاح",
  "feedback.alert.infoText": "نص المعلومات",
  "feedback.alert.warningText": "نص التحذير",
  "feedback.alert.errorText": "نص خطأ",
  "feedback.alert.closableAlertType": "نوع التنبيه القابل للإغلاق",
  "feedback.alert.iconAlertType": "رمز نوع التنبيه",
  "feedback.alert.iconInfoAlertType": "رمز معلومات نوع التنبيه",
  "feedback.alert.successTips": "نصائح النجاح",
  "feedback.alert.successTipsDescription":
    "وصف مفصل ونصائح حول الكتابة الناجحة.",
  "feedback.alert.informationTips": "ملاحظات إعلامية",
  "feedback.alert.informationDescription":
    "وصف إضافي ومعلومات حول كتابة النصوص.",
  "feedback.alert.warningTips": "تحذير",
  "feedback.alert.warningDescription": "هذا إشعار تحذير حول كتابة النصوص.",
  "feedback.alert.errorTips": "خطأ",
  "feedback.alert.errorDescription": "هذه رسالة خطأ حول كتابة النصوص.",
  "feedback.alert.modalTitle": "مشروط واحد مع تخصيص تذييل الصفحة",
  "feedback.alert.modalSubTitle": "مربع حوار أساسي مشروط.",
  "feedback.alert.successTitle": "نجاح",
  "feedback.alert.infoTitle": "معلومات",
  "feedback.alert.errorTitle": "خطأ",
  "feedback.alert.warningTitle": "تحذير",
  "feedback.alert.modalBlockTitle": "شكلي",
  "feedback.alert.confirmationModalDialogue": "حوار مشروط التأكيد",
  "feedback.alert.simpleModalDialogue": "حوار مشروط بسيط",
  "feedback.alert.message": "رسالة",
  "feedback.alert.normalMessageTitle": "رسالة عادية",
  "feedback.alert.normalMessageSubtitle": "الرسائل العادية كتعليقات.",
  "feedback.alert.displayMessage": "عرض الرسالة العادية",
  "feedback.alert.displayOtherTypeMessageTitle": "أنواع أخرى من الرسائل",
  "feedback.alert.displayOtherTypeMessageSubTitle":
    "رسائل النجاح وأنواع الأخطاء والإنذارات.",
  "feedback.alert.customizeDurationTitle": "تخصيص المدة",
  "feedback.alert.customizeDurationSubTitle":
    "أوستميز مدة عرض الرسالة من الافتراضي 1.5s إلى 10s.",
  "feedback.alert.customizeDurationButton": "تخصيص مدة العرض",
  "feedback.alert.messageLoadingTitle": "رسالة التحميل",
  "feedback.alert.messageLoadingSubTitle":
    "عرض مؤشر تحميل عالمي، يتم رفضه في حد ذاته بشكل غير متزامن.",
  "feedback.alert.displayLoadIndicator": "عرض مؤشر التحميل",
  "feedback.alert.notification": "إعلام",
  "feedback.alert.notificationBasicTitle": "الأساسية",
  "feedback.alert.notificationBasicSubTitle":
    "أبسط الاستخدام الذي يغلق مربع الإعلام بعد 4.5S.",
  "feedback.alert.notificationBasicDescription": "افتح مربع الإشعارات",
  "feedback.alert.notificationDurationTitle":
    "المدة التي يتم بعدها إغلاق مربع الإشعارات",
  "feedback.alert.notificationDurationSubTitle":
    "يمكن استخدام المدة لتحديد المدة التي يبقى فيها الإشعار مفتوحا. بعد انقضاء المدة الزمنية، يتم إغلاق الإشعار تلقائيا. إذا لم يتم تحديد القيمة الافتراضية هي 4.5 ثانية. إذا قمت بتعيين القيمة إلى 0، لن يتم إغلاق مربع الإشعار تلقائيا تلقائيا.",
  "feedback.alert.notificationwithIconTitle": "إعلام مع رمز",
  "feedback.alert.notificationwithIconSubTitle":
    "مربع إعلام مع رمز على الجانب الأيسر.",
  "feedback.alert.notificationwithCustomIconTitle": "إعلام مع رمز مخصص",
  "feedback.alert.notificationwithCustomIconSubTitle":
    "الرسائل العادية كتعليقات.",
  "feedback.alert.notificationwithCustomButtonTitle": "إعلام مع زر مخصص",
  "feedback.alert.notificationwithCustomButtonSubTitle":
    "الرسائل العادية كتعليقات.",
  "feedback.alert.popConfirm": "تأكيد البوب",
  "feedback.alert.popConfirm.basicTitle": "تأكيد أساسي",
  "feedback.alert.popConfirm.basicSubTitle": "المثال الأساسي.",
  "feedback.alert.popConfirm.delete": "حذف",
  "feedback.alert.popConfirm.notiWithIconTitle": "إعلام مع رمز مخصص",
  "feedback.alert.popConfirm.notiWithIconSubTitle": "الرسائل العادية كتعليقات.",
  "feedback.alert.popConfirm.TL": "TL",
  "feedback.alert.popConfirm.top": "أعلى",
  "feedback.alert.popConfirm.TR": "TR",
  "feedback.alert.popConfirm.LT": "LT",
  "feedback.alert.popConfirm.left": "اليسار",
  "feedback.alert.popConfirm.LB": "رطل",
  "feedback.alert.popConfirm.RT": "RT",
  "feedback.alert.popConfirm.right": "حق",
  "feedback.alert.popConfirm.RB": "RB",
  "feedback.alert.popConfirm.Bl": "BL",
  "feedback.alert.popConfirm.bottom": "الأسفل",
  "feedback.alert.popConfirm.BR": "BR",
  "feedback.alert.spin": "غزل",
  "feedback.alert.spin.basicTitle": "حجم تدور",
  "feedback.alert.spin.background": "تدور مع الخلفية",
  "feedback.alert.spin.backgroundDescription": "تدور مع وصف الخلفية",
  "feedback.alert.spin.loadingState": "تحميل الدولة",
  "feedback.alert.spin.alertTitle": "عنوان رسالة التنبيه",
  "feedback.alert.spin.alertDescription":
    "مزيد من التفاصيل حول سياق هذا التنبيه.",
  "forms.input.header": "إدخال",
  "forms.input.basicTitle": "الاستخدام الأساسي",
  "forms.input.basicSubTitle": "مثال الاستخدام الأساسي.",
  "forms.input.variationsTitle": "ثلاثة أحجام من الإدخال",
  "forms.input.variationsSubtitle":
    "هناك ثلاثة أحجام من مربع الإدخال  كبير (42px  ، الافتراضي (35px   والصغيرة (30px  . ملاحظة  داخل النماذج، يتم استخدام الحجم الكبير فقط.",
  "forms.input.groupTitle": "مجموعة الإدخال",
  "forms.input.groupSubTitle":
    "الإدخال. مثال المجموعة ملاحظة  أنت لا تحتاج كول للتحكم في العرض في الوضع المضغوط.",
  "forms.input.autoSizingTitle": "يمكنك ضبط مستوى الارتفاع لتتناسب مع المحتوى",
  "forms.input.autoSizingSubTitle":
    "أوتوسيزي دعامة لنوع تكستاريا من المدخلات يجعل ارتفاع لضبط تلقائيا استنادا إلى المحتوى. يمكن توفير كائن خيارات ل أوتوسيزي لتحديد الحد الأدنى والحد الأقصى لعدد الخطوط التي سوف تكستاريا ضبط تلقائيا.",
  "forms.input.prePostTabTitle": "علامة التبويب ما قبل    المشاركة",
  "forms.input.prePostTabSubTitle":
    "استخدام بري & أمب؛ نموذج علامات تبويب المشاركات ..",
  "forms.input.textAreaTitle": "ناحية النص",
  "forms.input.textAreaSubTitle":
    "بالنسبة لحالات إدخال المستخدم متعدد الخطوط، يمكن استخدام المدخلات التي لها قيمة دعامة قيمة تكستاريا.",
  "forms.input.searchTitle": "بحث",
  "forms.input.searchSubTitle":
    "مثال لإنشاء مربع بحث عن طريق تجميع إدخال قياسي باستخدام زر بحث",
  "forms.editor.header": "محرر",
  "forms.formsWithValidation.header": "نموذج التحقق المخصص",
  "forms.formsWithValidation.failLabel": "فشل",
  "forms.formsWithValidation.failHelp":
    "يجب أن يكون مزيج من الأرقام & أمب؛ الحروف الهجائية",
  "forms.formsWithValidation.warningLabel": "تحذير",
  "forms.formsWithValidation.ValidatingLabel": "التحقق من صحة",
  "forms.formsWithValidation.ValidatingHelp": "جار التحقق من صحة المعلومات ...",
  "forms.formsWithValidation.SuccessLabel": "نجاح",
  "forms.formsWithValidation.WarninghasFeedbackLabel": "تحذير",
  "forms.formsWithValidation.FailhasFeedbackLabel": "فشل",
  "forms.formsWithValidation.FailhasFeedbackHelp":
    "يجب أن يكون مزيج من الأرقام & أمب؛ الحروف الهجائية",
  "forms.progressBar.header": "شريط التقدم",
  "forms.progressBar.standardTitle": "شريط التقدم",
  "forms.progressBar.standardSubTitle": "شريط تقدم قياسي.",
  "forms.progressBar.circularTitle": "شريط التقدم الدائري",
  "forms.progressBar.circularSubTitle": "شريط تقدم دائري.",
  "forms.progressBar.miniTitle": "ميني حجم شريط التقدم",
  "forms.progressBar.miniSubTitle": "مناسبة لمنطقة ضيقة.",
  "forms.progressBar.miniCircularTitle": "شريط تقدم دائري أصغر.",
  "forms.progressBar.dynamicCircularTitle": "ديناميكية شريط التقدم دائرية",
  "forms.progressBar.dynamicCircularSubTitle":
    "شريط التقدم الديناميكي هو أفضل.",
  "forms.progressBar.customTextTitle": "تنسيق نص مخصص",
  "forms.progressBar.customTextSubTitle":
    "يمكنك تنسيق النص المخصص عن طريق وضع التنسيق.",
  "forms.progressBar.dashboardTitle": "لوحة القيادة",
  "forms.progressBar.dashboardSubTitle": "نمط لوحة القيادة للتقدم.",
  "forms.button.header": "وصفت",
  "forms.button.simpleButton": "نوع الزر",
  "forms.button.iconButton": "رمز الزر",
  "forms.button.simpleButtonPrimaryText": "ابتدائي",
  "forms.button.simpleButtonDefaultText": "افتراضي",
  "forms.button.simpleButtonDashedText": "متقطع",
  "forms.button.simpleButtonDangerText": "خطر",
  "forms.button.iconPrimaryButton": "بحث",
  "forms.button.iconSimpleButton": "بحث",
  "forms.button.iconCirculerButton": "بحث",
  "forms.button.iconDashedButton": "بحث",
  "forms.button.SizedButton": "حجم الزر",
  "forms.button.DisabledButton": "تم تعطيل الزر",
  "forms.button.LoadingButton": "زر التحميل",
  "forms.button.MultipleButton": "زر متعددة",
  "forms.button.groupButton": "زر المجموعة",
  "forms.Tabs.header": "علامات التبويب",
  "forms.Tabs.simpleTabTitle": "بحث",
  "forms.Tabs.simpleTabSubTitle": "تم تعطيل علامات التبويب",
  "forms.Tabs.iconTabTitle": "أيقونة علامات التبويب",
  "forms.Tabs.miniTabTitle": "ميني علامات التبويب",
  "forms.Tabs.extraTabTitle": "علامات تبويب إضافية للعمل",
  "forms.Tabs.TabpositionTitle": "موضع",
  "forms.Tabs.TabpositionSubTitle":
    "موضع علامات التبويب  يسار أو يمين أو أعلى أو أسفل",
  "forms.Tabs.cardTitle": "نوع بطاقة علامات التبويب",
  "forms.Tabs.editableTitle": "إضافة علامات التبويب وإغلاقها",
  "forms.Tabs.verticalTitle": "عمودي نوع علامات التبويب",
  "forms.Tabs.basicTitle": "علامات التبويب الأساسية",
  "forms.checkbox.header": "مربع",
  "forms.checkbox.basicTitle": "مربع الاختيار الأساسي",
  "forms.checkbox.basicSubTitle": "الاستخدام الأساسي لخانة الاختيار.",
  "forms.checkbox.groupTitle": "مربع الاختيار",
  "forms.checkbox.groupSubTitle":
    "إنشاء مجموعة من مربعات الاختيار من صفيف. استخدم تعطيل لتعطيل مربع اختيار.",
  "forms.checkbox.groupCheckTitle": "مربع الاختيار",
  "forms.checkbox.groupCheckSubTitle":
    "إنشاء مجموعة من مربعات الاختيار من صفيف. استخدم تعطيل لتعطيل مربع اختيار.",
  "forms.radio.header": "راديو",
  "forms.radio.simpleTitle": "الراديو الأساسي",
  "forms.radio.simpleSubTitle": "أبسط استخدام. استخدام تعطيل لتعطيل الراديو.",
  "forms.radio.groupTitle": "عمودي راديوغروب",
  "forms.radio.groupSubTitle": "عمودي راديوغروب، مع المزيد من أجهزة الراديو.",
  "forms.radio.groupSecondTitle": "RadioGroup",
  "forms.radio.groupSecondSubTitle": "مجموعة من مكونات الراديو.",
  "forms.radio.groupThirdTitle": "RadioGroup",
  "forms.radio.groupThirdSubTitle": "مجموعة من مكونات الراديو.",
  "forms.transfer.header": "تحويل",
  "forms.transfer.SubTitle": "نقل مع مربع البحث.",
  "forms.transfer.Title": "بحث",
  "forms.autocomplete.header": "الإكمال التلقائي",
  "forms.autocomplete.simpleTitle": "حسب الطلب",
  "forms.autocomplete.simpleSubTitle":
    "هل يمكن أن تمر AutoComplete.Option كأطفال الإكمال التلقائي، بدلا من استخدام داتسورس",
  "forms.autocomplete.customizeTitle": "تخصيص مكون الإدخال",
  "forms.autocomplete.customizeSubTitle": "تخصيص مكون الإدخال",
  "uiElements.badge.badge": "شارة",
  "uiElements.badge.basicExample": "مثال أساسي",
  "uiElements.badge.basicExampleSubTitle":
    "أبسط الاستخدام. سيتم إخفاء شارة عندما العد هو 0، ولكن يمكننا استخدام شوزيرو لإظهار ذلك.",
  "uiElements.badge.overflowCount": "تجاوز عدد",
  "uiElements.badge.overflowCountSubTitle":
    "يتم عرض أوفيرفلوكونت عندما يكون عدد أكبر من أوفيرفلوكونت. القيمة الافتراضية ل أوفيرفلوكونت هي 99.",
  "uiElements.badge.status": "الحالة",
  "uiElements.badge.statusSubTitle": "شارة مستقلة مع الوضع.",
  "uiElements.badge.success": "نجاح",
  "uiElements.badge.error": "خطأ",
  "uiElements.badge.default": "افتراضي",
  "uiElements.badge.processing": "معالجة",
  "uiElements.badge.warning": "تحذير",
  "uiElements.badge.redBadge": "شارة حمراء",
  "uiElements.badge.redBadgeSubTitle":
    "وهذا ببساطة عرض شارة حمراء، دون عدد معين.",
  "uiElements.badge.linkSomething": "ربط شيء",
  "uiElements.cards.cards": "بطاقات",
  "uiElements.cards.basicCard": "بطاقة أساسية",
  "uiElements.cards.basicCardSubTitle":
    "بطاقة أساسية تحتوي على عنوان ومحتوى ومحتوى زاوية إضافي.",
  "uiElements.cards.more": "أكثر من",
  "uiElements.cards.cardTitle": "عنوان البطاقة",
  "uiElements.cards.cardContent": "محتوى البطاقة",
  "uiElements.cards.lorem":
    "لوريم إيبسوم دولور سيت أميت، كونسكتيتور أديبيسيسينغ إليت، سيد دو إيسمود تيمبور إنسيدونت أوت لابور إت دولور ماغنا أليكوا. أوت إنيم أد مينيم فينيام، كويس نوسترود إكسيرسيساتيون أولامكو لابوريس نيسي أوت أليكيب إكس إي كومودو ثوسكات.",
  "uiElements.cards.noBorder": "ليس لها حدود",
  "uiElements.cards.noBorderSubTitle": "بطاقة بلا حدود على خلفية رمادية.",
  "uiElements.cards.gridCard": "بطاقة الشبكة",
  "uiElements.cards.gridCardSubTitle":
    "بطاقات عادة ما تتعاون مع تخطيط الشبكة في صفحة نظرة عامة.",
  "uiElements.cards.loadingCard": "تحميل البطاقة",
  "uiElements.cards.loadingCardSubTitle":
    "لعرض مؤشر التحميل أثناء جلب محتويات البطاقة.",
  "uiElements.cards.whateverContent": "أيا كان المحتوى",
  "uiElements.cards.customizedContentTitle": "محتوى مخصص",
  "uiElements.cards.customizedContent":
    "لعرض مؤشر التحميل أثناء جلب محتويات البطاقة.",
  "uiElements.cards.europeStreetBeat": "فاز شارع أوروبا",
  "uiElements.cards.instagram": "www.instagram.com",
  "uiElements.carousel.carousel": "إحتفال صاخب مخمور",
  "uiElements.carousel.verticalCarousel": "دائري عمودي",
  "uiElements.carousel.verticalCarouselSubTitle":
    "ترقيم الصفحات الرأسي. استخدم   فرتيكال = ترو",
  "uiElements.carousel.basicCarousel": "دائري الأساسي",
  "uiElements.carousel.basicCarouselSubTitle": "الاستخدام الأساسي",
  "uiElements.carousel.fadeInTransition": "تتلاشى في الانتقال",
  "uiElements.carousel.fadeInTransitionSubTitle":
    "الشرائح استخدام تتلاشى للانتقال.   تأثير = تتلاشى",
  "uiElements.carousel.scrollAutomatically": "مرر تلقائيا",
  "uiElements.carousel.scrollAutomaticallySubTitle":
    "توقيت التمرير إلى بطاقة المقبل    الصورة. تشغيل تلقائي",
  "uiElements.collapse.collapse": "انهدام",
  "uiElements.collapse.collapseSubTitle":
    "يمكن توسيع أكثر من لوحة واحدة في وقت واحد، يتم تهيئة اللوحة الأولى لتكون نشطة في هذه الحالة. وس   ديفولتاكتيفيكي =   [كينوم]",
  "uiElements.collapse.text":
    "الكلب هو نوع من الحيوانات المستأنسة. المعروفة عن ولائها والإخلاص، ويمكن العثور عليها كضيف ترحيب في العديد من الأسر في جميع أنحاء العالم.",
  "uiElements.collapse.headerOne": "هذا هو رأس اللوحة 1",
  "uiElements.collapse.headerTwo": "هذا هو رأس اللوحة 2",
  "uiElements.collapse.headerThree": "هذا هو رأس اللوحة 3",
  "uiElements.collapse.headerNested": "هذا هو لوحة لوحة العش",
  "uiElements.collapse.nestedExample": "مثال متداخل",
  "uiElements.collapse.nestedExampleSubTitle": "يتم دمج كولابس داخل كولابس.",
  "uiElements.collapse.borderlessExample": "مثال بلا حدود",
  "uiElements.collapse.borderlessExampleSubTitle":
    "نمط بلا حدود من كولابس. استخدم   بوردرد =   فالس",
  "uiElements.collapse.accordion": "أكورديون",
  "uiElements.collapse.accordionSubTitle":
    "وضع الأكورديون، لوحة واحدة فقط يمكن توسيعها في وقت واحد. سيتم توسيع اللوحة الأولى بشكل افتراضي. استخدام الأكورديون",
  "uiElements.popover.popover": "البوب",
  "uiElements.popover.basicExample": "مثال أساسي",
  "uiElements.popover.basicExampleSubTitle":
    "أبسط مثال. حجم طبقة عائمة يعتمد على محتويات المنطقة.",
  "uiElements.popover.hoverMe": "مرر لي",
  "uiElements.popover.title": "عنوان",
  "uiElements.popover.titleTrigger": "ثلاث طرق لتحريك",
  "uiElements.popover.titleTriggerSubTitle": "الماوس فوق، والتركيز والتحرك في.",
  "uiElements.popover.focusMe": "ركزني",
  "uiElements.popover.clickMe": "انقر فوق لي",
  "uiElements.popover.placement": "تحديد مستوى",
  "uiElements.popover.placementSubTitle": "تتوفر 12 خيارا للمواضع.",
  "uiElements.popover.top": "أعلى",
  "uiElements.popover.topLeft": "أعلى اليسار",
  "uiElements.popover.topRight": "اعلى اليمين",
  "uiElements.popover.leftTop": "أعلى اليسار",
  "uiElements.popover.left": "اليسار",
  "uiElements.popover.leftBottom": "أسفل اليسار",
  "uiElements.popover.rightTop": "أعلى اليمين",
  "uiElements.popover.right": "حق",
  "uiElements.popover.bottom": "الأسفل",
  "uiElements.popover.bottomLeft": "أسفل اليسار",
  "uiElements.popover.bottomRight": "أسفل يمين",
  "uiElements.popover.boxTitle": "التحكم في إغلاق مربع الحوار",
  "uiElements.popover.boxSubTitle": "استخدم دعامة مرئية للتحكم في عرض البطاقة.",
  "uiElements.popover.TR": "TR",
  "uiElements.popover.TL": "TL",
  "uiElements.popover.LT": "LT",
  "uiElements.popover.LB": "رطل",
  "uiElements.popover.RT": "RT",
  "uiElements.popover.RB": "RB",
  "uiElements.popover.BL": "BL",
  "uiElements.popover.BR": "BR",
  "uiElements.popover.close": "قريب",
  "uiElements.tooltip.tooltip": "تلميح",
  "uiElements.tooltip.tooltipContent": "تلميح المحتوى",
  "uiElements.tooltip.basicExample": "مثال أساسي",
  "uiElements.tooltip.basicExampleSubTitle": "أبسط الاستخدام.",
  "uiElements.tooltip.placementTitle": "تحديد مستوى",
  "uiElements.tooltip.placementSubTitle": "يحتوي تولتيب على 12 اختيار للمواضع.",
  "uiElements.tooltip.TL": "TL",
  "uiElements.tooltip.TR": "TR",
  "uiElements.tooltip.LT": "LT",
  "uiElements.tooltip.LB": "رطل",
  "uiElements.tooltip.RT": "RT",
  "uiElements.tooltip.RB": "RB",
  "uiElements.tooltip.BL": "BL",
  "uiElements.tooltip.BR": "BR",
  "uiElements.tooltip.bottom": "الأسفل",
  "uiElements.tooltip.right": "حق",
  "uiElements.tooltip.left": "اليسار",
  "uiElements.tooltip.top": "أعلى",
  "uiElements.tooltip.tooltipContentSpan": "سيتم عرض تلميح عندما يدخل الماوس.",
  "uiElements.tooltip.contentSpan": "تلميح المحتوى",
  "uiElements.tags.tags": "الكلمات",
  "uiElements.tags.basicExample": "مثال أساسي",
  "uiElements.tags.basicExampleSubTitle":
    "استخدام العلامة الأساسية، ويمكن أن يكون قابلا للإغلاق عن طريق مجموعة الممتلكات القابلة للإغلاق. كلوسابل تدعم العلامة إغلوس بعد أحداث كلوس.",
  "uiElements.tags.tagOne": "علامة 1",
  "uiElements.tags.tagTwo": "علامة 2",
  "uiElements.tags.link": "حلقة الوصل",
  "uiElements.tags.preventDefault": "منع افتراضي",
  "uiElements.tags.colorfulTag": "علامة ملونة",
  "uiElements.tags.hotTags": "العلامات الساخنة",
  "uiElements.tags.hotTagsSubTitle": "حدد الموضوعات المفضلة لديك.",
  "uiElements.tags.hots": "السواخن",
  "uiElements.tags.addRemoveDynamically": "إضافة & إزالة ديناميكيا",
  "uiElements.tags.addRemoveDynamicallySubTitle":
    "إنشاء مجموعة من العلامات من قبل مجموعة، يمكنك إضافة وإزالة حيوي. على أساس الحدث بعد كلوس، والتي سيتم تشغيلها في حين نهاية الرسوم المتحركة وثيق.",
  "uiElements.tags.newTag": "+ علامة جديدة",
  "uiElements.timeline.timeline": "الجدول الزمني",
  "uiElements.timeline.basicExample": "مثال أساسي",
  "uiElements.timeline.basicTimeline": "الجدول الزمني الأساسي",
  "uiElements.timeline.lastNode": "العقدة الأخيرة",
  "uiElements.timeline.lastNodeContent":
    "عندما يكون الجدول الزمني غير مكتمل ومستمر، ضع عقدة شبح في الماضي. سيت   بندينغ =   ترو     أور   بندينغ =   a رياكت إليمنت",
  "uiElements.timeline.seeMore": "شاهد المزيد",
  "uiElements.timeline.custom": "العادة",
  "uiElements.timeline.customContent": "تعيين عقدة كرمز أو عنصر مخصص آخر.",
  "uiElements.timeline.colorExample": "مثال على الألوان",
  "uiElements.timeline.colorExampleContent":
    "تعيين لون الدوائر. الأخضر يعني الانتهاء أو حالة النجاح، أحمر يعني التحذير أو الخطأ، والأزرق يعني استمرار أو حالة افتراضية أخرى.",
  "uiElements.timeline.createServiceSite": "إنشاء موقع خدمات 2015-09-01",
  "uiElements.timeline.solveInitialNetwork":
    "حل مشاكل الشبكة الأولية 2015-09-01",
  "uiElements.timeline.networkProblemSolved":
    "مشاكل الشبكة التي يتم حلها 2015-09-01",
  "uiElements.timeline.technicalTesting": "الاختبارات الفنية 2015-09-01",
  "uiElements.dropdown.dropdown": "اسقاط",
  "uiElements.dropdown.hoverDropdown": "مرر لأسفل",
  "uiElements.dropdown.hoverMe": "مرر لي",
  "uiElements.dropdown.hoverPlacement": "تحوم التنسيب المنسدلة",
  "uiElements.dropdown.hoverDisableLink":
    "مرر مؤشر الماوس لأسفل مع تعطيل الرابط",
  "uiElements.dropdown.clickedDropdown": "النقر المنسدلة",
  "uiElements.dropdown.buttonDropdown": "زر مع القائمة المنسدلة",
  "uiElements.pagination.pagination": "ترقيم الصفحات",
  "uiElements.pagination.basic": "الأساسية",
  "uiElements.pagination.more": "أكثر من",
  "uiElements.pagination.changer": "المغير",
  "uiElements.pagination.jumper": "سترة او قفاز او لاعب قفز",
  "uiElements.pagination.miniSize": "حجم صغير",
  "uiElements.pagination.simpleMode": "الوضع البسيط",
  "uiElements.pagination.controlled": "خاضع للسيطرة",
  "uiElements.pagination.totalNumber": "الرقم الإجمالي",
  "uiElements.rating.rating": "تقييم",
  "uiElements.rating.basicExample": "مثال أساسي",
  "uiElements.rating.basicExampleSubTitle": "أبسط الاستخدام.",
  "uiElements.rating.halfStar": "نصف نجوم",
  "uiElements.rating.halfStarSubTitle": "دعم حدد نصف النجم.",
  "uiElements.rating.showCopywriting": "عرض كتابة النصوص",
  "uiElements.rating.showCopywritingSubTitle": "إضافة مكونات الكتابة في معدل.",
  "uiElements.rating.readOnly": "يقرأ فقط",
  "uiElements.rating.readOnlySubTitle":
    "للقراءة فقط، لا يمكن استخدام الماوس للتفاعل.",
  "uiElements.rating.otherCharacter": "شخصية أخرى",
  "uiElements.rating.otherCharacterSubTitle":
    "استبدال النجمة الافتراضية إلى حرف آخر مثل الأبجدية، أرقام، إيكونفونت أو حتى كلمة الصينية.",
  "uiElements.tree.tree": "شجرة",
  "uiElements.tree.basicExample": "مثال أساسي",
  "uiElements.tree.basicExampleSubTitle":
    "الاستخدام الأساسي، أخبرك عن كيفية استخدام قابل للتحديد، قابل للتحديد، تعطيل، ديفولتكسانديكيس، وما إلى ذلك.",
  "uiElements.tree.basicControlledExample": "مثال تحكم أساسي",
  "uiElements.tree.basicControlledExampleSubTitle": "مثال أساسي للتحكم",
  "uiElements.tree.draggableExample": "مثال قابل للسحب",
  "uiElements.tree.draggableExampleSubTitle":
    "اسحب ترينود لإدراج بعد ترينود أخرى أو إدراج في ترينود الأصل الآخر.",
  "uiElements.tree.loadAsync": "تحميل البيانات بشكل غير متزامن",
  "uiElements.tree.loadAsyncSubTitle":
    "لتحميل البيانات بشكل غير متزامن عند النقر لتوسيع ترينود.",
  "uiElements.tree.searchableExample": "مثال قابل للبحث",
  "uiElements.tree.searchableExampleSubTitle": "شجرة قابلة للبحث",
  "uiElements.tree.treeWithLine": "شجرة مع خط",
  "shuffle.descriptionOne": "نيتسكيب 2.0 السفن، وتقديم جافا سكريبت",
  "shuffle.descriptionTwo": "جيسي جيمس غاريت يطلق مواصفات أجاكس",
  "shuffle.descriptionThree": "جكري 1.0 صدر",
  "shuffle.descriptionFour": "تلتزم suberscore.js الأولى",
  "shuffle.descriptionFive": "Backbone.js يصبح شيئا",
  "shuffle.descriptionSix": "الزاوي 1.0 صدر",
  "shuffle.descriptionSeven": "رد فعل مفتوح المصدر؛ المطورين نفرح",
  "toggle.list": "قائمة",
  "toggle.grid": "شبكة",
  "toggle.ascending": "تصاعدي",
  "toggle.descending": "تنازلي",
  "toggle.shuffle": "خلط",
  "toggle.rotate": "استدارة",
  "toggle.addItem": "اضافة عنصر",
  "toggle.removeItem": "إزالة بند",
  "contactlist.searchContacts": "اتصالات بحث",
  "contactlist.addNewContact": "إضافة جهة اتصال جديدة",
  "notes.ChoseColor": "اختر لونا لملاحظتك",
  "notes.addNote": "إضافة ملاحظة جديدة",
  "page404.title": "404",
  "page404.subTitle": "يبدو أنك قد فقدت",
  "page404.description": "الصفحة التي تبحث عنها غير موجودة أو تم نقلها.",
  "page404.backButton": "عودة المنزل",
  "page500.title": "500",
  "page500.subTitle": "خطأ في الخادم الداخلي",
  "page500.description": "هناك خطأ ما. الرجاء إعادة المحاولة.",
  "page500.backButton": "عودة المنزل",
  "page.forgetPassTitle": "متماثل",
  "page.forgetPassSubTitle": "هل نسيت كلمة المرور؟",
  "page.forgetPassDescription":
    "أدخل بريدك الإلكتروني ونرسل إليك رابط إعادة تعيين.",
  "page.sendRequest": "ارسل طلب",
  "page.resetPassTitle": "متماثل",
  "page.resetPassSubTitle": "إعادة تعيين كلمة المرور",
  "page.resetPassDescription": "أدخل كلمة مرور جديدة وقم بتأكيدها.",
  "page.resetPassSave": "حفظ",
  "page.signInTitle": "متماثل",
  "page.signInRememberMe": "تذكرنى",
  "page.signInButton": "تسجيل الدخول",
  "page.signInPreview":
    "اسم المستخدم  تجريبي، كلمة السر  ديموديمو، أو فقط اضغط على أي زر.",
  "page.signInFacebook": "قم بتسجيل الدخول باستخدام الفيسبوك",
  "page.signInGooglePlus": "سجل الدخول باستخدام غوغل بلوس",
  "page.signInAuth0": "سجل الدخول باستخدام أوث0",
  "page.signInForgotPass": "هل نسيت كلمة المرور",
  "page.signInCreateAccount": "إنشاء حساب إسومورفويك",
  "page.signUpTitle": "متماثل",
  "page.signUpTermsConditions": "أوافق على الشروط والأحكام",
  "page.signUpButton": "سجل",
  "page.signUpFacebook": "اشترك عبر حساب فايسبوك",
  "page.signUpGooglePlus": "اشترك مع غوغل بلوس",
  "page.signUpAuth0": "اشترك باستخدام أوث0",
  "page.signUpAlreadyAccount": "هل لديك حساب بالفعل؟ تسجيل الدخول.",
  "widget.reportswidget.label": "الإيرادات",
  "widget.reportswidget.details":
    "لوريم إيبسوم دولور سيت أميت، كونسكتيتور أديبيسيسينغ إليت، سيد دو إيسمود تيمبور",
  "widget.singleprogresswidget1.label": "تسويق",
  "widget.singleprogresswidget2.label": "Addvertisement",
  "widget.singleprogresswidget3.label": "الاستشارات",
  "widget.singleprogresswidget4.label": "تطوير",
  "widget.stickerwidget1.number": "210",
  "widget.stickerwidget1.text": "بريد إلكتروني غير مقروء",
  "widget.stickerwidget2.number": "1749",
  "widget.stickerwidget2.text": "تحميل الصور",
  "widget.stickerwidget3.number": "3024",
  "widget.stickerwidget3.text": "إجمالي الرسالة",
  "widget.stickerwidget4.number": "54",
  "widget.stickerwidget4.text": "أوامر المشاركة",
  "widget.salewidget1.label": "الإيرادات",
  "widget.salewidget1.price": "$ 15000",
  "widget.salewidget1.details":
    "لوريم إيبسوم دولور سيت أميت، كونسكتيتور أديبيسيسينغ إليت، سيد دو إيسمود تيمبور",
  "widget.salewidget2.label": "الإيرادات",
  "widget.salewidget2.price": "$ 15000",
  "widget.salewidget2.details":
    "لوريم إيبسوم دولور سيت أميت، كونسكتيتور أديبيسيسينغ إليت، سيد دو إيسمود تيمبور",
  "widget.salewidget3.label": "الإيرادات",
  "widget.salewidget3.price": "$ 15000",
  "widget.salewidget3.details":
    "لوريم إيبسوم دولور سيت أميت، كونسكتيتور أديبيسيسينغ إليت، سيد دو إيسمود تيمبور",
  "widget.salewidget4.label": "الإيرادات",
  "widget.salewidget4.price": "$ 15000",
  "widget.salewidget4.details":
    "لوريم إيبسوم دولور سيت أميت، كونسكتيتور أديبيسيسينغ إليت، سيد دو إيسمود تيمبور",
  "widget.cardwidget1.number": "110",
  "widget.cardwidget1.text": "رسائل جديدة",
  "widget.cardwidget2.number": "100٪",
  "widget.cardwidget2.text": "الصوت",
  "widget.cardwidget3.number": "137",
  "widget.cardwidget3.text": "موهلات",
  "widget.progresswidget1.label": "تحميل",
  "widget.progresswidget1.details": "50٪ كاملة",
  "widget.progresswidget2.label": "الدعم",
  "widget.progresswidget2.details": "80٪ العملاء راض",
  "widget.progresswidget3.label": "تحميل",
  "widget.progresswidget3.details": "65٪ كاملة",
  "widget.vcardwidget.name": "جون دو",
  "widget.vcardwidget.title": "مطور يوس",
  "widget.vcardwidget.description":
    "لوريم إيبسوم دولور سيت أميت، كونسكتيتور أديبيسيسينغ إليت، سيد دو إيسمود تيمبور أمت رولر كونسكتيتور أديبيسيسينغ إليت",
  "checkout.billingform.firstname": "الاسم الاول",
  "checkout.billingform.lastname": "الكنية",
  "checkout.billingform.company": "اسم الشركة",
  "checkout.billingform.email": "عنوان البريد الإلكتروني",
  "checkout.billingform.mobile": "رقم المحمول",
  "checkout.billingform.country": "بلد",
  "checkout.billingform.city": "مدينة",
  "checkout.billingform.address": "عنوان",
  "checkout.billingform.addressoptional": "شقة، جناح، وحدة الخ (اختياري",
  "checkout.billingform.checkbox": "انشئ حساب؟",
  "antTable.title.image": "صورة",
  "antTable.title.firstName": "الاسم الاول",
  "antTable.title.lastName": "الكنية",
  "antTable.title.city": "مدينة",
  "antTable.title.street": "شارع",
  "antTable.title.email": "البريد الإلكتروني",
  "antTable.title.dob": "تاريخ الميلاد",
  "Map.leaflet.basicTitle": "الخريطة الأساسية",
  "Map.leaflet.basicMarkerTitle": "خريطة أساسية (مع علامة افتراضية",
  "Map.leaflet.leafletCustomMarkerTitle": "خريطة أساسية (مع علامة رمز مخصص",
  "Map.leaflet.leafletCustomHtmlMarkerTitle":
    "خريطة أساسية (مع علامة هتمل مخصصة",
  "Map.leaflet.leafletMarkerClusterTitle":
    "الخريطة الأساسية (مع مجموعة العلامات",
  "Map.leaflet.leafletRoutingTitle": "خريطة أساسية التوجيه",
  "Component.contacts.noOption": "لم يتم العثور على جهة اتصال",
  "email.send": "إرسال",
  "email.cancel": "إلغاء",
  "email.compose": "مؤلف موسيقى",
  "email.noMessage": "الرجاء تحديد بريد للقراءة",
  "themeSwitcher.purchase": "شراء الآن",
  "themeSwitcher.settings": "إعدادات",
  "sidebar.selectbox": "تحديد"
};

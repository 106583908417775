// import getDevSidebar from '../../customApp/sidebar';
const options = [
  { key: '', label: '', leftIcon: '' },
  { key: 'reservations', label: '', leftIcon: '' },
  { key: 'properties', label: '', leftIcon: '' },
  { key: 'emails', label: '', leftIcon: '' },
  { key: 'tasks', label: '', leftIcon: '' },
  { key: 'tax', label: '', leftIcon: '' },
  { key: 'calendar', label: '', leftIcon: '' },
  { key: 'settings', label: '', leftIcon: '' },
  { key: 'notifications', label: '', leftIcon: '' },
];
export default options;

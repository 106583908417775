import React, { Component } from 'react'
import { Link } from 'react-router-dom';
//
import "../../scss/components/Logo.scss";

/**
 * Icon component
 */
export default class Logo extends Component {

    render() {

        const collapsedCalsses = {
            main: this.props.collapsed ? "vpinsight__logo_collapsed" : '',
            logo: this.props.collapsed ? "" : "mr-2",
            text: this.props.collapsed ? "opacity-0 max-w-0" : ''
        };

        const mainClasses = {
            main: this.props.main ? 'vpinsight__logo__main items-center' : '',
            logo: this.props.main ? 'vpinsight__logo-image__big' : '',
            text: this.props.main ? 'text-2.5xl' : ''
        };

        return (
            <Link
                to="/reservations"
                className={ `relative flex vpinsight__logo ${collapsedCalsses.main} ${mainClasses.main}`  }
            >
                <div className={ `vpinsight__logo-image flex-no-shrink ${collapsedCalsses.logo} ${mainClasses.logo}` }></div>

                <div className={ `text-dusk text-xl font-bold leading-normal ${collapsedCalsses.text} ${mainClasses.text}` } >
                    Reservations
                </div>

                { !this.props.notBeta && (
                    <div className="vpinsight__beta absolute font-semibold italic pin-t pin-r text-xxs">beta</div>
                ) }

            </Link>
        )
    }
}

import SecureAxios from './SecureAxios';

class ReservationService {
  getCurrentReservations(filterStates) {
    return SecureAxios.get(
      '/server/api/v1/reservations?filter=' + JSON.stringify(filterStates)
    );
  }
  getReservation(id) {
    return SecureAxios.get('/server/api/v1/reservations/' + id);
  }
  getReservationsByMonth(startDate) {
    return SecureAxios.get(
      '/server/api/v1/reservations/month?start_date=' + startDate
    );
  }
  emailConfirmation(id) {
    return SecureAxios.post(
      '/server/api/v1/reservations/' + id + '/email_confirmation',
      {}
    );
  }
  emailCheckin(id) {
    return SecureAxios.post(
      '/server/api/v1/reservations/' + id + '/email_checkin',
      {}
    );
  }
  emailCleaner(id) {
    return SecureAxios.post(
      '/server/api/v1/reservations/' + id + '/email_cleaner',
      {}
    );
  }
  emailCleanerCancellation(id) {
    return SecureAxios.post(
      '/server/api/v1/reservations/' + id + '/email_cleaner_cancellation',
      {}
    );
  }
  saveReservation(reservation) {
    return SecureAxios.patch('/server/api/v1/reservations/' + reservation.id, {
      customer_reservation: reservation,
    });
  }
  cancelReservation(id) {
    return SecureAxios.post(
      '/server/api/v1/reservations/' + id + '/cancel',
      {}
    );
  }
  sendGuestReview(id, review) {
    return SecureAxios.post(
      '/server/api/v1/reservations/' + id + '/guest_review',
      review
    );
  }
}

export default new ReservationService();

import actions from './actions';

const initState = {
  //  properties Page
  properties: null,
  propertiesLoading: true,
  filterStates: {
    sorts: {
      name: 'Check In',
      direction: 'asc',
      col: 'arrival',
    },
    criteria: {
      search: '',
    },
  },

  //  property Details page
  propertyDetails: null,
  propertyDetailsLoading: true,

  filterDetailStates: {
    sorts: {
      name: 'Type',
      direction: 'asc',
      col: 'detail.property_detail_type.name',
    },
    criteria: {
      search: '',
    },
  },

  filterMaintenanceStates: {
    sorts: {
      name: 'Date',
      direction: 'desc',
      col: 'maintenance_date',
    },
    criteria: {
      search: '',
    },
  },
};

export default function propertiesReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_PROPERTIES:
      return {
        ...state,
        properties: action.properties,
      };
    case actions.SET_PROPERTIES_LOADED:
      return {
        ...state,
        propertiesLoading: false,
      };
    case actions.SET_DETAIL_FILTER:
      return {
        ...state,
        filterDetailStates: action.filterDetailStates,
      };
    case actions.SET_MAINTENANCE_FILTER:
      return {
        ...state,
        filterMaintenanceStates: action.filterMaintenanceStates,
      };
    case actions.SET_PROPERTIES_LOADING:
      return {
        ...state,
        propertiesLoading: true,
      };
    case actions.SET_PROPERTY_DETAILS:
      return {
        ...state,
        propertyDetails: action.propertyDetails,
      };
    case actions.SET_PROPERTY_DETAILS_LOADING:
      return {
        ...state,
        propertyDetailsLoading: true,
      };
    case actions.SET_PROPERTY_DETAILS_LOADED:
      return {
        ...state,
        propertyDetailsLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
}

import SecureAxios from './SecureAxios';

class ReportsService {
  getReports() {
    return SecureAxios.get('/server/api/v1/reports');
  }

  runReport(id, startDate, endDate) {
    return SecureAxios.get(
      '/server/api/v1/reports/' +
        id +
        '?start_date=' +
        startDate +
        '&end_date=' +
        endDate
    );
  }
}

export default new ReportsService();

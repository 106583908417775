import React from 'react';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { IntlProvider } from 'react-intl';
import config, {
  getCurrentLanguage
} from './containers/LanguageSwitcher/config';
import AppLocale from './languageProvider';

import Boot from './redux/boot';

//  Components
import Main from './components/Main';
//  Styles
import 'antd/dist/antd.css'
import './scss/App.scss';
import './scss/theme.scss'

import 'perfect-scrollbar/css/perfect-scrollbar.css';

const currentAppLocale = AppLocale[getCurrentLanguage(config.defaultLanguage || 'english').locale];

const App = () => {
  return (
    <Provider store={store}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Main />
      </IntlProvider>
    </Provider>
  );
}

Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;
export { AppLocale };

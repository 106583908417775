import actions from './actions';

const initState = {
  //  properties Page
  payouts: null,
  payoutsCsv: [],
  payoutsLoading: true,
};

export default function payoutsReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_PAYOUTS:
      return {
        ...state,
        payouts: action.payouts,
      };
    case actions.SET_PAYOUTS_LOADED:
      return {
        ...state,
        payoutsLoading: false,
      };
    case actions.SET_PAYOUTS_LOADING:
      return {
        ...state,
        payoutsLoading: true,
      };
    case actions.SET_PAYOUTS_CSV:
      return {
        ...state,
        payoutsCsv: action.payouts,
      };
    default:
      return {
        ...state,
      };
  }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faExLink from '@fortawesome/fontawesome-free-solid/faExternalLinkSquareAlt';
import { FormattedNumber } from 'react-intl';
import ReactPaginate from 'react-paginate';

import _ from 'lodash';

//  Actions
import reservationsActions from '../../redux/reservations/actions';
import { isMobile } from '../../redux/app/actions';
//  Components
import HeaderSort from '../../components/common/HeaderSort';
import LoadingAnimation from '../../components/common/LoadingAnimation';
import IntlMessages from '../../components/utility/intlMessages';
import { isNull } from 'is-what';
//  Styles
import '../../scss/reservations.scss';

const { getReservations, getPropertyStats, setFilter } = reservationsActions;

const PER_PAGE = 10;

class Reservations extends Component {
  state = {
    reservationsSearch: '',
    tablePage: 0,
  };

  /**
   * Fetch data on mount
   */
  componentDidMount() {
    this.getReservations();
    this.getPropertyStats();
  }

  /**
   * Update data
   */
  componentDidUpdate(prevProps, prevState) {
    const { reservationsSearch } = this.state;
    const { reservationsSearch: prevReservationsSearch } = prevState;
    const { user, filterStates } = this.props;
    const { user: prevUser } = prevProps;

    if (!_.isEqual(user, prevUser)) {
      this.getReservations();
      this.getPropertyStats();
    }

    if (reservationsSearch !== prevReservationsSearch) {
      filterStates.criteria.search = reservationsSearch;
      this.setFilter(filterStates);
    }
  }

  getReservations = () => {
    const { getReservations } = this.props;
    getReservations();
  };

  getPropertyStats = () => {
    const { getPropertyStats } = this.props;
    getPropertyStats();
  };

  setFilter = filterStates => {
    const { setFilter } = this.props;
    setFilter(filterStates);
  };

  /**
   * Handle search input
   *
   * @param {object} event
   */
  handleSearchChange = event => {
    if (event.target.value.length === 0) {
      this.setState({
        reservationsSearch: '',
        tablePage: this.memoizedPage,
      });
    } else {
      this.memoizedPage = this.state.tablePage;

      this.setState({
        reservationsSearch: event.target.value.toLowerCase(),
        tablePage: 0,
      });
    }
  };

  /**
   * Redirect to reservationDetail on row click
   *
   * @param {number} reservationID - reservation's ID
   */
  reservationDetail = reservationID => {
    const { history, user } = this.props;

    // role check for this function
    var match = user.roles.some(function(r) {
      return r.role_enum === 'ADMIN';
    });

    if (match) {
      history.push(`/reservations/${reservationID}`);
    }
  };

  /**
   * Change page state
   *
   * @param {object} page - current page object
   */
  pageChange = page => {
    this.setState({
      tablePage: page.selected,
    });
  };

  render() {
    const {
      reservations,
      stats,
      filterStates,
      reservationsLoading,
      statsLoading,
      view,
    } = this.props;
    const { reservationsSearch, tablePage } = this.state;

    const mobile = isMobile(view);

    const cardClass = `relative rounded-lg bg-white shadow-vp-default flex flex-col ${
      mobile ? 'overflow-hidden w-auto p-8' : 'p-50px m-2 mb-0'
    }`;
    const titleClassName = `flex justify-between  ${
      mobile ? 'flex-col pb-8' : 'pb-50px'
    }`;
    const tableContainerClass = `${
      mobile ? 'w-full overflow-x-scroll -ml-50px' : 'm-4 w-full'
    }`;
    const tableClass = `text-left ${mobile ? '' : 'min-w-full'}`;
    const rowClass = `cursor-pointer`;
    const cellClass = `border-b border-grey-light ${
      mobile ? 'py-2 px-4' : 'py-4 px-6'
    }`;

    const sorting = filterStates.sorts;

    const filteredSortedReservations = _(reservations)
      .filter(reservation => {
        //  Return true if search is not defined
        if (reservationsSearch.length === 0) {
          return true;
        }

        const name = String(
          reservation.customer.first_name + ' ' + reservation.customer.last_name
        ).toLowerCase();
        const id = String(reservation.id);
        const arrival = moment(reservation.arrival)
          .utc()
          .format('MM-DD-YYYY');
        const departure = moment(reservation.departure)
          .utc()
          .format('MM-DD-YYYY');
        const market = reservation.market.name.toLowerCase();
        const propName = reservation.property.name.toLowerCase();

        if (
          name.indexOf(reservationsSearch) !== -1 ||
          id.indexOf(reservationsSearch) !== -1 ||
          arrival.indexOf(reservationsSearch) !== -1 ||
          departure.indexOf(reservationsSearch) !== -1 ||
          market.indexOf(reservationsSearch) !== -1 ||
          propName.indexOf(reservationsSearch) !== -1
        ) {
          return true;
        } else {
          return false;
        }
      })
      .orderBy([sorting.col], [sorting.direction])
      .value();

    const reservationsTable =
      filteredSortedReservations &&
      filteredSortedReservations
        .slice(tablePage * PER_PAGE, tablePage * PER_PAGE + PER_PAGE)
        .map(reservation => (
          <tr
            key={reservation.id}
            className={rowClass + ' hover:bg-grey-backdrop-transparent '}
            onClick={() => {
              this.reservationDetail(reservation.id);
            }}
          >
            <td className={cellClass}>{reservation.customer.first_name}</td>
            <td className={cellClass}>{reservation.customer.last_name}</td>
            <td className={cellClass}>
              {moment(reservation.arrival)
                .utc()
                .format('MM-DD-YYYY')}
            </td>
            <td className={cellClass}>
              {moment(reservation.departure)
                .utc()
                .format('MM-DD-YYYY')}
            </td>
            <td className={cellClass}>{reservation.property.name}</td>
            <td className={cellClass}>{reservation.cleaner.name}</td>
            <td className={cellClass}>
              {reservation.market && reservation.market.name === 'AirBnb' && (
                <a
                  className="d-flex justify-content-between"
                  href={
                    reservation.market.url +
                    'z/q/' +
                    reservation.airbnb_thread_id
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {reservation.market.name} <FontAwesomeIcon icon={faExLink} />
                </a>
              )}
              {reservation.market && reservation.market.name === 'VRBO' && (
                <a
                  className="d-flex justify-content-between"
                  href={reservation.vrbo_thread_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {reservation.market.name} <FontAwesomeIcon icon={faExLink} />
                </a>
              )}
            </td>
          </tr>
        ));

    const statsCards =
      stats &&
      _.map(stats, (value, key) => {
        if (key === '') {
          return '';
        }

        //  Get current time
        let year = moment();
        //  Next year stats should have one year added
        if (key.indexOf('next') !== -1) {
          year = year.add(1, 'years');
          //  Last year stats should heve one year subtracted
        } else if (key.indexOf('last') !== -1) {
          year = year.subtract(1, 'years');
        }

        const displayYear = year.format('YYYY');

        return (
          <div
            className={
              cardClass +
              ' ' +
              (mobile ? 'mb-2.5 flex-grow max-w-1/2-margin' : 'min-w-1/5')
            }
            key={key}
          >
            <div className={titleClassName + ' ' + (mobile ? 'h-28' : 'h-18')}>
              <h1 className="text-lg text-left">
                <IntlMessages id={`reservations.stats.${key}`} />
                <br />
                <small className="text-sm">({displayYear})</small>
              </h1>
            </div>

            {statsLoading && <LoadingAnimation />}

            {!statsLoading && (
              <div className="flex justify-between flex-wrap">
                {_.map(value, (stat, statKey) => {
                  let format = {};

                  if (statKey === 'id') {
                    return '';
                  }

                  if (
                    statKey.indexOf('nights') === -1 &&
                    statKey.indexOf('count') === -1
                  ) {
                    format = { style: 'currency', currency: 'USD' };
                  }

                  return (
                    <section
                      key={statKey}
                      className={
                        'flex flex-col justify-left items-left h-8 w-full ' +
                        (mobile ? ' mr-2.5 mb-2.5' : 'flex-grow m-1 mt-5')
                      }
                    >
                      <div
                        className={
                          'text-slate font-bold text-left ' +
                          (mobile ? 'text-lg' : 'text-xl')
                        }
                      >
                        {isNull(stat) ? (
                          'N/A'
                        ) : (
                          <FormattedNumber {...format} value={stat} />
                        )}
                      </div>
                      <div
                        className={
                          'text-light-grey-blue text-left font-bold uppercase ' +
                          (mobile ? 'text-xxs' : 'text-sm')
                        }
                      >
                        <IntlMessages id={`reservations.stats.${statKey}`} />
                      </div>
                    </section>
                  );
                })}
              </div>
            )}
          </div>
        );
      });

    const paginationProps = {
      pageCount: filteredSortedReservations.length / PER_PAGE,
      pageRangeDisplayed: 5,
      marginPagesDisplayed: 5,
      onPageChange: this.pageChange,
      containerClassName: 'vpreservations__pagination-container',
    };

    return (
      <div className="flex flex-col w-full h-full overflow-x-hidden">
        <div className={cardClass + ' flex-no-shrink'}>
          <div className={titleClassName}>
            <h1>Reservations</h1>
            <input
              type="text"
              placeholder="search..."
              className={`form-control ${mobile ? 'h-8 mt-4' : ''}`}
              value={reservationsSearch}
              onChange={this.handleSearchChange}
            />
          </div>

          {reservationsLoading && <LoadingAnimation />}

          {reservations && reservations.length > 0 && (
            <>
              <div className={tableContainerClass}>
                <table className={tableClass}>
                  <thead>
                    <tr className={rowClass}>
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="First"
                        column="customer.first_name"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="Last"
                        column="customer.last_name"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="Check In"
                        column="arrival"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="Check Out"
                        column="departure"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="Property"
                        column="id"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="Cleaner"
                        column="id"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="Source"
                        column="id"
                      />
                    </tr>
                  </thead>
                  <tbody>{reservationsTable}</tbody>
                </table>
              </div>

              <ReactPaginate {...paginationProps} />
            </>
          )}

          {reservations && reservations.length <= 0 && (
            <h4>No reservations found</h4>
          )}
        </div>

        <div
          className={
            'flex justify-between align-start my-2.5 ' +
            (mobile ? 'flex-wrap' : 'mb-7')
          }
        >
          {statsCards}
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    //  Current user
    user: state.Auth.currentUser,
    //  View
    view: state.App.view,
    //  Reservations state
    ...state.Reservations,
  }),
  { getReservations, getPropertyStats, setFilter }
)(withRouter(Reservations));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Tooltip from 'react-tooltip-lite';

import {
  Menu,
  MenuItem,
  InputAdornment,
  TextField,
  Select,
  InputLabel,
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

//  Icons
import {
  faChevronLeft,
  faSave,
  faTimes,
  faBan,
  faExternalLinkSquareAlt,
  faEllipsisV,
  faCheck,
} from '@fortawesome/fontawesome-free-solid';
//  Components
import LoadingAnimation from '../../components/common/LoadingAnimation';
//  Actions
import reservationsActions from '../../redux/reservations/actions';
import { isMobile } from '../../redux/app/actions';
import ReviewModal from '../../components/common/review/ReviewModal';

const {
  getReservationDetails,
  emailCleanerCancellation,
  emailCleaner,
  emailCheckin,
  emailConfirmation,
  cancelReservation,
  saveReservation,
  showGuestReviewModal,
} = reservationsActions;

function isNull(value) {
  return value == null;
}

class ReservationDetail extends Component {
  state = {
    reservationState: null,
  };

  /**
   * Open reservation actions menu
   *
   * @param {object} event - click event
   */
  actionsToggle = event => {
    this.setState({
      actionsOpen: true,
    });
  };

  /**
   * Close reservation actions menu
   *
   * @param {object} event - click event
   */
  actionsClose = event => {
    this.setState({
      actionsOpen: false,
    });
  };

  /**
   * Handle changing arrival date in editable
   * reservation details state
   *
   * @param {string} date - new date of arrival
   */
  handleArrivalChange = date => {
    let { reservationState } = this.state;

    this.setState({
      reservationState: {
        ...reservationState,
        arrival: date,
      },
    });
  };

  /**
   * Handle changing arrival date in editable
   * reservation details state
   *
   * @param {string} date - new date of arrival
   */
  handleDepartureChange = date => {
    let { reservationState } = this.state;

    this.setState({
      reservationState: {
        ...reservationState,
        departure: date,
      },
    });
  };

  handleChange = propertyName => event => {
    const { reservationState } = this.state;

    this.setState({
      reservationState: _.set(
        reservationState,
        propertyName,
        event.target.value
      ),
    });
  };

  revertChanges = e => {
    this.setState({
      reservationState: _.cloneDeep(this.props.reservationDetails),
    });
  };

  saveReservation = () => {
    const { reservationState } = this.state;
    const { saveReservation } = this.props;
    saveReservation(reservationState);
  };

  cancelReservation = () => {
    const { cancelReservation } = this.props;
    cancelReservation();
    this.actionsClose();
  };

  emailConfirmation = () => {
    const { emailConfirmation } = this.props;
    emailConfirmation();
    this.actionsClose();
  };

  emailCheckin = () => {
    const { emailCheckin } = this.props;
    emailCheckin();
    this.actionsClose();
  };

  emailCleaner = () => {
    const { emailCleaner } = this.props;
    emailCleaner();
    this.actionsClose();
  };

  emailCleanerCancellation = () => {
    const { emailCleanerCancellation } = this.props;
    emailCleanerCancellation();
    this.actionsClose();
  };

  showGuestReviewModal = () => {
    console.log('Showing modal');
    const { showGuestReviewModal } = this.props;
    showGuestReviewModal();
    this.actionsClose();
  };

  getReservationDetails = () => {
    const { getReservationDetails } = this.props;
    const { id } = this.props.match.params;

    getReservationDetails(id);
  };

  constructor(props) {
    super(props);

    this.actionsAnchor = React.createRef();
  }

  componentDidMount() {
    this.getReservationDetails();
  }

  componentDidUpdate(prevProps) {
    //  Copy reservation details from props to state
    //  for purposes of editing
    if (
      !_.isEqual(prevProps.reservationDetails, this.props.reservationDetails)
    ) {
      this.setState({
        reservationState: _.cloneDeep(this.props.reservationDetails),
      });
    }
  }

  render() {
    const {
      user,
      reservationDetails,
      reservationDetailsLoading,
      view,
    } = this.props;
    const { reservationState, actionsOpen } = this.state;

    const mobile = isMobile(view);

    const edited = !_.isEqual(reservationState, reservationDetails);

    const cardClass = `relative rounded-lg bg-white shadow-vp-default flex flex-col ${
      mobile ? 'overflow-hidden w-auto p-8 mb-2.5' : 'p-50px m-2 mb-7'
    }`;
    const titleClassName = `flex justify-between  ${
      mobile ? 'flex-col pb-8' : 'pb-50px'
    }`;
    const textFieldClass = `mb-8  ${mobile ? 'w-full' : 'pr-8 w-1/3'}`;

    const cleanerOptions =
      user &&
      user.cleaners &&
      user.cleaners.length > 0 &&
      user.cleaners.map(c => (
        <MenuItem key={c.id} value={c.id}>
          {c.name}
        </MenuItem>
      ));

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className="flex flex-col w-full h-full overflow-x-hidden">
          <div
            className={`w-full flex-no-shrink ${
              mobile ? 'mb-2.5 text-lg' : 'mb-6'
            }`}
          >
            <Link to={`/`}>
              <FontAwesomeIcon icon={faChevronLeft} /> Back to list
            </Link>
          </div>

          {/* View section */}
          <div className={cardClass + ' flex-no-shrink'}>
            <div className={titleClassName}>
              {/* Title */}
              <div>
                <div className="text-base">Reservation Details</div>

                <h1>
                  {reservationDetailsLoading || isNull(reservationState) ? (
                    'Loading...'
                  ) : (
                    <>
                      {reservationState.customer.first_name}{' '}
                      {reservationState.customer.last_name}
                      {reservationDetails.market &&
                        reservationDetails.market.name === 'AirBnb' && (
                          <a
                            className="text-sm ml-2"
                            href={
                              reservationDetails.market.url +
                              'z/q/' +
                              reservationDetails.airbnb_thread_id
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {reservationDetails.market.name}{' '}
                            <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
                          </a>
                        )}
                      {reservationDetails.market &&
                        reservationDetails.market.name === 'VRBO' && (
                          <a
                            className="text-sm ml-2"
                            href={reservationDetails.vrbo_thread_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {reservationDetails.market.name}{' '}
                            <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
                          </a>
                        )}
                    </>
                  )}
                </h1>
              </div>

              {/* Buttons */}
              {!reservationDetailsLoading && !isNull(reservationState) && (
                <div
                  className={`flex ${
                    mobile
                      ? 'absolute bg-white pin-t pin-r mt-4 mr-2.5 justify-end'
                      : 'justify-between'
                  }`}
                >
                  <Tooltip content="Save reservation details">
                    <button
                      className={
                        'flex align-center justify-center min-w-8 h-8 px-2 mx-2 text-lg overflow-hidden rounded-full bg-grey-backdrop-transparent hover:bg-leafy-green hover:text-white ' +
                        (edited ? '' : ' opacity-75 pointer-events-none')
                      }
                      onClick={this.saveReservation}
                      alt="save"
                    >
                      <FontAwesomeIcon icon={faSave} />
                    </button>
                  </Tooltip>

                  <Tooltip content="Revert all edits">
                    <button
                      className={
                        'flex align-center justify-center min-w-8 h-8 px-2 mx-2 text-lg overflow-hidden rounded-full bg-grey-backdrop-transparent hover:bg-holiday-red hover:text-white ' +
                        (edited ? '' : ' opacity-75 pointer-events-none')
                      }
                      onClick={this.revertChanges}
                      alt="save"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </Tooltip>

                  <Tooltip content="More Actions">
                    <button
                      className={
                        'flex align-center justify-center min-w-8 h-8 px-2 mx-2 text-lg overflow-hidden rounded-full bg-grey-backdrop-transparent hover:bg-grey-backdrop hover:text-white'
                      }
                      aria-controls="reservations-actions-menu"
                      aria-haspopup="true"
                      onClick={this.actionsToggle}
                      ref={this.actionsAnchor}
                      alt="save"
                    >
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </button>
                  </Tooltip>

                  <Menu
                    id="reservations-actions-menu"
                    anchorEl={this.actionsAnchor.current}
                    keepMounted
                    open={actionsOpen}
                    onClose={this.actionsClose}
                  >
                    <MenuItem onClick={this.emailConfirmation}>
                      {reservationDetails.emailed_confirmation ? (
                        <FontAwesomeIcon icon={faCheck} className="mr-4" />
                      ) : (
                        <div className="w-4 mr-4" />
                      )}
                      Email Confirmation
                    </MenuItem>
                    <MenuItem onClick={this.emailCheckin}>
                      {reservationDetails.checkin_emailed ? (
                        <FontAwesomeIcon icon={faCheck} className="mr-4" />
                      ) : (
                        <div className="w-4 mr-4" />
                      )}
                      Email Check-in Info
                    </MenuItem>
                    <MenuItem onClick={this.emailCleaner}>
                      {reservationDetails.emailed_cleaners ? (
                        <FontAwesomeIcon icon={faCheck} className="mr-4" />
                      ) : (
                        <div className="w-4 mr-4" />
                      )}
                      Email Cleaner
                    </MenuItem>
                    <MenuItem onClick={this.showGuestReviewModal}>
                      {reservationDetails.sent_guest_review ? (
                        <FontAwesomeIcon icon={faCheck} className="mr-4" />
                      ) : (
                        <div className="w-4 mr-4" />
                      )}
                      Send Guest Review
                    </MenuItem>
                    <MenuItem onClick={this.emailCleanerCancellation}>
                      <div className="w-4 mr-4" />
                      Email Cleaner Cancellation
                    </MenuItem>
                    <MenuItem onClick={this.cancelReservation}>
                      <FontAwesomeIcon icon={faBan} className="mr-4" />
                      Cancel Reservation
                    </MenuItem>
                  </Menu>
                </div>
              )}
            </div>

            {reservationDetailsLoading || isNull(reservationState) ? (
              <LoadingAnimation />
            ) : (
              <>
                <ReviewModal
                  expanded
                  customerReservationId={reservationState.id}
                />
                <div className="w-full flex flex-wrap">
                  <div className={textFieldClass}>
                    <TextField
                      id="property-name"
                      label="Property"
                      disabled
                      className={'w-full'}
                      value={reservationDetails.property.name}
                      onChange={this.handleChange('property.name')}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="date-booked"
                      label="Date Booked"
                      disabled
                      className={'w-full'}
                      value={moment(reservationDetails.created_at)
                        .utc()
                        .format('MM-DD-YYYY')}
                      onChange={this.handleChange('created_at')}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="is-active"
                      label="Status"
                      disabled
                      className={'w-full'}
                      value={reservationState.canceled ? 'Cancel' : 'Active'}
                      onChange={this.handleChange('cancelled')}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="first_name"
                      label="First Name"
                      className={'w-full'}
                      value={reservationState.customer.first_name}
                      onChange={this.handleChange('customer.first_name')}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="last_name"
                      label="Last Name"
                      className={'w-full'}
                      value={reservationState.customer.last_name}
                      onChange={this.handleChange('customer.last_name')}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <KeyboardDatePicker
                      id="arrival-date"
                      label="Arrival Date"
                      placeholder="2018/10/10"
                      className={'w-full'}
                      value={moment(reservationState.arrival).utc()}
                      onChange={this.handleArrivalChange}
                      format="MM-DD-YYYY"
                    />
                  </div>

                  <div className={textFieldClass}>
                    <KeyboardDatePicker
                      id="departure-date"
                      label="Departure Date"
                      placeholder="2018/10/10"
                      className={'w-full'}
                      value={moment(reservationState.departure).utc()}
                      onChange={this.handleDepartureChange}
                      format="MM-DD-YYYY"
                    />
                  </div>

                  <div className={textFieldClass}>
                    <InputLabel shrink id="cleaner-label">
                      Assigned Cleaner
                    </InputLabel>
                    <Select
                      id="assigned-cleaner"
                      value={reservationState.cleaner_id}
                      className={'w-full'}
                      onChange={this.handleChange('cleaner_id')}
                    >
                      {cleanerOptions}
                    </Select>
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="email"
                      label="Email"
                      className={'w-full'}
                      value={reservationState.customer.primary_email}
                      onChange={this.handleChange('customer.primary_email')}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="primary-phone"
                      label="Primary Phone"
                      className={'w-full'}
                      value={reservationState.customer.primary_phone}
                      onChange={this.handleChange('customer.primary_phone')}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="number-of-guests"
                      label="Total # of People"
                      value={reservationState.total_number_of_guests}
                      type="number"
                      className={'w-full'}
                      onChange={this.handleChange('total_number_of_guests')}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="number-of-adults"
                      label="# of Adults"
                      value={reservationState.number_of_adults}
                      type="number"
                      className={'w-full'}
                      onChange={this.handleChange('number_of_adults')}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="number-of-children"
                      label="# of Children"
                      value={reservationState.number_of_children}
                      type="number"
                      className={'w-full'}
                      onChange={this.handleChange('number_of_children')}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="number-of-infants"
                      label="# of Infants"
                      value={reservationState.number_of_infants}
                      type="number"
                      className={'w-full'}
                      onChange={this.handleChange('number_of_infants')}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="number-of-nights"
                      label="# of Nights"
                      value={reservationState.number_of_nights}
                      type="number"
                      className={'w-full'}
                      onChange={this.handleChange('number_of_nights')}
                    />
                  </div>

                  <div className="h-8 w-full"></div>

                  <div className={textFieldClass}>
                    <TextField
                      id="nigthly-amount"
                      label="Nightly Rate"
                      value={reservationState.nightly_amount}
                      type="number"
                      className={'w-full'}
                      onChange={this.handleChange('nightly_amount')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="number-of-nights"
                      label="Total Rent"
                      value={reservationState.net_rent}
                      type="number"
                      className={'w-full'}
                      onChange={this.handleChange('net_rent')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="fees"
                      label="Market Fees"
                      value={reservationState.fees}
                      type="number"
                      className={'w-full'}
                      onChange={this.handleChange('fees')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="cleaning-fee"
                      label="Cleaning Fee"
                      value={reservationState.cleaning_fee}
                      type="number"
                      className={'w-full'}
                      onChange={this.handleChange('cleaning_fee')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="mgmt-fee"
                      label="Management Fee"
                      value={reservationState.prop_mgmt_fee}
                      type="number"
                      className={'w-full'}
                      onChange={this.handleChange('prop_mgmt_fee')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="tax"
                      label="Tax"
                      value={reservationState.tax}
                      type="number"
                      className={'w-full'}
                      onChange={this.handleChange('tax')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="pet_fee"
                      label="Pet Fee"
                      value={reservationState.pet_fee}
                      type="number"
                      className={'w-full'}
                      onChange={this.handleChange('pet_fee')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default connect(
  state => ({
    //  Current user
    user: state.Auth.currentUser,
    reservationDetails: state.Reservations.reservationDetails,
    reservationDetailsLoading: state.Reservations.reservationDetailsLoading,
    view: state.App.view,
  }),
  {
    getReservationDetails,
    emailCleanerCancellation,
    emailCleaner,
    emailCheckin,
    emailConfirmation,
    cancelReservation,
    saveReservation,
    showGuestReviewModal,
  }
)(withRouter(ReservationDetail));

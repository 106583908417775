import React, { Component } from 'react';
import {
  LineChart,
  BarChart,
  AreaChart,
  Bar,
  Brush,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
  Legend,
  Area,
} from 'recharts';
import moment from 'moment';

class ReportGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      report: props.report,
    };
  }

  componentDidMount() {}

  componentWillReceiveProps() {
    this.setState({});
  }

  formatXAxis(tickItem) {
    return moment(tickItem).format('YYYY-MM-DD');
  }

  findMax(values, attribute) {
    return values.reduce(function(accumulator, currentValue) {
      return currentValue[attribute] &&
        currentValue[attribute] > accumulator[attribute]
        ? currentValue
        : accumulator;
    });
  }

  findMin(values, attribute) {
    return values.reduce(function(accumulator, currentValue) {
      if (!accumulator) {
        return currentValue;
      } else {
        return currentValue[attribute] &&
          currentValue[attribute] < accumulator[attribute]
          ? currentValue
          : accumulator;
      }
    });
  }

  render() {
    // console.log(this.state.report.graph_type === 'BarChart');
    // let max = 100;
    // let min = 0;

    // if(this.state.data && this.state.data.length > 0) {
    //   max = this.findMax(this.state.data, 'value').value;
    //   min = this.findMin(this.state.data, 'value').value;

    //   min = min - 1;
    //   max = max + 1;
    // }

    return (
      <div className="container">
        <div className="m-auto" style={{ height: '400px' }}>
          {this.state.report.graph_type === 'LineChart' && (
            <ResponsiveContainer width="100%" minHeight="400">
              <LineChart
                data={this.state.data}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis
                  dataKey="date"
                  tickFormatter={this.formatXAxis}
                  minTickGap={20}
                />
                <YAxis
                  yAxisId="left"
                  type="number"
                  unit={this.state.report.y_unit}
                  domain={[this.state.yMin, this.state.yMax]}
                ></YAxis>
                <Tooltip />
                <Legend />
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="value"
                  name={this.state.report.name}
                  stroke="blue"
                  style={{ opacity: '0.5' }}
                  strokeWidth={2}
                  dot={false}
                />
                {this.state.report.x_attribute_2 !== null && (
                  <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey={this.state.report.x_attribute_2}
                    name={this.state.report.x_name_2}
                    stroke="green"
                    style={{ opacity: '0.5' }}
                    strokeWidth={2}
                    dot={false}
                  />
                )}
              </LineChart>
            </ResponsiveContainer>
          )}
          {this.state.report.graph_type === 'AreaChart' && (
            <ResponsiveContainer width="100%" minHeight="400">
              <AreaChart
                data={this.state.data}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis
                  dataKey="date"
                  tickFormatter={this.formatXAxis}
                  minTickGap={20}
                />
                <YAxis
                  yAxisId="left"
                  type="number"
                  unit={this.state.report.y_unit}
                  domain={[this.state.yMin, this.state.yMax]}
                ></YAxis>
                <Tooltip />
                <Legend />
                <Area
                  yAxisId="left"
                  type="monotone"
                  dataKey={this.state.report.x_attribute_1}
                  name={this.state.report.x_name_1}
                  stroke="blue"
                  fill="blue"
                  style={{ opacity: '0.5' }}
                  strokeWidth={2}
                  dot={false}
                />
                {this.state.report.x_attribute_2 !== null && (
                  <Area
                    yAxisId="left"
                    type="monotone"
                    dataKey={this.state.report.x_attribute_2}
                    name={this.state.report.x_name_2}
                    stroke="green"
                    fill="green"
                    style={{ opacity: '0.5' }}
                    strokeWidth={2}
                    dot={false}
                  />
                )}
              </AreaChart>
            </ResponsiveContainer>
          )}
          {this.state.report.graph_type === 'BarChart' && (
            <ResponsiveContainer width="100%" minHeight="400">
              <BarChart
                data={this.state.data}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  tickFormatter={this.formatXAxis}
                  minTickGap={20}
                />
                <YAxis />
                <Tooltip />
                <Legend
                  verticalAlign="top"
                  wrapperStyle={{ lineHeight: '40px' }}
                />
                <Brush dataKey="name" height={30} stroke="#8884d8" />
                <Bar
                  dataKey="value"
                  fill="#8884d8"
                  name={this.state.report.name}
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    );
  }
}

export default ReportGraph;

const propertiesActions = {
  //  Properties list page
  GET_PROPERTIES: 'GET_PROPERTIES',
  SET_PROPERTIES: 'SET_PROPERTIES',
  SET_PROPERTIES_LOADING: 'SET_PROPERTIES_LOADING',
  SET_PROPERTIES_LOADED: 'SET_PROPERTIES_LOADED',
  SET_FILTER: 'SET_FILTER',
  SET_DETAIL_FILTER: 'SET_DETAIL_FILTER',
  SET_MAINTENANCE_FILTER: 'SET_MAINTENANCE_FILTER',

  //  Property details page
  GET_PROPERTY_DETAILS: 'GET_PROPERTY_DETAILS',
  SET_PROPERTY_DETAILS_LOADING: 'SET_PROPERTY_DETAILS_LOADING',
  SET_PROPERTY_DETAILS: 'SET_PROPERTY_DETAILS',
  SET_PROPERTY_DETAILS_LOADED: 'SET_PROPERTY_DETAILS_LOADED',
  SAVE_PROPERTY_DETAILS: 'SAVE_PROPERTY_DETAILS',

  getProperties() {
    return {
      type: propertiesActions.GET_PROPERTIES,
    };
  },
  getPropertyDetails(propertyID) {
    return {
      type: propertiesActions.GET_PROPERTY_DETAILS,
      propertyID,
    };
  },
  setFilter(filterStates) {
    return {
      type: propertiesActions.SET_FILTER,
      filterStates,
    };
  },
  setDetailFilter(filterDetailStates) {
    return {
      type: propertiesActions.SET_DETAIL_FILTER,
      filterDetailStates,
    };
  },
  setMaintenanceFilter(filterMaintenanceStates) {
    return {
      type: propertiesActions.SET_MAINTENANCE_FILTER,
      filterMaintenanceStates,
    };
  },
  saveProperty(propertyDetails) {
    return {
      type: propertiesActions.SAVE_PROPERTY_DETAILS,
      propertyDetails,
    };
  },
};

export default propertiesActions;

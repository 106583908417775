import React from 'react';

class Footer extends React.Component {
    render() {
        const year = new Date().getFullYear();
        const classes = this.props.class ? this.props.class : '';
        return (<footer style={{backgroundColor: 'transparent'}}
                        id={'footer'}
                        className={'text-xxs w-full font-medium flex items-center justify-center text-center text-light-grey-blue min-h-13 px-3 py-4 ' + classes}>
            &copy; {year} VPInsight. All right reserved.
        </footer>);
    }
}

export default Footer;

const payoutsActions = {
  //  Payouts list page
  GET_PAYOUTS: 'GET_PAYOUTS',
  SET_PAYOUTS: 'SET_PAYOUTS',
  SET_PAYOUTS_LOADING: 'SET_PAYOUTS_LOADING',
  SET_PAYOUTS_LOADED: 'SET_PAYOUTS_LOADED',
  GET_PAYOUTS_CSV: 'GET_PAYOUTS_CSV',
  SET_PAYOUTS_CSV: 'SET_PAYOUTS_CSV',

  getPayouts(startDate, byYear) {
    return {
      type: payoutsActions.GET_PAYOUTS,
      startDate,
      byYear,
    };
  },
  getPayoutsCsv(startDate, byYear) {
    return {
      type: payoutsActions.GET_PAYOUTS_CSV,
      startDate,
      byYear,
    };
  },
};

export default payoutsActions;

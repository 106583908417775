import moment from 'moment';
import actions from './actions';

const initState = {
  //  Reservations Page
  reservations: null,
  reservationsMonthly: null,
  publicCommentTemplates: null,
  privateCommentTemplates: null,
  reservationsLoading: true,
  stats: null,
  statsLoading: true,
  filterStates: {
    sorts: {
      name: 'Check In',
      direction: 'asc',
      col: 'arrival',
    },
    criteria: {
      search: '',
    },
  },

  //  Reservation Details page
  reservationDetails: null,
  reservationDetailsLoading: true,

  guestReviewModalVisible: false,
};

function processEvents(reservations) {
  var events = [];

  for (var b in reservations) {
    var res = reservations[b];

    events.push({
      id: res.id,
      title:
        res.customer.first_name +
        ' ' +
        res.customer.last_name +
        ' - ' +
        res.market.name,
      start: moment(res.arrival)
        .utc()
        .add(1, 'days'),
      end: moment(res.departure)
        .utc()
        .add(1, 'days'),
    });
  }

  return events;
}

export default function reservationsReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_RESERVATIONS:
      return {
        ...state,
        reservations: action.reservations,
      };
    case actions.SET_STATS:
      return {
        ...state,
        stats: action.stats,
      };
    case actions.SET_FILTER:
      return {
        ...state,
        filterStates: action.filterStates,
      };
    case actions.SET_RESERVATIONS_LOADED:
      return {
        ...state,
        reservationsLoading: false,
      };
    case actions.SET_STATS_LOADED:
      return {
        ...state,
        statsLoading: false,
      };
    case actions.SET_RESERVATIONS_LOADING:
      return {
        ...state,
        reservationsLoading: true,
      };
    case actions.SET_STATS_LOADING:
      return {
        ...state,
        statsLoading: true,
      };
    case actions.SHOW_GUEST_REVIEW_MODAL:
      return {
        ...state,
        guestReviewModalVisible: true,
      };
    case actions.HIDE_GUEST_REVIEW_MODAL:
      return {
        ...state,
        guestReviewModalVisible: false,
      };

    case actions.SET_RESERVATION_DETAILS:
      return {
        ...state,
        reservationDetails: action.reservationDetails,
      };
    case actions.SET_RESERVATION_DETAILS_LOADING:
      return {
        ...state,
        reservationDetailsLoading: true,
      };
    case actions.SET_RESERVATION_DETAILS_LOADED:
      return {
        ...state,
        reservationDetailsLoading: false,
      };

    case actions.SET_RESERVATIONS_MONTHLY:
      return {
        ...state,
        reservationsMonthly: processEvents(action.reservations),
      };

    case actions.SET_PUBLIC_COMMENT_TEMPLATES:
      return {
        ...state,
        publicCommentTemplates: action.messages,
      };
    case actions.SET_PRIVATE_COMMENT_TEMPLATES:
      return {
        ...state,
        privateCommentTemplates: action.messages,
      };
    case actions.SET_GUEST_REVIEW_RESPONSE:
      return {
        ...state,
        reviewResponse: action.reviewResponse,
        guestReviewModalVisible: false,
      };

    default:
      return state;
  }
}

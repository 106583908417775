import {
  all,
  takeEvery,
  fork,
  call,
  put,
  select,
  takeLatest,
  delay,
} from 'redux-saga/effects';

import actions from './actions';

import TaskService from '../../services/TaskService';

/**
 * Get tasks filters state
 *
 * @param {object} state - Redux store state
 * @returns {object} - filters object
 */
export const getFilterState = state => {
  return state.Tasks.filterStates;
};

/**
 *  Fetch tasks data from API
 *  using current filter state
 */
export function* getTasks() {
  //  Set loading state for tasks
  yield put({
    type: actions.SET_TASKS_LOADING,
  });

  //  Fetch filters state
  const filterState = yield select(getFilterState);

  //  Fetch tasks
  yield takeEvery(actions.GET_TASKS, function*() {
    const tasks = yield call(TaskService.getTasks, filterState);

    //  Set loaded tasks
    yield put({
      type: actions.SET_TASKS,
      tasks,
    });

    //  Set tasks loaded
    yield put({
      type: actions.SET_TASKS_LOADED,
    });
  });
}

/**
 * Handle changing filter state
 */
export function* setFilter() {
  yield takeLatest(actions.SET_FILTER, function*() {
    //  Still delay by 500ms just to be sure
    yield delay(1500);

    yield put({
      type: actions.GET_TASKS,
    });
  });
}

export default function* rootSaga() {
  yield all([fork(getTasks)]);
}

import React, { Component } from 'react'

import "../../scss/components/LoadingAnimation.scss";

export default class LoadingAnimation extends Component {
    render() {
        return (
            <div className="flex w-full h-full justify-center items-center">
                <div className="vpinsight__spinner relative rounded-full">
                    <div className="vpinsight__spinner-dot absolute"/>
                    <div className="vpinsight__spinner-dot absolute"/>
                    <div className="vpinsight__spinner-dot absolute"/>
                    <div className="vpinsight__spinner-dot absolute"/>
                    <div className="vpinsight__spinner-dot absolute"/>
                    <div className="vpinsight__spinner-dot absolute"/>
                    <div className="vpinsight__spinner-dot absolute"/>
                    <div className="vpinsight__spinner-dot absolute"/>
                </div>
            </div>
        )
    }
}

const tasksActions = {
  //  Tasks page
  GET_TASKS: 'GET_TASKS',
  SET_TASKS_LOADING: 'SET_TASKS_LOADING',
  SET_TASKS_LOADED: 'SET_TASKS_LOADED',
  SET_TASKS: 'SET_TASKS',
  SET_FILTER: 'SET_FILTER',

  getTasks(filterStates = {}) {
    return {
      type: tasksActions.GET_TASKS,
      filterStates,
    };
  },

  setFilter(filterStates) {
    return {
      type: tasksActions.SET_FILTER,
      filterStates,
    };
  },
};

export default tasksActions;

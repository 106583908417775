import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
// import moment from 'moment';
import _ from 'lodash';

import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

//  Components

//  Actions
import taxesActions from '../../redux/taxes/actions';
import LoadingAnimation from '../../components/common/LoadingAnimation';
import { isMobile } from '../../redux/app/actions';
import { FormattedNumber } from 'react-intl';

const { getTaxes } = taxesActions;

export class Taxes extends Component {
  state = {
    filterYear: new Date().getFullYear(),
  };

  componentDidMount() {
    this.getTaxes();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { user: prevUser } = prevProps;

    if (!_.isEqual(user, prevUser)) {
      this.getTaxes();
    }
  }

  /**
   *
   */
  runChannelFilter = (channels = []) => {
    var filters = this.state.filterStates;
    filters.criteria.channels = channels;

    this.setState(filters);
    this.getTaxes();
  };

  getTaxes() {
    const { getTaxes } = this.props;
    getTaxes();
  }

  makeTable = (name, data, excludeKeys = []) => {
    const { view } = this.props;
    const { filterYear } = this.state;
    const mobile = isMobile(view);

    if (filterYear !== false) {
      data = _.filter(data, ([key, value]) => {
        return key.indexOf(filterYear) !== -1;
      });
    }

    if (_.isEmpty(data)) {
      return '';
    }

    const columnStyle = {
      width: `${100 / data.length}%`,
    };

    const cellClass = (index, dark = false) =>
      `flex justify-end items-center h-10 w-full overflow-hidden font-normal ${
        mobile ? 'min-w-32 flex-no-shrink' : ''
      } ${
        index % 2 === 0
          ? dark
            ? 'bg-blueish-grey-transparent-30'
            : 'bg-blueish-grey-transparent-20'
          : ''
      }`;

    const tableHead = data.map((array, index) => (
      <div
        className={`flex h-12 justify-end font-bold items-center ${
          mobile ? 'min-w-32 flex-no-shrink' : ''
        } ${index % 2 === 0 ? 'bg-blueish-grey-transparent-40' : ''}`}
        style={columnStyle}
        key={array[0]}
      >
        {array[0]}
      </div>
    ));

    const legendColumn = data[0][1].map(([key, value], index) => {
      //  Skip properties key
      if (excludeKeys.includes(key)) {
        return '';
      }

      return (
        <div className={cellClass(index, true)} key={key}>
          {key}
        </div>
      );
    });

    let format = { style: 'currency', currency: 'USD' };

    const otherColumns = data.map(([colKey, colData], colIndex) => {
      const values = colData.map(([rowKey, rowData], index) => {
        //  Skip properties key
        if (excludeKeys.includes(rowKey)) {
          return '';
        }

        return (
          <div className={cellClass(index)} key={`${colKey} ${rowKey}`}>
            <FormattedNumber {...format} value={rowData} />
          </div>
        );
      });

      return (
        <div
          key={colKey}
          className={`flex flex-col justify-center font-bold items-center ${
            mobile ? 'min-w-32 flex-no-shrink' : ''
          } ${colIndex % 2 === 0 ? 'bg-blueish-grey-transparent-20' : ''}`}
          style={columnStyle}
        >
          {values}
        </div>
      );
    });

    return (
      <ScrollSyncPane>
        <div className={`w-full ${mobile ? 'overflow-x-scroll' : ''}`}>
          <div className="flex justify-start items-end h-10 text-xl font-bold h-18 p-4">
            {name}
          </div>

          <div className="flex bg-blueish-grey-transparent-10">
            <div
              style={columnStyle}
              className={`${mobile ? 'min-w-32 flex-no-shrink' : ''}`}
            />
            {tableHead}
          </div>

          <div className="flex bg-blueish-grey-transparent-10">
            <div
              className={`flex flex-col justify-center font-bold items-center ${
                mobile ? 'min-w-32 flex-no-shrink' : ''
              }`}
              style={columnStyle}
            >
              {legendColumn}
            </div>
            {otherColumns}
          </div>
        </div>
      </ScrollSyncPane>
    );
  };

  /**
   *  Show data only for the selected year
   *
   *  @param {object} event - select event
   */
  filterYear = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { taxes, view } = this.props;
    const { filterYear } = this.state;

    const mobile = isMobile(view);

    if (taxes == null) {
      return <LoadingAnimation />;
    }

    const cardClass = `relative rounded-lg bg-white shadow-vp-default flex flex-col ${
      mobile ? 'overflow-hidden w-auto p-8 mb-2.5' : 'p-50px m-2 mb-7'
    }`;
    const titleClassName = `flex justify-between ${
      mobile ? 'flex-col' : 'pb-50px'
    }`;

    const propertiesTables = Object.keys(taxes.properties).map(key => {
      return this.makeTable(
        taxes.properties[key][0][1][0][1],
        taxes.properties[key],
        ['name']
      );
    });

    let years = [];

    for (let index = 0; index < taxes.totals.length; index++) {
      const [key] = taxes.totals[index];

      const year = key.split(' ')[1];

      if (year !== 'Total' && years.indexOf(year) === -1) {
        years.push(year);
      }
    }

    const yearMenu = years.map(year => {
      return (
        <MenuItem value={year} key={year}>
          {year}
        </MenuItem>
      );
    });

    return (
      <div className="flex flex-col w-full h-full overflow-x-hidden">
        <div className={cardClass}>
          <div className={titleClassName}>
            <h1>Taxes</h1>

            <FormControl>
              <InputLabel htmlFor="filter-year-select">Year</InputLabel>
              <Select
                value={filterYear}
                onChange={this.filterYear}
                inputProps={{
                  name: 'filterYear',
                  id: 'filter-year-select',
                }}
              >
                <MenuItem value={false}>Show all</MenuItem>
                {yearMenu}
              </Select>
            </FormControl>
          </div>

          <ScrollSync>
            <>
              {this.makeTable('Totals', taxes.totals, ['properties'])}

              {propertiesTables}
            </>
          </ScrollSync>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.Taxes,
  view: state.App.view,
});

const mapDispatchToProps = {
  getTaxes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Taxes);

import actions from './actions';

const initState = {
  //  properties Page
  reports: null,
  reportsLoading: true,

  //  report Details page
  reportDetails: null,
  reportDetailsLoading: true,
};

export default function reportsReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_REPORTS:
      return {
        ...state,
        reports: action.reports,
      };
    case actions.SET_REPORTS_LOADED:
      return {
        ...state,
        reportsLoading: false,
      };
    case actions.SET_REPORTS_LOADING:
      return {
        ...state,
        reportsLoading: true,
      };
    case actions.SET_REPORT_DETAILS:
      return {
        ...state,
        reportDetails: action.reportDetails,
      };
    case actions.SET_REPORT_DETAILS_LOADING:
      return {
        ...state,
        reportDetailsLoading: true,
      };
    case actions.SET_REPORT_DETAILS_LOADED:
      return {
        ...state,
        reportDetailsLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
}

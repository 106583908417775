import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import actions from './actions';
import authActions from '../auth/actions';
import UserService from '../../services/UserService';
import SecurityService from '../../services/SecurityService';
import { clearToken } from '../../helpers/utility';

export function* changePropertyRequest() {
  yield takeEvery(actions.CHANGE_PROPERTY, function*(payload) {
    const user = yield call(UserService.switchProperty, payload.key.key);

    yield put({
      type: authActions.SET_USER,
      user: user
    });

    yield put({
      type: actions.PROPERTY_LOADED
    })

  });
}

export function* changeListingRequest() {
  yield takeEvery(actions.CHANGE_LISTING, function*(payload) {
    const user = yield call(UserService.switchListing, payload.key.key);
    yield put({
      type: authActions.SET_USER,
      user: user
    });

  });
}

export function* setupDemoRequest() {
  yield takeEvery(actions.SETUP_DEMO, function*(payload) {
    yield put({
      type: 'LOGOUT'
    });
    const user = yield call(UserService.setupDemoUser, payload.data);

    const token = yield call(SecurityService.authenticate, user.email, user.email.substring(0, 12) + "A1");

    yield put({
      type: authActions.LOGIN_SUCCESS,
      token: token,
      profile: 'Profile'
    });

    try {
      const user = yield call(UserService.getCurrentUser);
      yield put({
        type: authActions.SET_USER,
        user: user
      });

      if(token) {
        yield put(push('/dashboard'));
      }
    } catch(err) {
      console.log("getting user err");
      console.log(err);
      clearToken();
      yield put(push('/'));
    }

  });
}

export default function* rootSaga() {
  yield all([
    fork(changePropertyRequest),
    fork(changeListingRequest),
    fork(setupDemoRequest),
  ]);
}

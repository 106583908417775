import SecureAxios from './SecureAxios';

class UserService {
  getCurrentUser() {
    return SecureAxios.get('/server/api/v1/users/current_user');
  }
  switchProperty(id) {
    return SecureAxios.post('/server/api/v1/users/' + id + '/update_current_user_property', {});
  }
  markNotificationsRead() {
    return SecureAxios.post('/server/api/v1/users/mark_notifications_read', {});
  }
}

export default new UserService();

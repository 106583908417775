import {
  all,
  takeEvery,
  fork,
  call,
  put,
  select,
  takeLatest,
  delay,
} from 'redux-saga/effects';
import moment from 'moment';

import actions from './actions';
import toastActions from '../toasts/actions';

import ReservationService from '../../services/ReservationService';
import PropertyService from '../../services/PropertyService';
import MessageService from '../../services/MessageService';

/**
 * Get reservations filters state
 *
 * @param {object} state - Redux store state
 * @returns {object} - filters object
 */
export const getFilterState = state => {
  return state.Reservations.filterStates;
};

/**
 * Get current selected reservation ID
 *
 * @param {object} state - Redux store state
 * @returns {number} - reservation's ID
 */
export const getReservationID = state => {
  return state.Reservations.reservationDetails.id;
};

/**
 *  Fetch reservations data from API
 *  using current filter state
 */
export function* getReservations() {
  //  Set loading state for reservations
  yield put({
    type: actions.SET_RESERVATIONS_LOADING,
  });

  //  Fetch filters state
  const filterState = yield select(getFilterState);

  //  Fetch reservations
  yield takeEvery(actions.GET_RESERVATIONS, function*() {
    const reservations = yield call(
      ReservationService.getCurrentReservations,
      filterState
    );

    //  Set loaded reservations
    yield put({
      type: actions.SET_RESERVATIONS,
      reservations,
    });

    //  Set reservations loaded
    yield put({
      type: actions.SET_RESERVATIONS_LOADED,
    });
  });
}

/**
 *  Get property stats from API
 */
export function* getPropertyStats() {
  //  Set loading state for stats
  yield put({
    type: actions.SET_STATS_LOADING,
  });

  //  Fetch stats
  yield takeEvery(actions.GET_STATS, function*({ property }) {
    const stats = yield call(PropertyService.getPropertyStats, property);

    //  Set loaded stats
    yield put({
      type: actions.SET_STATS,
      stats,
    });

    //  Set stats loaded
    yield put({
      type: actions.SET_STATS_LOADED,
    });
  });
}

/**
 *  Get Calendar data from API
 */
export function* getReservationsMonthly() {
  //  Set loading state for stats
  yield put({
    type: actions.SET_RESERVATIONS_LOADING,
  });

  yield takeEvery(actions.GET_RESERVATIONS_MONTHLY, function*({ date }) {
    date = moment(date).format('MM-DD-YYYY');

    const reservations = yield call(
      ReservationService.getReservationsByMonth,
      date
    );

    //  Set calendar stats
    yield put({
      type: actions.SET_RESERVATIONS_MONTHLY,
      reservations,
    });

    //  Set reservations loaded
    yield put({
      type: actions.SET_RESERVATIONS_LOADED,
    });
  });
}

/**
 *  Get public comment templates for reviews
 */
export function* getReviewPublicCommentTemplate() {
  yield takeEvery(actions.GET_PUBLIC_COMMENT_TEMPLATES, function*({
    customer_reservation_id,
  }) {
    const messages = yield call(
      MessageService.getReviewPublicMessages,
      customer_reservation_id
    );

    //  Set calendar stats
    yield put({
      type: actions.SET_PUBLIC_COMMENT_TEMPLATES,
      messages,
    });
  });
}

/**
 *  Get private comment templates for reviews
 */
export function* getReviewPrivateCommentTemplate() {
  yield takeEvery(actions.GET_PRIVATE_COMMENT_TEMPLATES, function*({
    customer_reservation_id,
  }) {
    const messages = yield call(
      MessageService.getReviewPrivateMessages,
      customer_reservation_id
    );

    //  Set calendar stats
    yield put({
      type: actions.SET_PRIVATE_COMMENT_TEMPLATES,
      messages,
    });
  });
}

/**
 * Handle changing filter state
 */
export function* setFilter() {
  yield takeLatest(actions.SET_FILTER, function*() {
    //  Still delay by 500ms just to be sure
    yield delay(1500);

    yield put({
      type: actions.GET_RESERVATIONS,
    });

    yield put({
      type: actions.GET_STATS,
    });
  });
}

/**
 * Get details for the selected reservation
 */
export function* getReservationDetails() {
  yield takeLatest(actions.GET_RESERVATION_DETAILS, function*({
    reservationID,
  }) {
    yield put({
      type: actions.SET_RESERVATION_DETAILS_LOADING,
    });

    const reservationDetails = yield call(
      ReservationService.getReservation,
      reservationID
    );

    yield put({
      type: actions.SET_RESERVATION_DETAILS,
      reservationDetails,
    });

    yield put({
      type: actions.SET_RESERVATION_DETAILS_LOADED,
    });
  });
}

export function* saveReservationDetails() {
  yield takeEvery(actions.SAVE_RESERVATION_DETAILS, function*({
    reservationDetails,
  }) {
    yield put({
      type: actions.SET_RESERVATION_DETAILS_LOADING,
    });

    const updatedReservation = yield call(
      ReservationService.saveReservation,
      reservationDetails
    );

    yield put({
      type: actions.SET_RESERVATION_DETAILS,
      reservationDetails: updatedReservation,
    });

    yield put({
      type: actions.SET_RESERVATION_DETAILS_LOADED,
    });
  });
}

function* genericActionWithID({ service, messageSuccess, messageError }) {
  try {
    yield put({
      type: actions.SET_RESERVATION_DETAILS_LOADING,
    });

    //  Get reservation ID from state
    const reservationID = yield select(getReservationID);
    //  Call the API, get new details back
    const reservationDetails = yield call(service, reservationID);

    yield put({
      type: actions.SET_RESERVATION_DETAILS,
      reservationDetails,
    });

    yield put({
      type: actions.SET_RESERVATION_DETAILS_LOADED,
    });

    yield put({
      type: toastActions.SHOW,
      message: messageSuccess,
    });
  } catch (error) {
    yield put({
      type: toastActions.SHOW,
      message: messageError,
      error,
    });
  }
}

export function* emailCleaner() {
  yield takeLatest(actions.EMAIL_CLEANER, function*() {
    yield genericActionWithID({
      service: ReservationService.emailCleaner,
      messageSuccess: 'reservarions.emailCleanerSuccess',
      messageError: 'reservarions.emailCleanerError',
    });
  });
}

export function* emailCleanerCancellation() {
  yield takeLatest(actions.EMAIL_CLEANER_CANCELLATION, function*() {
    yield genericActionWithID({
      service: ReservationService.emailCleanerCancellation,
      messageSuccess: 'reservarions.emailCleanerCancellationSuccess',
      messageError: 'reservarions.emailCleanerCancellationError',
    });
  });
}

export function* emailCheckin() {
  yield takeLatest(actions.EMAIL_CHECKIN, function*() {
    yield genericActionWithID({
      service: ReservationService.emailCheckin,
      messageSuccess: 'reservarions.emailCheckinSuccess',
      messageError: 'reservarions.emailCheckinError',
    });
  });
}

export function* emailConfirmation() {
  yield takeLatest(actions.EMAIL_CONFIRMATION, function*() {
    yield genericActionWithID({
      service: ReservationService.emailConfirmation,
      messageSuccess: 'reservarions.emailConfirmationSuccess',
      messageError: 'reservarions.emailConfirmationError',
    });
  });
}
export function* cancelReservation() {
  yield takeLatest(actions.CANCEL_RESERVATION, function*() {
    yield genericActionWithID({
      service: ReservationService.cancelReservation,
      messageSuccess: 'reservarions.cancelReservationSuccess',
      messageError: 'reservarions.cancelReservationError',
    });
  });
}
export function* saveGuestReview() {
  yield takeEvery(actions.SAVE_GUEST_REVIEW, function*({
    guestReview,
    customerReservationId,
  }) {
    yield put({
      type: actions.SET_RESERVATION_DETAILS_LOADING,
    });

    const reviewResponse = yield call(
      ReservationService.sendGuestReview,
      customerReservationId,
      guestReview
    );

    yield put({
      type: actions.SET_GUEST_REVIEW_RESPONSE,
      reviewResponse: reviewResponse,
      reservationDetails: reviewResponse,
    });

    yield put({
      type: actions.SET_RESERVATION_DETAILS_LOADED,
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(getReservations),
    fork(getPropertyStats),
    fork(getReservationDetails),
    fork(saveReservationDetails),
    fork(emailCleaner),
    fork(emailCleanerCancellation),
    fork(emailCheckin),
    fork(emailConfirmation),
    fork(cancelReservation),
    fork(getReservationsMonthly),
    fork(getReviewPublicCommentTemplate),
    fork(getReviewPrivateCommentTemplate),
    fork(saveGuestReview),
  ]);
}

import SecureAxios from './SecureAxios';

class PropertyService {
  getPropertyStats() {
    return SecureAxios.get('/server/api/v1/properties/stats');
  }
  getUserProperties(filter) {
    return SecureAxios.get('/server/api/v1/properties', { filter });
  }
  getProperty(id) {
    return SecureAxios.get('/server/api/v1/properties/' + id);
  }
  saveProperty(property) {
    return SecureAxios.patch('/server/api/v1/properties/' + property.id, {
      property: property,
    });
  }
}

export default new PropertyService();

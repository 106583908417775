import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faExLink from '@fortawesome/fontawesome-free-solid/faExternalLinkSquareAlt';
import ReactPaginate from 'react-paginate';

//  Actions
import propertiesActions from '../../redux/properties/actions';
import { isMobile } from '../../redux/app/actions';
//  Components
import HeaderSort from '../../components/common/HeaderSort';
import LoadingAnimation from '../../components/common/LoadingAnimation';
//  Styles
import '../../scss/reservations.scss';

const PER_PAGE = 25;

const { getProperties, setFilter } = propertiesActions;

export class Properties extends Component {
  state = {
    search: '',
    tablePage: 0,
  };

  /**
   * Handle search input
   *
   * @param {object} event
   */
  handleSearchChange = event => {
    if (event.target.value.length === 0) {
      this.setState({
        search: '',
        tablePage: this.memoizedPage,
      });
    } else {
      this.memoizedPage = this.state.tablePage;

      this.setState({
        search: event.target.value,
        tablePage: 0,
      });
    }
  };

  /**
   * Redirect to propertyDetail on row click
   *
   * @param {number} propertyID - reservation's ID
   */
  propertyDetail = propertyID => {
    const { history, user } = this.props;

    // role check for this function
    var match = user.roles.some(function(r) {
      return r.role_enum === 'ADMIN';
    });

    if (match) {
      history.push(`/properties/${propertyID}`);
    }
  };

  /**
   * Change page state
   *
   * @param {object} page - current page object
   */
  pageChange = page => {
    this.setState({
      tablePage: page.selected,
    });
  };

  getProperties = () => {
    const { getProperties } = this.props;
    getProperties();
  };

  setFilter = filterStates => {
    const { setFilter } = this.props;
    setFilter(filterStates);
  };

  componentDidMount() {
    this.getProperties();
  }

  componentDidUpdate(prevProps, prevState) {
    const { search } = this.state;
    const { search: prevsearch } = prevState;
    const { user, filterStates } = this.props;
    const { user: prevUser } = prevProps;

    if (!_.isEqual(user, prevUser)) {
      this.getProperties();
    }

    if (search !== prevsearch) {
      filterStates.criteria.search = search;
      this.setFilter(filterStates);
    }
  }

  render() {
    const { properties, propertiesLoading, filterStates, view } = this.props;
    const { search, tablePage } = this.state;

    const mobile = isMobile(view);

    const cardClass = `relative rounded-lg bg-white shadow-vp-default flex flex-col ${
      mobile ? 'overflow-hidden w-auto p-8' : 'p-50px m-2'
    }`;
    const titleClassName = `flex justify-between  ${
      mobile ? 'flex-col pb-8' : 'pb-50px'
    }`;
    const tableContainerClass = `${
      mobile ? 'w-full overflow-x-scroll -ml-50px' : 'm-4 w-full'
    }`;
    const tableClass = `text-left ${mobile ? '' : 'min-w-full'}`;
    const rowClass = `cursor-pointer`;
    const cellClass = `border-b border-grey-light ${
      mobile ? 'py-2 px-4' : 'py-4 px-6'
    }`;

    const sorting = filterStates.sorts;

    const filteredSortedProperties = _(properties)
      .filter(property => {
        //  Return true if search is not defined
        if (search.length === 0) {
          return true;
        }

        const name = String(property.name).toLowerCase();
        const id = String(property.id);
        const airbnb_listing_id = String(
          property.airbnb_listing_id
        ).toLowerCase();
        const vrbo_listing_id = String(property.vrbo_listing_id).toLowerCase();

        if (
          name.indexOf(search) !== -1 ||
          id.indexOf(search) !== -1 ||
          airbnb_listing_id.indexOf(search) !== -1 ||
          vrbo_listing_id.indexOf(search) !== -1
        ) {
          return true;
        } else {
          return false;
        }
      })
      .orderBy([sorting.col], [sorting.direction])
      .value();

    const propertiesTable =
      filteredSortedProperties &&
      filteredSortedProperties
        .slice(tablePage * PER_PAGE, tablePage * PER_PAGE + PER_PAGE)
        .map(property => (
          <tr
            key={property.id}
            className={rowClass + ' hover:bg-grey-backdrop-transparent '}
            onClick={() => {
              this.propertyDetail(property.id);
            }}
          >
            <td className={cellClass}>{property.id}</td>
            <td className={cellClass}>{property.name}</td>
            <td className={cellClass}>
              {property.airbnb_listing_id && (
                <a
                  className="d-flex justify-content-between"
                  href={`https://www.airbnb.com/rooms/${property.vrbo_listing_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AirBnb <FontAwesomeIcon icon={faExLink} />
                </a>
              )}
            </td>
            <td className={cellClass}>
              {property.vrbo_listing_id && (
                <a
                  className="d-flex justify-content-between"
                  href={`https://www.vrbo.com/${property.vrbo_listing_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  VRBO <FontAwesomeIcon icon={faExLink} />
                </a>
              )}
            </td>
          </tr>
        ));

    const paginationProps = {
      pageCount: filteredSortedProperties.length / PER_PAGE,
      pageRangeDisplayed: 5,
      marginPagesDisplayed: 5,
      onPageChange: this.pageChange,
      containerClassName: 'vpreservations__pagination-container',
    };

    return (
      <div className="flex flex-col w-full h-full overflow-x-hidden">
        <div className={cardClass + ' flex-no-shrink'}>
          <div className={titleClassName}>
            <h1>Properties</h1>
            <input
              type="text"
              placeholder="search..."
              className={`form-control ${mobile ? 'h-8 mt-4' : ''}`}
              value={search}
              onChange={this.handleSearchChange}
            />
          </div>

          {propertiesLoading && <LoadingAnimation />}

          {properties && properties.length > 0 && (
            <>
              <div className={tableContainerClass}>
                <table className={tableClass}>
                  <thead>
                    <tr className={rowClass}>
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="ID"
                        column="id"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="Name"
                        column="name"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="AirBnb Listing"
                        column="airbnb_listing_id"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="VRBO Listing"
                        column="vrbo_listing_id"
                      />
                    </tr>
                  </thead>
                  <tbody>{propertiesTable}</tbody>
                </table>
              </div>

              <ReactPaginate {...paginationProps} />
            </>
          )}

          {properties && properties.length <= 0 && <h4>No Properties found</h4>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  //  Current user
  user: state.Auth.currentUser,
  //  View
  view: state.App.view,
  //  Properties state
  ...state.Properties,
});

const mapDispatchToProps = {
  getProperties,
  setFilter,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Properties));

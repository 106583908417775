import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Auth from './auth/reducer';
import App from './app/reducer';
import Properties from './properties/reducer';
import Payouts from './payouts/reducer';
import Reports from './reports/reducer';
import Settings from './settings/reducer';
// import Cards from './card/reducer';
// import DynamicChartComponent from './dynamicEchart/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
// import competitionsState from './competition/reducer';
// import statState from './map/reducer'

import Reservations from './reservations/reducer';
import Tasks from './tasks/reducer';
import Taxes from './taxes/reducer';

// import Filter from './filter/reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    Auth,
    App,
    ThemeSwitcher,
    LanguageSwitcher,
    Settings,
    Reservations,
    Tasks,
    Taxes,
    Properties,
    Payouts,
    Reports,

    // Cards,
    // DynamicChartComponent,
    // Listings,
    // competitionsState,
    // statState,
    // Filter,
  });

import SecureAxios from './SecureAxios';

class MessageService {
  getReviewPublicMessages(customer_reservation_id) {
    return SecureAxios.get(
      '/server/api/v1/messages?message_type=public_review&customer_reservation_id=' +
        customer_reservation_id
    );
  }

  getReviewPrivateMessages(customer_reservation_id) {
    return SecureAxios.get(
      '/server/api/v1/messages?message_type=private_review&customer_reservation_id=' +
        customer_reservation_id
    );
  }
}

export default new MessageService();

import SecureAxios from './SecureAxios';

class PayoutService {
  getPayouts(startDate, byYear) {
    var yearText = byYear ? '&by_year=' + byYear : '';
    return SecureAxios.get(
      '/server/api/v1/payouts?start_date=' + startDate + yearText
    );
  }
  getPayoutsCsv(startDate, byYear) {
    var yearText = byYear ? '&by_year=' + byYear : '';
    return SecureAxios.get(
      '/server/api/v1/payouts/csv?start_date=' + startDate + yearText
    );
  }
}

export default new PayoutService();

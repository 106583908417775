/* eslint-disable no-unused-vars */
import React from 'react';

export const messages = {
  //  Main Menu
  'sidebar.reservations': 'Reservations',
  'sidebar.properties': 'Properties',
  'sidebar.calendar': 'Calendar',
  'sidebar.tasks': 'Tasks',
  'sidebar.taxes': 'Taxes',
  'sidebar.payouts': 'Payouts',
  'sidebar.payments': 'Card Payment',
  'sidebar.reports': 'Reports',
  'sidebar.settings': 'Account Settings',

  'sidebar.dashboard': 'Dashboard',
  'sidebar.email': 'Email',
  'sidebar.chat': 'Chat',
  'sidebar.ecommerce': 'Ecommerce',
  'sidebar.shop': 'Shop',
  'sidebar.cart': 'Cart',
  'sidebar.checkout': 'Checkout',
  'sidebar.cards': 'Cards',
  'sidebar.maps': 'Map',
  'sidebar.competition': 'Competition',
  'sidebar.listing': 'My Listing',

  //  Reservations
  'reservations.stats.year_total': 'This year',
  'reservations.stats.projected_year_total': 'This year projected',
  'reservations.stats.last_year_total': 'Last year',
  'reservations.stats.projected_next_year_total': 'Next year projected',
  'reservations.stats.fees': 'Market Fees',
  'reservations.stats.prop_mgmt_fee': 'Mgmt Fees',
  'reservations.stats.cleaning_fee': 'Clean Fees',
  'reservations.stats.tax': 'Taxes',
  'reservations.stats.sum': 'Rent',
  'reservations.stats.nights': 'Nights Hosted',
  'reservations.stats.nights_projected': 'Nights Hosted',
  'reservations.stats.avg_rate': 'Average Rate',
  'reservations.stats.res_count': 'Res. Count',
  //  Legacy vvv

  'feedback.alert.basicTitle': 'Basic Title',
  'feedback.alert.successText': 'Success text',
  'feedback.alert.infoText': 'Info Text',
  'feedback.alert.warningText': 'Warning Text',
  'feedback.alert.errorText': 'Error Text',
  'feedback.alert.closableAlertType': 'Closable Alert Type',
  'feedback.alert.iconAlertType': 'Icon Alert Type',
  'feedback.alert.iconInfoAlertType': 'Icon Info Alert Type',
  'feedback.alert.successTips': 'success tips',
  'feedback.alert.successTipsDescription':
    'Detailed description and advices about successful copywriting.',
  'feedback.alert.informationTips': 'Informational Notes',
  'feedback.alert.informationDescription':
    'Additional description and informations about copywriting.',
  'feedback.alert.warningTips': 'Warning',
  'feedback.alert.warningDescription':
    'This is a warning notice about copywriting.',
  'feedback.alert.errorTips': 'Error',
  'feedback.alert.errorDescription':
    'This is an error message about copywriting.',
  'feedback.alert.modalTitle': 'Modal one with customize Footer',
  'feedback.alert.modalSubTitle': 'Basic modal dialog.',
  'feedback.alert.successTitle': 'Success',
  'feedback.alert.infoTitle': 'Info',
  'feedback.alert.errorTitle': 'Error',
  'feedback.alert.warningTitle': 'Warning',
  'feedback.alert.modalBlockTitle': 'Modal',
  'feedback.alert.confirmationModalDialogue': 'Confirmation modal dialog',
  'feedback.alert.simpleModalDialogue': 'Simple modal dialog',
  'feedback.alert.message': 'Message',
  'feedback.alert.normalMessageTitle': 'Normal Message',
  'feedback.alert.normalMessageSubtitle': 'Normal messages as feedbacks.',
  'feedback.alert.displayMessage': 'Display normal message',
  'feedback.alert.displayOtherTypeMessageTitle': 'Other Types of Message',
  'feedback.alert.displayOtherTypeMessageSubTitle':
    'Messages of success   error and warning types.',
  'feedback.alert.customizeDurationTitle': 'Customize duration',
  'feedback.alert.customizeDurationSubTitle':
    'ustomize message display duration from default 1.5s to 10s.',
  'feedback.alert.customizeDurationButton': 'Customized display duration',
  'feedback.alert.messageLoadingTitle': 'Message of loading',
  'feedback.alert.messageLoadingSubTitle':
    'Display a global loading indicator   which is dismissed by itself asynchronously.',
  'feedback.alert.displayLoadIndicator': 'Display a loading indicator',
  'feedback.alert.notification': 'Notification',
  'feedback.alert.notificationBasicTitle': 'Basic',
  'feedback.alert.notificationBasicSubTitle':
    'The simplest usage that close the notification box after 4.5s.',
  'feedback.alert.notificationBasicDescription': 'Open the notification box',
  'feedback.alert.notificationDurationTitle':
    'Duration after which the notification box is closed',
  'feedback.alert.notificationDurationSubTitle':
    'Duration can be used to specify how long the notification stays open. After the duration time elapses   the notification closes automatically. If not specified   default value is 4.5 seconds. If you set the value to 0   the notification box will never close automatically.',
  'feedback.alert.notificationwithIconTitle': 'Notification with icon',
  'feedback.alert.notificationwithIconSubTitle':
    'A notification box with a icon at the left side.',
  'feedback.alert.notificationwithCustomIconTitle':
    'Notification with custom icon',
  'feedback.alert.notificationwithCustomIconSubTitle':
    'Normal messages as feedbacks.',
  'feedback.alert.notificationwithCustomButtonTitle':
    'Notification with custom button',
  'feedback.alert.notificationwithCustomButtonSubTitle':
    'Normal messages as feedbacks.',
  'feedback.alert.popConfirm': 'Pop Confirm',
  'feedback.alert.popConfirm.basicTitle': 'Basic Confirm',
  'feedback.alert.popConfirm.basicSubTitle': 'The basic example.',
  'feedback.alert.popConfirm.delete': 'Delete',
  'feedback.alert.popConfirm.notiWithIconTitle':
    'Notification with custom icon',
  'feedback.alert.popConfirm.notiWithIconSubTitle':
    'Normal messages as feedbacks.',
  'feedback.alert.popConfirm.TL': 'TL',
  'feedback.alert.popConfirm.top': 'Top',
  'feedback.alert.popConfirm.TR': 'TR',
  'feedback.alert.popConfirm.LT': 'LT',
  'feedback.alert.popConfirm.left': 'Left',
  'feedback.alert.popConfirm.LB': 'LB',
  'feedback.alert.popConfirm.RT': 'RT',
  'feedback.alert.popConfirm.right': 'Right',
  'feedback.alert.popConfirm.RB': 'RB',
  'feedback.alert.popConfirm.Bl': 'BL',
  'feedback.alert.popConfirm.bottom': 'Bottom',
  'feedback.alert.popConfirm.BR': 'BR',
  'feedback.alert.spin': 'Spin',
  'feedback.alert.spin.basicTitle': 'Size Spin',
  'feedback.alert.spin.background': 'Spin With Background',
  'feedback.alert.spin.backgroundDescription':
    'Spin With Background description',
  'feedback.alert.spin.loadingState': 'Loading State',
  'feedback.alert.spin.alertTitle': 'Alert message title',
  'feedback.alert.spin.alertDescription':
    'Further details about the context of this alert.',
  'forms.input.header': 'Input',
  'forms.input.basicTitle': 'Basic usage',
  'forms.input.basicSubTitle': 'Basic usage example.',
  'forms.input.variationsTitle': 'Three sizes of Input',
  'forms.input.variationsSubtitle':
    'There are three sizes of an Input box  large (42px  、default (35px   and small (30px  . Note  Inside of forms   only the large size is used.',
  'forms.input.groupTitle': 'Input Group',
  'forms.input.groupSubTitle':
    'Input.Group example Note  You dont need Col to control the width in the compact mode.',
  'forms.input.autoSizingTitle': 'Autosizing the height to fit the content',
  'forms.input.autoSizingSubTitle':
    'autosize prop for a textarea type of Input makes the height to automatically adjust based on the content. An options object can be provided to autosize to specify the minimum and maximum number of lines the textarea will automatically adjust.',
  'forms.input.prePostTabTitle': 'Pre    Post tab',
  'forms.input.prePostTabSubTitle': 'Using pre &amp; post tabs example..',
  'forms.input.textAreaTitle': 'Textarea',
  'forms.input.textAreaSubTitle':
    'For multi-line user input cases   an input whose type prop has the value of textarea can be used.',
  'forms.input.searchTitle': 'Search',
  'forms.input.searchSubTitle':
    'Example of creating a search box by grouping a standard input with a search button',
  'forms.editor.header': 'Editor',
  'forms.formsWithValidation.header': 'Customized Validation Form',
  'forms.formsWithValidation.failLabel': 'Fail',
  'forms.formsWithValidation.failHelp':
    'Should be combination of numbers & alphabets',
  'forms.formsWithValidation.warningLabel': 'Warning',
  'forms.formsWithValidation.ValidatingLabel': 'Validating',
  'forms.formsWithValidation.ValidatingHelp':
    'The information is being validated...',
  'forms.formsWithValidation.SuccessLabel': 'Success',
  'forms.formsWithValidation.WarninghasFeedbackLabel': 'Warning',
  'forms.formsWithValidation.FailhasFeedbackLabel': 'Fail',
  'forms.formsWithValidation.FailhasFeedbackHelp':
    'Should be combination of numbers & alphabets',
  'forms.progressBar.header': 'Progress Bar',
  'forms.progressBar.standardTitle': 'Progress bar',
  'forms.progressBar.standardSubTitle': 'A standard progress bar.',
  'forms.progressBar.circularTitle': 'Circular Progress bar',
  'forms.progressBar.circularSubTitle': 'A circular progress bar.',
  'forms.progressBar.miniTitle': 'Mini size progress bar',
  'forms.progressBar.miniSubTitle': 'Appropriate for a narrow area.',
  'forms.progressBar.miniCircularTitle': 'A smaller circular progress bar.',
  'forms.progressBar.dynamicCircularTitle': 'Dynamic circular progress bar',
  'forms.progressBar.dynamicCircularSubTitle':
    'A dynamic progress bar is better.',
  'forms.progressBar.customTextTitle': 'Custom text format',
  'forms.progressBar.customTextSubTitle':
    'You can custom text format by setting format.',
  'forms.progressBar.dashboardTitle': 'Dashboard',
  'forms.progressBar.dashboardSubTitle': 'A dashboard style of progress.',
  'forms.button.header': 'Buttons',
  'forms.button.simpleButton': 'Button Type',
  'forms.button.iconButton': 'Button Icon',
  'forms.button.simpleButtonPrimaryText': 'Primary',
  'forms.button.simpleButtonDefaultText': 'Default',
  'forms.button.simpleButtonDashedText': 'Dashed',
  'forms.button.simpleButtonDangerText': 'Danger',
  'forms.button.iconPrimaryButton': 'search',
  'forms.button.iconSimpleButton': 'search',
  'forms.button.iconCirculerButton': 'search',
  'forms.button.iconDashedButton': 'search',
  'forms.button.SizedButton': 'Button Size',
  'forms.button.DisabledButton': 'Button Disabled',
  'forms.button.LoadingButton': 'Button Loading',
  'forms.button.MultipleButton': 'Multiple Button',
  'forms.button.groupButton': 'Button Group',
  'forms.Tabs.header': 'Tabs',
  'forms.Tabs.simpleTabTitle': 'search',
  'forms.Tabs.simpleTabSubTitle': 'Disabled Tabs',
  'forms.Tabs.iconTabTitle': 'Icon Tabs',
  'forms.Tabs.miniTabTitle': 'Mini Tabs',
  'forms.Tabs.extraTabTitle': 'Extra Action Tabs',
  'forms.Tabs.TabpositionTitle': 'Position',
  'forms.Tabs.TabpositionSubTitle':
    'Tabss position  left   right   top or bottom',
  'forms.Tabs.cardTitle': 'Card Type Tabs',
  'forms.Tabs.editableTitle': 'Add and Close Tabs',
  'forms.Tabs.verticalTitle': 'Vertical Type Tabs',
  'forms.Tabs.basicTitle': 'Basic Tabs',
  'forms.checkbox.header': 'Checkbox',
  'forms.checkbox.basicTitle': 'Basic Checkbox',
  'forms.checkbox.basicSubTitle': 'Basic usage of checkbox.',
  'forms.checkbox.groupTitle': 'Checkbox Group',
  'forms.checkbox.groupSubTitle':
    'Generate a group of checkboxes from an array. Use disabled to disable a checkbox.',
  'forms.checkbox.groupCheckTitle': 'Checkbox Group',
  'forms.checkbox.groupCheckSubTitle':
    'Generate a group of checkboxes from an array. Use disabled to disable a checkbox.',
  'forms.radio.header': 'Radio',
  'forms.radio.simpleTitle': 'Basic Radio',
  'forms.radio.simpleSubTitle':
    'The simplest use. Use disabled to disable a radio.',
  'forms.radio.groupTitle': 'Vertical RadioGroup',
  'forms.radio.groupSubTitle': 'Vertical RadioGroup   with more radios.',
  'forms.radio.groupSecondTitle': 'RadioGroup',
  'forms.radio.groupSecondSubTitle': 'A group of radio components.',
  'forms.radio.groupThirdTitle': 'RadioGroup',
  'forms.radio.groupThirdSubTitle': 'A group of radio components.',
  'forms.transfer.header': 'Transfer',
  'forms.transfer.SubTitle': 'Transfer with a search box.',
  'forms.transfer.Title': 'Search',
  'forms.autocomplete.header': 'Autocomplete',
  'forms.autocomplete.simpleTitle': 'Customized',
  'forms.autocomplete.simpleSubTitle':
    'You could pass AutoComplete.Option as children of AutoComplete   instead of using dataSource',
  'forms.autocomplete.customizeTitle': 'Customize Input Component',
  'forms.autocomplete.customizeSubTitle': 'Customize Input Component',
  'uiElements.badge.badge': 'Badge',
  'uiElements.badge.basicExample': 'Basic Example',
  'uiElements.badge.basicExampleSubTitle':
    'Simplest Usage. Badge will be hidden when count is 0   but we can use showZero to show it.',
  'uiElements.badge.overflowCount': 'Overflow Count',
  'uiElements.badge.overflowCountSubTitle':
    'OverflowCount is displayed when count is larger than overflowCount. The default value of overflowCount is 99.',
  'uiElements.badge.status': 'Status',
  'uiElements.badge.statusSubTitle': 'Standalone badge with status.',
  'uiElements.badge.success': 'Success',
  'uiElements.badge.error': 'Error',
  'uiElements.badge.default': 'Default',
  'uiElements.badge.processing': 'Processing',
  'uiElements.badge.warning': 'Warning',
  'uiElements.badge.redBadge': 'Red badge',
  'uiElements.badge.redBadgeSubTitle':
    'This will simply display a red badge   without a specific count.',
  'uiElements.badge.linkSomething': 'Link something',
  'uiElements.cards.cards': 'Cards',
  'uiElements.cards.basicCard': 'Basic card',
  'uiElements.cards.basicCardSubTitle':
    'A basic card containing a title   content and an extra corner content.',
  'uiElements.cards.more': 'More',
  'uiElements.cards.cardTitle': 'Card Title',
  'uiElements.cards.cardContent': 'Card content',
  'uiElements.cards.lorem':
    'Lorem ipsum dolor sit amet   consectetur adipisicing elit   sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam   quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  'uiElements.cards.noBorder': 'No Border',
  'uiElements.cards.noBorderSubTitle':
    'A borderless card on a gray background.',
  'uiElements.cards.gridCard': 'Grid card',
  'uiElements.cards.gridCardSubTitle':
    'Cards usually cooperate with grid layout in overview page.',
  'uiElements.cards.loadingCard': 'Loading card',
  'uiElements.cards.loadingCardSubTitle':
    'Shows a loading indicator while the contents of the card is being fetched.',
  'uiElements.cards.whateverContent': 'Whatever content',
  'uiElements.cards.customizedContentTitle': 'Customized Content',
  'uiElements.cards.customizedContent':
    'Shows a loading indicator while the contents of the card is being fetched.',
  'uiElements.cards.europeStreetBeat': 'Europe Street beat',
  'uiElements.cards.instagram': 'www.instagram.com',
  'uiElements.carousel.carousel': 'Carousal',
  'uiElements.carousel.verticalCarousel': 'Vertical Carousel',
  'uiElements.carousel.verticalCarouselSubTitle':
    'Vertical pagination. use   vertical=true',
  'uiElements.carousel.basicCarousel': 'Basic Carousel',
  'uiElements.carousel.basicCarouselSubTitle': 'Basic usage',
  'uiElements.carousel.fadeInTransition': 'Fade In Transition',
  'uiElements.carousel.fadeInTransitionSubTitle':
    'Slides use fade for transition.   effect=fade',
  'uiElements.carousel.scrollAutomatically': 'Scroll Automatically',
  'uiElements.carousel.scrollAutomaticallySubTitle':
    'Timing of scrolling to the next card  picture. autoplay',
  'uiElements.collapse.collapse': 'Collapse',
  'uiElements.collapse.collapseSubTitle':
    'More than one panel can be expanded at a time   the first panel is initialized to be active in this case. use   defaultActiveKey=  [keyNum]',
  'uiElements.collapse.text':
    'A dog is a type of domesticated animal. Known for its loyalty and faithfulness   it can be found as a welcome guest in many households across the world.',
  'uiElements.collapse.headerOne': 'This is panel header 1',
  'uiElements.collapse.headerTwo': 'This is panel header 2',
  'uiElements.collapse.headerThree': 'This is panel header 3',
  'uiElements.collapse.headerNested': 'This is panel nest panel',
  'uiElements.collapse.nestedExample': 'Nested Example',
  'uiElements.collapse.nestedExampleSubTitle':
    'Collapse is nested inside the Collapse.',
  'uiElements.collapse.borderlessExample': 'Borderless Example',
  'uiElements.collapse.borderlessExampleSubTitle':
    'A borderless style of Collapse. use   bordered=  false',
  'uiElements.collapse.accordion': 'Accordion',
  'uiElements.collapse.accordionSubTitle':
    'Accordion mode   only one panel can be expanded at a time. The first panel will be expanded by default. use accordion',
  'uiElements.popover.popover': 'Popover',
  'uiElements.popover.basicExample': 'Basic Example',
  'uiElements.popover.basicExampleSubTitle':
    'The most basic example. The size of the floating layer depends on the contents region.',
  'uiElements.popover.hoverMe': 'Hover me',
  'uiElements.popover.title': 'Title',
  'uiElements.popover.titleTrigger': 'Three ways to trigger',
  'uiElements.popover.titleTriggerSubTitle':
    'Mouse to click   focus and move in.',
  'uiElements.popover.focusMe': 'Focus me',
  'uiElements.popover.clickMe': 'Click me',
  'uiElements.popover.placement': 'Placement',
  'uiElements.popover.placementSubTitle':
    'There are 12 placement options available.',
  'uiElements.popover.top': 'Top',
  'uiElements.popover.topLeft': 'Top Left',
  'uiElements.popover.topRight': 'Top Right',
  'uiElements.popover.leftTop': 'Left Top',
  'uiElements.popover.left': 'Left',
  'uiElements.popover.leftBottom': 'Left Bottom',
  'uiElements.popover.rightTop': 'Right Top',
  'uiElements.popover.right': 'Right',
  'uiElements.popover.bottom': 'Bottom',
  'uiElements.popover.bottomLeft': 'Bottom Left',
  'uiElements.popover.bottomRight': 'Bottom Right',
  'uiElements.popover.boxTitle': 'Controlling the close of the dialog',
  'uiElements.popover.boxSubTitle':
    'Use visible prop to control the display of the card.',
  'uiElements.popover.TR': 'TR',
  'uiElements.popover.TL': 'TL',
  'uiElements.popover.LT': 'LT',
  'uiElements.popover.LB': 'LB',
  'uiElements.popover.RT': 'RT',
  'uiElements.popover.RB': 'RB',
  'uiElements.popover.BL': 'BL',
  'uiElements.popover.BR': 'BR',
  'uiElements.popover.close': 'Close',
  'uiElements.tooltip.tooltip': 'Tooltip',
  'uiElements.tooltip.tooltipContent': 'Tooltip Content',
  'uiElements.tooltip.basicExample': 'Basic Example',
  'uiElements.tooltip.basicExampleSubTitle': 'The simplest usage.',
  'uiElements.tooltip.placementTitle': 'Placement',
  'uiElements.tooltip.placementSubTitle':
    'The ToolTip has 12 placements choice.',
  'uiElements.tooltip.TL': 'TL',
  'uiElements.tooltip.TR': 'TR',
  'uiElements.tooltip.LT': 'LT',
  'uiElements.tooltip.LB': 'LB',
  'uiElements.tooltip.RT': 'RT',
  'uiElements.tooltip.RB': 'RB',
  'uiElements.tooltip.BL': 'BL',
  'uiElements.tooltip.BR': 'BR',
  'uiElements.tooltip.bottom': 'Bottom',
  'uiElements.tooltip.right': 'Right',
  'uiElements.tooltip.left': 'Left',
  'uiElements.tooltip.top': 'Top',
  'uiElements.tooltip.tooltipContentSpan':
    'Tooltip will show when mouse enter.',
  'uiElements.tooltip.contentSpan': 'Tooltip Content',
  'uiElements.tags.tags': 'Tags',
  'uiElements.tags.basicExample': 'Basic Example',
  'uiElements.tags.basicExampleSubTitle':
    'Usage of basic Tag   and it could be closable by set closable property. Closable Tag supports onClose afterClose events.',
  'uiElements.tags.tagOne': 'Tag 1',
  'uiElements.tags.tagTwo': 'Tag 2',
  'uiElements.tags.link': 'Link',
  'uiElements.tags.preventDefault': 'Prevent Default',
  'uiElements.tags.colorfulTag': 'Colorful Tag',
  'uiElements.tags.hotTags': 'Hot Tags',
  'uiElements.tags.hotTagsSubTitle': 'Select your favourite topics.',
  'uiElements.tags.hots': 'Hots',
  'uiElements.tags.addRemoveDynamically': 'Add & Remove Dynamically',
  'uiElements.tags.addRemoveDynamicallySubTitle':
    'Generating a set of Tags by array   you can add and remove dynamically. Its based on afterClose event   which will be triggered while the close animation end.',
  'uiElements.tags.newTag': '+ New Tag',
  'uiElements.timeline.timeline': 'Timeline',
  'uiElements.timeline.basicExample': 'Basic Example',
  'uiElements.timeline.basicTimeline': 'Basic timeline',
  'uiElements.timeline.lastNode': 'Last Node',
  'uiElements.timeline.lastNodeContent':
    'When the timeline is incomplete and ongoing   put a ghost node at last. set   pending=  true     or   pending=  a React Element',
  'uiElements.timeline.seeMore': 'See more',
  'uiElements.timeline.custom': 'Custom',
  'uiElements.timeline.customContent':
    'Set a node as an icon or other custom element.',
  'uiElements.timeline.colorExample': 'Color Example',
  'uiElements.timeline.colorExampleContent':
    'Set the color of circles. green means completed or success status   red means warning or error   and blue means ongoing or other default status.',
  'uiElements.timeline.createServiceSite': 'Create a services site 2015-09-01',
  'uiElements.timeline.solveInitialNetwork':
    'Solve initial network problems 2015-09-01',
  'uiElements.timeline.networkProblemSolved':
    'Network problems being solved 2015-09-01',
  'uiElements.timeline.technicalTesting': 'Technical testing 2015-09-01',
  'uiElements.dropdown.dropdown': 'Dropdown',
  'uiElements.dropdown.hoverDropdown': 'Hover Drop Down',
  'uiElements.dropdown.hoverMe': 'Hover me',
  'uiElements.dropdown.hoverPlacement': 'Hover Placement Drop Down',
  'uiElements.dropdown.hoverDisableLink': 'Hover Drop Down with Disable link',
  'uiElements.dropdown.clickedDropdown': 'Clicked Drop Down',
  'uiElements.dropdown.buttonDropdown': 'Button with dropdown menu',
  'uiElements.pagination.pagination': 'Pagination',
  'uiElements.pagination.basic': 'Basic',
  'uiElements.pagination.more': 'More',
  'uiElements.pagination.changer': 'Changer',
  'uiElements.pagination.jumper': 'Jumper',
  'uiElements.pagination.miniSize': 'Mini Size',
  'uiElements.pagination.simpleMode': 'Simple Mode',
  'uiElements.pagination.controlled': 'Controlled',
  'uiElements.pagination.totalNumber': 'Total Number',
  'uiElements.rating.rating': 'Rating',
  'uiElements.rating.basicExample': 'Basic Example',
  'uiElements.rating.basicExampleSubTitle': 'The simplest usage.',
  'uiElements.rating.halfStar': 'Half star',
  'uiElements.rating.halfStarSubTitle': 'Support select half star.',
  'uiElements.rating.showCopywriting': 'Show copywriting',
  'uiElements.rating.showCopywritingSubTitle':
    'Add copywriting in rate components.',
  'uiElements.rating.readOnly': 'Read only',
  'uiElements.rating.readOnlySubTitle':
    'Read only   cant use mouse to interact.',
  'uiElements.rating.otherCharacter': 'Other Character',
  'uiElements.rating.otherCharacterSubTitle':
    'Replace the default star to other character like alphabet   digit   iconfont or even Chinese word.',
  'uiElements.tree.tree': 'Tree',
  'uiElements.tree.basicExample': 'Basic example',
  'uiElements.tree.basicExampleSubTitle':
    'The most basic usage   tell you how to use checkable   selectable   disabled   defaultExpandKeys   and etc.',
  'uiElements.tree.basicControlledExample': 'Basic controlled example',
  'uiElements.tree.basicControlledExampleSubTitle': 'basic controlled example',
  'uiElements.tree.draggableExample': 'Draggable example',
  'uiElements.tree.draggableExampleSubTitle':
    'Drag treeNode to insert after the other treeNode or insert into the other parent TreeNode.',
  'uiElements.tree.loadAsync': 'Load data asynchronously',
  'uiElements.tree.loadAsyncSubTitle':
    'To load data asynchronously when click to expand a treeNode.',
  'uiElements.tree.searchableExample': 'Searchable example',
  'uiElements.tree.searchableExampleSubTitle': 'Searchable Tree',
  'uiElements.tree.treeWithLine': 'Tree With Line',
  'shuffle.descriptionOne': 'Netscape 2.0 ships   introducing Javascript',
  'shuffle.descriptionTwo': 'Jesse James Garrett releases AJAX spec',
  'shuffle.descriptionThree': 'jQuery 1.0 released',
  'shuffle.descriptionFour': 'First underscore.js commit',
  'shuffle.descriptionFive': 'Backbone.js becomes a thing',
  'shuffle.descriptionSix': 'Angular 1.0 released',
  'shuffle.descriptionSeven': 'React is open-sourced; developers rejoice',
  'toggle.list': 'List',
  'toggle.grid': 'Grid',
  'toggle.ascending': 'Ascending',
  'toggle.descending': 'Descending',
  'toggle.shuffle': 'Shuffle',
  'toggle.rotate': 'Rotate',
  'toggle.addItem': 'Add Item',
  'toggle.removeItem': 'Remove Item',
  'contactlist.searchContacts': 'Search Contacts',
  'contactlist.addNewContact': 'Add New Contact',
  'notes.ChoseColor': 'Choose a color for your note',
  'notes.addNote': 'Add New Note',
  'page404.title': '404',
  'page404.subTitle': 'Looks like you got lost',
  'page404.description':
    'The page youre looking for doesnt exist or has been moved.',
  'page404.backButton': 'BACK HOME',
  'page500.title': '500',
  'page500.subTitle': 'Internal Server Error',
  'page500.description': 'Something went wrong. Please try again later.',
  'page500.backButton': 'BACK HOME',
  'page.forgetPassTitle': 'Isomorphic',
  'page.forgetPassSubTitle': 'Forgot Password?',
  'page.forgetPassDescription':
    'Enter your email and we send you a reset link.',
  'page.sendRequest': 'Send request',
  'page.resetPassTitle': 'Isomorphic',
  'page.resetPassSubTitle': 'Reset Password',
  'page.resetPassDescription': 'Enter new password and confirm it.',
  'page.resetPassSave': 'Save',
  'page.signInTitle': 'VP Market Insight',
  'page.signInRememberMe': 'Remember me',
  'page.signInButton': 'Sign in',
  'page.signInPreview':
    'username: demo   password: demodemo   or just click on any button.',
  'page.signInFacebook': 'Sign in with Facebook',
  'page.signInGooglePlus': 'Sign in with Google Plus',
  'page.signInAuth0': 'Sign in with Auth0',
  'page.signInForgotPass': 'Forgot password?',
  'page.signInCreateAccount': 'Sign up and create an account!',
  'page.signUpTitle': 'VP Market Insight',
  'page.signUpTermsConditions': 'I agree with terms and condtions',
  'page.signUpButton': 'Sign Up',
  'page.signUpFacebook': 'Sign up with Facebook',
  'page.signUpGooglePlus': 'Sign up with Google Plus',
  'page.signUpAuth0': 'Sign Up with Auth0',
  'page.signUpAlreadyAccount': 'Already have an account? Sign in.',
  'dashboard.reportsrate.label': 'Nightly Rates',
  'widget.reportswidget.label': 'Income',
  'widget.reportswidget.details':
    'Lorem ipsum dolor sit amet   consectetur adipisicing elit   sed do eiusmod tempor',
  'widget.singleprogresswidget1.label': 'Marketing',
  'widget.singleprogresswidget2.label': 'Addvertisement',
  'widget.singleprogresswidget3.label': 'Consulting',
  'widget.singleprogresswidget4.label': 'Development',
  'widget.stickerwidget1.number': '210',
  'widget.stickerwidget1.text': 'Unread Email',
  'widget.stickerwidget2.number': '1749',
  'widget.stickerwidget2.text': 'Image Upload',
  'widget.stickerwidget3.number': '3024',
  'widget.stickerwidget3.text': 'Total Message',
  'widget.stickerwidget4.number': '54',
  'widget.stickerwidget4.text': 'Orders Post',
  'widget.salewidget1.label': 'Income',
  'widget.salewidget1.price': '$15000',
  'widget.salewidget1.details':
    'Lorem ipsum dolor sit amet   consectetur adipisicing elit   sed do eiusmod tempor',
  'widget.salewidget2.label': 'Income',
  'widget.salewidget2.price': '$15000',
  'widget.salewidget2.details':
    'Lorem ipsum dolor sit amet   consectetur adipisicing elit   sed do eiusmod tempor',
  'widget.salewidget3.label': 'Income',
  'widget.salewidget3.price': '$15000',
  'widget.salewidget3.details':
    'Lorem ipsum dolor sit amet   consectetur adipisicing elit   sed do eiusmod tempor',
  'widget.salewidget4.label': 'Income',
  'widget.salewidget4.price': '$15000',
  'widget.salewidget4.details':
    'Lorem ipsum dolor sit amet   consectetur adipisicing elit   sed do eiusmod tempor',
  'widget.cardwidget1.number': '110',
  'widget.cardwidget1.text': 'New Messages',
  'widget.cardwidget2.number': '100%',
  'widget.cardwidget2.text': 'Volume',
  'widget.cardwidget3.number': '137',
  'widget.cardwidget3.text': 'Achievement',
  'widget.progresswidget1.label': 'Download',
  'widget.progresswidget1.details': '50% Complete',
  'widget.progresswidget2.label': 'Support',
  'widget.progresswidget2.details': '80% Satisfied Customer',
  'widget.progresswidget3.label': 'Upload',
  'widget.progresswidget3.details': '65% Complete',
  'widget.vcardwidget.name': 'Jhon Doe',
  'widget.vcardwidget.title': 'Sr. iOS Developer',
  'widget.vcardwidget.description':
    'Lorem ipsum dolor sit amet   consectetur adipisicing elit   sed do eiusmod tempor ammet dolar consectetur adipisicing elit',
  'checkout.billingform.firstname': 'First Name',
  'checkout.billingform.lastname': 'Last Name',
  'checkout.billingform.company': 'Company Name',
  'checkout.billingform.email': 'Email Address',
  'checkout.billingform.mobile': 'Mobile No',
  'checkout.billingform.country': 'Country',
  'checkout.billingform.city': 'City',
  'checkout.billingform.address': 'Address',
  'checkout.billingform.addressoptional':
    'Apartment   suite   unit etc. (optional',
  'checkout.billingform.checkbox': 'Create an account?',
  'antTable.title.image': 'Image',
  'antTable.title.firstName': 'First Name',
  'antTable.title.lastName': 'Last Name',
  'antTable.title.city': 'City',
  'antTable.title.street': 'Street',
  'antTable.title.email': 'Email',
  'antTable.title.dob': 'DOB',
  'Map.leaflet.basicTitle': 'Basic Map',
  'Map.leaflet.basicMarkerTitle': 'Basic Map(With Default Marker',
  'Map.leaflet.leafletCustomMarkerTitle': 'Basic Map(With Custom Icon Marker)',
  'Map.leaflet.leafletCustomHtmlMarkerTitle':
    'Basic Map(With Custom Html Marker',
  'Map.leaflet.leafletMarkerClusterTitle': 'Basic Map(With Marker Cluster',
  'Map.leaflet.leafletRoutingTitle': 'Basic Map Routing',
  'Component.contacts.noOption': 'No contact found',
  'email.send': 'SEND',
  'email.cancel': 'CANCEL',
  'email.compose': 'COMPOSE',
  'email.noMessage': 'Please select a mail to read',
  'themeSwitcher.purchase': 'PURCHASE NOW',
  'themeSwitcher.settings': 'Settings',
  'sidebar.selectbox': 'Select',
  'sidebar.frappeChart': 'Frappe Charts',
  'topbar.help': 'Help',
  'topbar.logout': 'Logout',
  'topbar.viewAll': 'View All',
  'topbar.viewCart': 'View Cart',
  'topbar.totalPrice': 'Total Price',

  'user.profile': 'Profile',
  'user.subscription': 'Subscription',
  'user.billing': 'Billing',
  'property.property': 'Property',

  'dashboard.avgNightly': 'AVG Nightly Rates',
  'dashboard.marketAverage': 'Market Avg',
  'dashboard.expectedRevenue': 'Expected Revenue',
  'dashboard.daysBooked': 'Days Booked',
  'dashboard.bookingRate': 'Booking Rate',
  'dashboard.marketBookingTrend': 'Market Booking Trend',
  'dashboard.booked': 'Booked',

  'filter.rentalTypes': 'Rental Type',
  'filter.nightlyRate': 'Nightly Rate',
  'filter.amenities': 'Amenities',
  'filter.bedrooms': 'Bedrooms',
  'filter.guests': 'Guests',
  'filter.distance': 'Distance',
  'filter.distanceMeasure': 'miles',
  'filter.comps': 'Comps',
  'filter.showComps': 'Show Comps',

  'user.firstName': 'First Name',
  'user.lastName': 'Last Name',
  'user.notificationEmail': 'Notification Email',
  'user.changePassword': 'Change Password',
  'user.newPassword': 'New Password',
  'user.newPasswordConfirm': 'Confirm Password',

  'billing.cardHolder': 'Card Holder',
  'billing.validThru': 'Valid Thru',
  'billing.cvc': 'CVC Code',

  'listingCard.basePrice': 'BASE PRICE',
  'listingCard.cleaningFee': 'CLEANING FEE',
  'listingCard.days': '30-DAY EXPECTED REVENUE',
  'listingCard.rate': '30-DAY BOOKING RATE',

  'booking.competitionBooked': 'Competition Booked',

  'calendar.avgNightly': 'My AVG Nightly Rates',
  'calendar.compsLowest': 'Comps Lowest',
  'calendar.compsAvg': 'Comps AVG Rate',
  'calendar.compsHighest': 'Comps Highest',
  'calendar.occupancy': 'My Property Occupancy',
  'calendar.compOccupancy': 'Competition Occupancy',
  'calendar.daysBooked': 'Days Booked',
  'calendar.noData': 'No booking stats available',

  'mapPage.rental-type': 'RENTAL TYPE',
  'mapPage.rental-size': 'RENTAL SIZE',
  'mapPage.thirty-days': '30-DAY MARKET BOOKING',

  'infoPopup.avg_rate':
    'Base booking rate (excluding fees and taxes) over the next 30 days',
  'infoPopup.property':
    'If you need to remove this property from your account, please contact us: {email}',

  'propModal.title': 'Properties are limited',
  'propModal.body':
    "You've reached your limit.\nYour plan allows for {allowed_prop} properties. To add more, please upgrade.",
};

import {
  all,
  takeEvery,
  fork,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import actions from './actions';

import PropertyService from '../../services/PropertyService';

/**
 * Get properties filters state
 *
 * @param {object} state - Redux store state
 * @returns {object} - filters object
 */
export const getFilterState = state => {
  return state.Properties.filterStates;
};

export const getFilterDetailState = state => {
  return state.Properties.filterDetailStates;
};

export const getFilterMaintenanceState = state => {
  return state.Properties.filterMaintenanceStates;
};

/**
 * Get details for the selected property
 */
export function* getPropertyDetails() {
  yield takeLatest(actions.GET_PROPERTY_DETAILS, function*({ propertyID }) {
    yield put({
      type: actions.SET_PROPERTY_DETAILS_LOADING,
    });

    //  Fetch filters state
    const filterDetailState = yield select(getFilterDetailState);
    const filterMaintenanceState = yield select(getFilterMaintenanceState);
    const propertyDetails = yield call(PropertyService.getProperty, propertyID);

    yield put({
      type: actions.SET_PROPERTY_DETAILS,
      propertyDetails,
      filterDetailState,
      filterMaintenanceState,
    });

    yield put({
      type: actions.SET_PROPERTY_DETAILS_LOADED,
    });
  });
}

/**
 *  Fetch properties data from API
 *  using current filter state
 */
export function* getProperties() {
  //  Set loading state for properties
  yield put({
    type: actions.SET_PROPERTIES_LOADING,
  });

  //  Fetch filters state
  const filterState = yield select(getFilterState);

  //  Fetch properties
  yield takeEvery(actions.GET_PROPERTIES, function*() {
    const properties = yield call(
      PropertyService.getUserProperties,
      filterState
    );

    //  Set loaded properties
    yield put({
      type: actions.SET_PROPERTIES,
      properties,
    });

    //  Set properties loaded
    yield put({
      type: actions.SET_PROPERTIES_LOADED,
    });
  });
}

export function* savePropertyDetails() {
  yield takeEvery(actions.SAVE_PROPERTY_DETAILS, function*({
    propertyDetails,
  }) {
    yield put({
      type: actions.SET_PROPERTY_DETAILS_LOADING,
    });

    const updatedProperty = yield call(
      PropertyService.saveProperty,
      propertyDetails
    );

    yield put({
      type: actions.SET_PROPERTY_DETAILS,
      propertyDetails: updatedProperty,
    });

    yield put({
      type: actions.SET_PROPERTY_DETAILS_LOADED,
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(getProperties),
    fork(getPropertyDetails),
    fork(savePropertyDetails),
  ]);
}

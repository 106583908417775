import actions from './actions';

const initState = {
  //  tasks Page
  tasks: null,
  tasksLoading: true,
  filterStates: {
    sorts: {
      name: 'Check In',
      direction: 'asc',
      col: 'arrival',
    },
    criteria: {
      search: '',
    },
  },
};

export default function tasksReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_TASKS:
      return {
        ...state,
        tasks: action.tasks,
      };
    case actions.SET_FILTER:
      return {
        ...state,
        filterStates: action.filterStates,
      };
    case actions.SET_TASKS_LOADED:
      return {
        ...state,
        tasksLoading: false,
      };
    case actions.SET_TASKS_LOADING:
      return {
        ...state,
        tasksLoading: true,
      };

    default:
      return state;
  }
}

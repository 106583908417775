import { getDefaultPath } from "../../helpers/urlSync";
import actions, { getView } from "./actions";

const preKeys = getDefaultPath();

const initState = {
  collapsed: false,
  view: getView(window.innerWidth),
  height: window.innerHeight,
  width: window.innerWidth,
  openDrawer: false,
  openKeys: preKeys,
  current: preKeys,
  propertySelectorLoading: false,
  menuActive: false
};

export default function appReducer(state = initState, action) {
  switch (action.type) {

    case actions.COLLPSE_CHANGE:
      return {
        ...state,
        collapsed: !state.collapsed
      };
    case actions.COLLPSE_OPEN_DRAWER:
      return {
        ...state,
        collapsed: state.openDrawer,
        openDrawer: !state.openDrawer
      };
    case actions.TOGGLE_ALL:
      if (state.view !== action.view || action.height !== state.height) {
        const height = action.height ? action.height : state.height;
        return {
          ...state,
          collapsed: action.collapsed,
          view: action.view,
          height
        };
      }
      break;
    case actions.CHANGE_OPEN_KEYS:
      return {
        ...state,
        openKeys: action.openKeys
      };
    case actions.CHANGE_CURRENT:
      return {
        ...state,
        current: action.current
      };
    case actions.CLEAR_MENU:
      return {
        ...state,
        openKeys: [],
        current: []
      };

    //  On starting property change, set state to loading
    case actions.PROPERTY_LOADING:
      return {
        ...state,
        propertySelectorLoading: true
      }

    case actions.CHANGE_PROPERTY:
      return {
        ...state,
        propertySelectorLoading: true
      }
    //  Do this also in case listing changes
    case actions.CHANGE_LISTING:
      return {
        ...state,
        propertySelectorLoading: true
      }
    //  On finished changing property, clear loading state
    case actions.PROPERTY_LOADED:
      return {
        ...state,
        propertySelectorLoading: false
      }

    case actions.RESIZE:
      return {
        ...state,
        width: action.width,
        height: action.height,
        collapsed: action.collapsed,
        view: getView(action.width),
      }

    case actions.SHOW_MENU:
      return {
        ...state,
        menuActive: true
      }

    case actions.HIDE_MENU:
      return {
        ...state,
        menuActive: false
      }

    case actions.SET_COLLAPSED_BREAKPOINT:
        return {
          ...state,
          collapsedBreakpoint: action.collapsedBreakpoint,
          collapsed: action.collapsed
        }
    case actions.SET_COLLAPSED:
        return {
          ...state,
          collapsed: action.collapsed
        }

    default:
      return state;
  }
  return state;
}

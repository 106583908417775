import React, { Component } from 'react';
import { connect, Provider } from "react-redux";
import { store, history } from '../redux/store';
import { withRouter } from 'react-router-dom';
import { ConnectedRouter } from "connected-react-router";
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';

import Notifications from 'react-notify-toast';

import PerfectScrollbar from 'react-perfect-scrollbar';

//  Actions
import appActions, { isMobile } from '../redux/app/actions';

//  New imports
import Router from './Router';

import Topbar from './common/Topbar';
import Sidebar from './common/Sidebar';

const { toggleAll, resize } = appActions;

class Main extends Component {
  /**
   * Set all viewport related properties in state on resize
   *
   * @param {object} event - resize event
   */
  resize = ( event ) => {
    const { resize, collapsedBreakpoint } = this.props;
    resize( window.innerWidth, window.innerHeight, collapsedBreakpoint );
  }

  componentDidMount() {
    window.addEventListener( "resize", this.resize );
  }

  onResize = ( windowSize ) => {
    const { toggleAll, collapsedBreakpoint } = this.props;

    toggleAll(
      windowSize.windowWidth,
      windowSize.windowHeight,
      collapsedBreakpoint
    )
  }

  render() {
    const { url } = this.props.match;
    const { isLoggedIn, view } = this.props;

    const mobile = isMobile( view );

    return (
      <Provider store={store}>

        <Debounce time="1000" handler="onResize">
          <WindowResizeListener onResize={ this.onResize } />
        </Debounce>

        <ConnectedRouter history={history}>
          <div className="flex relative w-full h-full">
            { isLoggedIn && (<>
              <Topbar />
              <Sidebar url={ url } />
            </>)}

            <Notifications />

            { mobile && (
              <div className="vpinsight__main w-full overflow-x-hidden overflow-y-scroll bg-container-bg">
                <div className="px-2.5 flex-no-shrink">
                  <Router />
                </div>
              </div>
            ) }

            { !mobile && (
              <PerfectScrollbar className={ "vpinsight__main w-full overflow-hidden bg-container-bg" }>
                <div className="px-7 flex-no-shrink">
                  <Router />
                </div>
              </PerfectScrollbar>
            ) }
          </div>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default connect(
  state => ({
    isLoggedIn: !!(state.Auth.idToken),
    view: state.App.view,
    collapsedBreakpoint: state.App.collapsedBreakpoint
  }),
  { toggleAll, resize }
)(withRouter(Main));

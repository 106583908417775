import React, { Component } from "react";
import { connect } from "react-redux";

import appActions, { isMobile } from "../../redux/app/actions";
// import notificationsActions from "../../redux/notifications/actions";

//  New components
//      Simple property selector
// import PropertySelector from "./PropertySelector";
//      Property selector with dropdown
import PropertySelectorDropdown from "./PropertySelectorDropdown";
//      Property selector with mobile modal
import PropertySelectorMobile from "./PropertySelectorMobile";
// //      Notification Menu
// import TopbarNotification from "./TopbarNotification";
//      User menu
import TopbarUser from "./TopbarUser";
//      Icon
import Icon from "./Icon";

//  SCSS
import "../../scss/components/Topbar.scss";
import "../../scss/components/PropertySelector.scss";

const { toggleCollapsed, showMenu } = appActions;

// const { getNotifications } = notificationsActions;

class Topbar extends Component {

    componentDidMount() {
        // this.props.getNotifications();
    }

    /**
     * Toggle Menu mobile modal
     *
     * @param {object} event - click event
     */
    showMenu = ( event ) => {
        const { showMenu } = this.props;
        showMenu();
    }

    render() {
        //  Properties
        const { locale, view, collapsed } = this.props;
        //  Classname for container element
        const headerClassname = "vpinsight__topbar fixed pin-t pin-l z-20 w-screen bg-white shadow-vp-header flex justify-between items-center " + ( isMobile( view ) ? "px-2.5 h-16 z-50" : "h-18" ) + ( collapsed ? " vpinsight__topbar_collapsed" : "" );

        // const notificationsAvailable = ( user && notifications && notifications.length > 0);

        //  For users with more than 5 properties, show advanced
        //  property selector with dropdown
        let propertySelector;

        if ( isMobile( view ) ) {
            propertySelector = ( <PropertySelectorMobile /> );
        } else {
            propertySelector = ( <PropertySelectorDropdown /> );
        }

        return (
            <section className={ headerClassname } >

                {/* Property selector component */}
                { propertySelector }

                { isMobile( view ) && (
                    <section className="flex justify-end items-center">
                        {/* Notification dot */}
                        {/* { ( notificationsAvailable && (notifications.length !== notifications.filter(n => n.viewed).length) ? (
                            <div className="vpinsight__topbar-notification-dot rounded-full shadow-vp-red mr-1"></div>
                        ) : "" ) } */}

                        {/* Toggle Menu modal */}
                        <button className="text-light-grey-blue opacity-80 z-20 text-lg2" onClick={ this.showMenu }>
                            <Icon name="hamburger" />
                        </button>
                    </section>
                ) }

                { !isMobile( view ) && (
                    <section className="right-section flex justify-end items-center">
                        {/* Notification Menu */}
                        {/* <div onClick={() => this.setState({ selectedItem: "notification" })}>
                            <TopbarNotification locale={locale} />
                        </div> */}

                        {/* User menu */}
                        <div onClick={() => this.setState({ selectedItem: "user" })}>
                            <TopbarUser locale={locale} user={this.props.user}/>
                        </div>
                    </section>
                ) }


            </section>
        );
    }
}

export default connect(
  state => ({
    ...state.App,
    locale: state.LanguageSwitcher.language.locale,
    customizedTheme: state.ThemeSwitcher.topbarTheme,
    user: state.Auth.currentUser,
    // notifications: state.Notifications.notifications,
  }),
  { toggleCollapsed, showMenu }
)(Topbar);

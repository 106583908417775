import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import Icon from "../Icon";
import { Modal } from "antd";

import {
  MenuItem,
  Select,
  InputLabel,
  TextField,
} from '@material-ui/core';

import { Textarea } from '../../../components/uielements/input';

// import ReviewColumn from "./ReviewColumn";
import { isMobile } from "../../../redux/app/actions";
import reservationsActions from "../../../redux/reservations/actions";
import MobileModal from "../MobileModal";

import { MODAL_CLOSE_CLASSNAME } from '../../../settings';

const {
  hideGuestReviewModal,
  getPublicCommentTemplates,
  getPrivateCommentTemplates,
  saveGuestReview,
} = reservationsActions;

class ReviewModal extends PureComponent {
  state = {
    reviewState: {
      public_comment_template: null,
      private_comment_template: null,
      cleanliness: 5,
      communication: 5,
      respect_house_rules: 5,
      recommend: true
    },
  };

  static propTypes = {
    expanded: PropTypes.bool,
    modal: PropTypes.bool,
    customerReservationId: PropTypes.number,
  };

  componentDidMount() {
    this.getPublicCommentTemplates();
    this.getPrivateCommentTemplates();
  }

  handleClose = event => {
    const { hideGuestReviewModal } = this.props;
    hideGuestReviewModal();
  }

  handleChange = propertyName => event => {
    const { reviewState } = this.state;
    this.setState({
      reviewState: {
        ...reviewState,
        [propertyName] : event.target.value,
      },
    });

  }

  handlePublicCommentChange = propertyName => event => {
    const { reviewState } = this.state;
    let pct = reviewState.public_comment_template;
    pct[propertyName] = event.target.value
    // console.log("public", pct);
    this.setState({
      reviewState:  {
        ...reviewState,
        "public_comment_template" : pct,
      },
    });
  }

  handlePrivateCommentChange = propertyName => event => {
    const { reviewState } = this.state;
    let pct = reviewState.private_comment_template;
    pct[propertyName] = event.target.value

    this.setState({
      reviewState:  {
        ...reviewState,
        "private_comment_template" : pct,
      },
    });
  }

  getPublicCommentTemplates = () => {
    const { getPublicCommentTemplates, customerReservationId } = this.props;

    getPublicCommentTemplates(customerReservationId);
  };

  getPrivateCommentTemplates = () => {
    const { getPrivateCommentTemplates, customerReservationId } = this.props;

    getPrivateCommentTemplates(customerReservationId);
  };

  saveAndSendReview = () => {
    const { saveGuestReview, customerReservationId } = this.props;
    const { reviewState } = this.state;
    // console.log(reviewState);
    saveGuestReview(reviewState, customerReservationId);
  };

  setupReviewState = () => {
    const { publicCommentTemplates, privateCommentTemplates } = this.props;
    const { reviewState } = this.state;

    if(publicCommentTemplates && publicCommentTemplates.length > 0){
      reviewState.public_comment_template = publicCommentTemplates[0];
    }
    if(privateCommentTemplates && privateCommentTemplates.length > 0){
      reviewState.private_comment_template = privateCommentTemplates[0];
    }
  };

  render() {
    const { modalActive, mobile, publicCommentTemplates, privateCommentTemplates } = this.props;
    const { reviewState } = this.state;
    this.setupReviewState();

    const publicCommentOptions =
      publicCommentTemplates &&
      publicCommentTemplates.length > 0 &&
      publicCommentTemplates.map(c => (
        <MenuItem key={c.id} value={c.id}>
          {c.name}
        </MenuItem>
      ));
    const privateCommentOptions =
      privateCommentTemplates &&
      privateCommentTemplates.length > 0 &&
      privateCommentTemplates.map(c => (
        <MenuItem key={c.id} value={c.id}>
          {c.name}
        </MenuItem>
      ));
    const selectFieldClass = `mb-8  ${mobile ? 'w-full' : 'pr-8 w-1/2'}`;
    const textFieldClass = `mb-8  ${mobile ? 'w-full' : 'pr-8 w-1/3'}`;
    const areaFieldClass = `mb-8  ${mobile ? 'w-full' : 'h-8 w-full'}`;
    const titleClassName = `flex justify-between  ${
      mobile ? 'flex-col pb-8' : 'pb-50px'
    }`;
    // console.log(mobile);
    // console.log(reviewState);

    return (<>
      { !mobile && (
        <Modal
          title={ null }
          footer={ null }
          visible={ modalActive }
          onOk={ this.handleClose }
          onCancel={ this.handleClose }
          closable={ false }
          centered={true}
          wrapClassName="reviews__review-modal-wrap"
          maskClosable={ true }
          maskStyle={ {
            backgroundColor: 'rgba( 0,0,0,0.82 )'
          } }
        >
          {reviewState && (
            <>
            <div >
              <div className={titleClassName}>
                <h1>Send Guest Review</h1>
              </div>
              <div className="w-full flex flex-wrap">
                <div className={selectFieldClass}>
                  <InputLabel shrink id="cleaner-label">
                    Public Comment Messages
                  </InputLabel>
                  <Select
                    id="assigned-cleaner"
                    value={reviewState.public_comment_template ? reviewState.public_comment_template.id : -1}
                    className={'w-full'}
                    onChange={this.handleChange('public_comment_template')}
                  >
                    {publicCommentOptions}
                  </Select>
                </div>
                <div className={selectFieldClass}>
                  <InputLabel shrink id="cleaner-label">
                    Private Comment Messages
                  </InputLabel>
                  <Select
                    id="assigned-cleaner"
                    value={reviewState.private_comment_template ? reviewState.private_comment_template.id : -1}
                    className={'w-full'}
                    onChange={this.handleChange('privateCommentTemplates')}
                  >
                    {privateCommentOptions}
                  </Select>
                </div>

                <div className="h-8 w-full"></div>

                <div className={areaFieldClass}>
                  <InputLabel>Public Comment</InputLabel>
                  <Textarea
                    name="public_comment"
                    placeholder="Public comment here..."
                    value={reviewState.public_comment_template ? reviewState.public_comment_template.message : null}
                    required
                    onChange={this.handlePublicCommentChange('message')}
                  />
                </div>

                <div className="h-8 w-full"></div>

                <div className={areaFieldClass}>
                  <InputLabel>Private Comment</InputLabel>
                  <Textarea
                    name="private_comment"
                    placeholder="Private comment here..."
                    value={reviewState.private_comment_template ? reviewState.private_comment_template.message : null}
                    required
                    onChange={this.handlePrivateCommentChange('message')}
                  />
                </div>

                <div className="h-8 w-full"></div>

                <div className={textFieldClass}>
                  <TextField
                    id="cleanliness"
                    label="Cleanliness"
                    value={reviewState.cleanliness}
                    type="number"
                    className={'w-full'}
                    onChange={this.handleChange('cleanliness')}
                  />
                </div>
                <div className={textFieldClass}>
                  <TextField
                    id="communication"
                    label="Communication"
                    value={reviewState.communication}
                    type="number"
                    className={'w-full'}
                    onChange={this.handleChange('communication')}
                  />
                </div>
                <div className={textFieldClass}>
                  <TextField
                    id="respect_house_rules"
                    label="Respect House Rules"
                    value={reviewState.respect_house_rules}
                    type="number"
                    className={'w-full'}
                    onChange={this.handleChange('respect_house_rules')}
                  />
                </div>

                <div className="h-8 w-full"></div>

                <button
                    className={
                      'flex align-center justify-center min-w-8 h-8 px-2 mx-2 text-white` overflow-hidden rounded-full bg-water-blue hover:bg-water-blue-hover hover:text-white'
                    }
                    onClick={this.saveAndSendReview}
                    alt="save"
                  >
                    Submit Review
                  </button>

              </div>
            </div>

            <button onClick={this.handleClose} className={ MODAL_CLOSE_CLASSNAME }>
              <Icon name="cross" />
            </button>
            </>
          )}

        </Modal>
      ) }

      { (mobile && modalActive) &&
        <MobileModal
          active={ modalActive }
          onHide={ this.handleClose }
          theme={ "dark" }
          containerClass={ "flex flex-col px-2.5 pt-4 pb-32 justify-center" }
        >
          {/* <ReviewColumn review={ modalReview } expanded={ expanded } modal={ false } closeOnUpdate mobile/> */}
        </MobileModal>
      }
    </>);
  }
}

const mapStateToProps = (state) => ({
  modalActive: state.Reservations.guestReviewModalVisible,
  publicCommentTemplates: state.Reservations.publicCommentTemplates,
  privateCommentTemplates: state.Reservations.privateCommentTemplates,
  mobile: isMobile( state.App.view )
})

const mapDispatchToProps = {
  hideGuestReviewModal,
  getPublicCommentTemplates,
  getPrivateCommentTemplates,
  saveGuestReview
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewModal);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import MomentUtils from '@date-io/moment';
import { Link } from 'react-router-dom';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { isMobile } from '../../redux/app/actions';
import { withRouter } from 'react-router';
import _ from 'lodash';
import moment from 'moment';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import HeaderPropertyDetailSort from '../../components/common/HeaderPropertyDetailSort';
import HeaderPropertyMaintSort from '../../components/common/HeaderPropertyMaintSort';
import propertiesActions from '../../redux/properties/actions';
import { Menu, TextField } from '@material-ui/core';
import LoadingAnimation from '../../components/common/LoadingAnimation';
import Tooltip from 'react-tooltip-lite';

//  Icons
import {
  faChevronLeft,
  faSave,
  faTimes,
  faEllipsisV,
} from '@fortawesome/fontawesome-free-solid';

const { getPropertyDetails, saveProperty } = propertiesActions;

function isNull(value) {
  return value == null;
}

export class PropertyDetails extends Component {
  state = {
    detailsSearch: '',
    propertyState: null,
  };

  /**
   * Open property actions menu
   *
   * @param {object} event - click event
   */
  actionsToggle = event => {
    this.setState({
      actionsOpen: true,
    });
  };

  /**
   * Close property actions menu
   *
   * @param {object} event - click event
   */
  actionsClose = event => {
    this.setState({
      actionsOpen: false,
    });
  };

  handleChange = propertyName => event => {
    const { propertyState } = this.state;
    this.setState({
      propertyState: _.set(propertyState, propertyName, event.target.value),
    });
  };

  revertChanges = e => {
    this.setState({
      propertyState: _.cloneDeep(this.props.propertyDetails),
    });
  };

  saveProperty = () => {
    const { propertyState } = this.state;
    const { saveProperty } = this.props;
    saveProperty(propertyState);
  };

  getPropertyDetails = () => {
    const { getPropertyDetails } = this.props;
    const { id } = this.props.match.params;

    getPropertyDetails(id);
  };

  constructor(props) {
    super(props);

    this.actionsAnchor = React.createRef();
  }

  componentDidMount() {
    this.getPropertyDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    const { detailsSearch } = this.state;
    const { detailsSearch: prevDetailsSearch } = prevState;
    const { filterDetailStates } = this.props;
    //  Copy property details from props to state
    //  for purposes of editing
    if (!_.isEqual(prevProps.propertyDetails, this.props.propertyDetails)) {
      this.setState({
        propertyState: _.cloneDeep(this.props.propertyDetails),
      });
    }

    if (detailsSearch !== prevDetailsSearch) {
      filterDetailStates.criteria.search = detailsSearch;
      this.setFilter(filterDetailStates);
    }
  }

  setFilter = filterDetailStates => {
    const { setFilter } = this.props;
    setFilter(filterDetailStates);
  };

  render() {
    const {
      propertyDetails,
      propertyDetailsLoading,
      filterDetailStates,
      filterMaintenanceStates,
      view,
    } = this.props;
    const { propertyState, actionsOpen, detailsSearch } = this.state;
    const edited = !_.isEqual(propertyState, propertyDetails);

    const mobile = isMobile(view);

    const cardClass = `relative rounded-lg bg-white shadow-vp-default flex flex-col ${
      mobile ? 'overflow-hidden w-auto p-8 mb-2.5' : 'p-50px m-2 mb-7'
    }`;
    const titleClassName = `flex justify-between  ${
      mobile ? 'flex-col pb-8' : 'pb-50px'
    }`;
    const textFieldClass = `mb-8  ${mobile ? 'w-full' : 'pr-8 w-1/3'}`;
    const tableContainerClass = `${
      mobile ? 'w-full overflow-x-scroll -ml-50px' : 'w-full'
    }`;
    const tableClass = `text-left ${mobile ? '' : 'min-w-full'}`;
    const rowClass = `cursor-pointer`;
    const cellClass = `border-b border-grey-light ${
      mobile ? 'py-2 px-4' : 'py-4'
    }`;

    const sorting = filterDetailStates.sorts;
    const maintSorting = filterMaintenanceStates.sorts;

    if (propertyState) {
      // console.log(propertyState.property_details);
    }

    const filteredSortedDetails = _(
      propertyState && propertyState.property_details
        ? propertyState.property_details
        : []
    )
      .filter(detail => {
        const detail_type = detail.property_detail_type.name.toLowerCase();
        const name = detail.name.toLowerCase();
        const notes = detail.notes ? detail.notes.toLowerCase() : '';

        // console.log(detailsSearch);

        if (
          name.indexOf(detailsSearch) !== -1 ||
          detail_type.indexOf(detailsSearch) !== -1 ||
          notes.indexOf(detailsSearch) !== -1
        ) {
          return true;
        } else {
          return false;
        }
      })
      .orderBy([sorting.col], [sorting.direction])
      .value();

    const filteredSortedMaintenances = _(
      propertyState && propertyState.property_maintenances
        ? propertyState.property_maintenances
        : []
    )
      .filter(detail => {
        const maintenance_type = detail.maintenance_type.toLowerCase();
        const maintenance_date = detail.maintenance_date.toLowerCase();
        const notes = detail.notes ? detail.notes.toLowerCase() : '';

        // console.log(detailsSearch);

        if (
          maintenance_type.indexOf(detailsSearch) !== -1 ||
          maintenance_date.indexOf(detailsSearch) !== -1 ||
          notes.indexOf(detailsSearch) !== -1
        ) {
          return true;
        } else {
          return false;
        }
      })
      .orderBy([maintSorting.col], [maintSorting.direction])
      .value();

    const detailsTable =
      !isNull(propertyState) &&
      filteredSortedDetails &&
      filteredSortedDetails.map(det => (
        <tr
          key={det.id}
          className={rowClass + ' hover:bg-grey-backdrop-transparent '}
        >
          <td className={cellClass}>{det.property_detail_type.name}</td>
          <td className={cellClass}>{det.name}</td>
          <td className={cellClass}>{det.notes}</td>
        </tr>
      ));

    const maintenanceTable =
      !isNull(propertyState) &&
      filteredSortedMaintenances &&
      filteredSortedMaintenances.map(det => (
        <tr
          key={det.id}
          className={rowClass + ' hover:bg-grey-backdrop-transparent '}
        >
          <td className={cellClass}>
            {moment(det.maintenance_date)
              .utc()
              .format('MM-DD-YYYY')}
          </td>
          <td className={cellClass}>{det.maintenance_type}</td>
          <td className={cellClass}>{det.notes}</td>
        </tr>
      ));

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className="flex flex-col w-full h-full overflow-x-hidden">
          <div
            className={`w-full flex-no-shrink ${
              mobile ? 'mb-2.5 text-lg' : 'mb-6'
            }`}
          >
            <Link to={`/properties`}>
              <FontAwesomeIcon icon={faChevronLeft} /> Back to list
            </Link>
          </div>

          {/* View section */}
          <div className={cardClass + ' flex-no-shrink'}>
            <div className={titleClassName}>
              {/* Title */}
              <div>
                <div className="text-base">Property Details</div>

                <h1>
                  {propertyDetailsLoading || isNull(propertyState) ? (
                    'Loading...'
                  ) : (
                    <>{propertyState.name}</>
                  )}
                </h1>
              </div>

              {/* Buttons */}
              {!propertyDetailsLoading && !isNull(propertyState) && (
                <div
                  className={`flex ${
                    mobile
                      ? 'absolute bg-white pin-t pin-r mt-4 mr-2.5 justify-end'
                      : 'justify-between'
                  }`}
                >
                  <Tooltip content="Save property details">
                    <button
                      className={
                        'flex align-center justify-center min-w-8 h-8 px-2 mx-2 text-lg overflow-hidden rounded-full bg-grey-backdrop-transparent hover:bg-leafy-green hover:text-white ' +
                        (edited ? '' : ' opacity-75 pointer-events-none')
                      }
                      onClick={this.saveProperty}
                      alt="save"
                    >
                      <FontAwesomeIcon icon={faSave} />
                    </button>
                  </Tooltip>

                  <Tooltip content="Revert all edits">
                    <button
                      className={
                        'flex align-center justify-center min-w-8 h-8 px-2 mx-2 text-lg overflow-hidden rounded-full bg-grey-backdrop-transparent hover:bg-holiday-red hover:text-white ' +
                        (edited ? '' : ' opacity-75 pointer-events-none')
                      }
                      onClick={this.revertChanges}
                      alt="save"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </Tooltip>

                  <Tooltip content="More Actions">
                    <button
                      className={
                        'flex align-center justify-center min-w-8 h-8 px-2 mx-2 text-lg overflow-hidden rounded-full bg-grey-backdrop-transparent hover:bg-grey-backdrop hover:text-white'
                      }
                      aria-controls="reservations-actions-menu"
                      aria-haspopup="true"
                      onClick={this.actionsToggle}
                      ref={this.actionsAnchor}
                      alt="save"
                    >
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </button>
                  </Tooltip>

                  <Menu
                    id="properties-actions-menu"
                    anchorEl={this.actionsAnchor.current}
                    keepMounted
                    open={actionsOpen || false}
                    onClose={this.actionsClose}
                  >
                    {/* <MenuItem onClick={this.emailConfirmation}>
                          {reservationDetails.emailed_confirmation ? (
                            <FontAwesomeIcon icon={faCheck} className="mr-4" />
                          ) : (
                            <div className="w-4 mr-4" />
                          )}
                          Email Confirmation
                        </MenuItem>
                        <MenuItem onClick={this.emailCheckin}>
                          {reservationDetails.checkin_emailed ? (
                            <FontAwesomeIcon icon={faCheck} className="mr-4" />
                          ) : (
                            <div className="w-4 mr-4" />
                          )}
                          Email Check-in Info
                        </MenuItem>
                        <MenuItem onClick={this.emailCleaner}>
                          {reservationDetails.emailed_cleaners ? (
                            <FontAwesomeIcon icon={faCheck} className="mr-4" />
                          ) : (
                            <div className="w-4 mr-4" />
                          )}
                          Email Cleaner
                        </MenuItem>
                        <MenuItem onClick={this.emailCleanerCancellation}>
                          <div className="w-4 mr-4" />
                          Email Cleaner Cancellation
                        </MenuItem>
                        <MenuItem onClick={this.cancelReservation}>
                          <FontAwesomeIcon icon={faBan} className="mr-4" />
                          Cancel Reservation
                        </MenuItem> */}
                  </Menu>
                </div>
              )}
            </div>

            {propertyDetailsLoading || isNull(propertyState) ? (
              <LoadingAnimation />
            ) : (
              <>
                <div className="w-full flex flex-wrap">
                  <div className={textFieldClass}>
                    <TextField
                      id="name"
                      label="Property"
                      className={'w-full'}
                      value={propertyState.name}
                      onChange={this.handleChange('name')}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="property-street"
                      label="Street"
                      className={'w-full'}
                      value={propertyState.street1}
                      onChange={this.handleChange('street1')}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="property-city"
                      label="City"
                      className={'w-full'}
                      value={propertyState.city.name}
                      onChange={this.handleChange('city.name')}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="property-state"
                      label="State"
                      className={'w-full'}
                      value={propertyState.state.name}
                      onChange={this.handleChange('state.name')}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="property-zip"
                      label="Zip"
                      className={'w-full'}
                      value={propertyState.zip}
                      onChange={this.handleChange('zip')}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="property-air"
                      label="Airbnb Listing Id"
                      className={'w-full'}
                      value={propertyState.airbnb_listing_id}
                      onChange={this.handleChange('airbnb_listing_id')}
                    />
                  </div>

                  <div className={textFieldClass}>
                    <TextField
                      id="property-vrbo"
                      label="VRBO Listing Id"
                      className={'w-full'}
                      value={propertyState.vrbo_listing_id}
                      onChange={this.handleChange('vrbo_listing_id')}
                    />
                  </div>

                  <div className="h-8 w-full"></div>
                </div>
                <div>
                  <div className="text-base">
                    <h2>Details</h2>
                  </div>
                  <div className={tableContainerClass}>
                    <table className={tableClass}>
                      <thead>
                        <tr className={rowClass}>
                          <HeaderPropertyDetailSort
                            className={
                              cellClass +
                              ' text-bold text-slate hover:text-coral'
                            }
                            name="Type"
                            column="property_detail_type.name"
                          />
                          <HeaderPropertyDetailSort
                            className={
                              cellClass +
                              ' text-bold text-slate hover:text-coral'
                            }
                            name="Value"
                            column="name"
                          />
                          <HeaderPropertyDetailSort
                            className={
                              cellClass +
                              ' text-bold text-slate hover:text-coral'
                            }
                            name="Notes"
                            column="notes"
                          />
                        </tr>
                      </thead>
                      <tbody>{detailsTable}</tbody>
                    </table>
                  </div>
                  <div className="h-8 w-full"></div>
                </div>
                <div>
                  <div className="text-base">
                    <h2>Maintenance Log</h2>
                  </div>
                  <div className={tableContainerClass}>
                    <table className={tableClass}>
                      <thead>
                        <tr className={rowClass}>
                          <HeaderPropertyMaintSort
                            className={
                              cellClass +
                              ' text-bold text-slate hover:text-coral'
                            }
                            name="Date"
                            column="maintenance_date"
                          />
                          <HeaderPropertyMaintSort
                            className={
                              cellClass +
                              ' text-bold text-slate hover:text-coral'
                            }
                            name="Type"
                            column="maintenance_type"
                          />
                          <HeaderPropertyMaintSort
                            className={
                              cellClass +
                              ' text-bold text-slate hover:text-coral'
                            }
                            name="Notes"
                            column="notes"
                          />
                        </tr>
                      </thead>
                      <tbody>{maintenanceTable}</tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default connect(
  state => ({
    propertyDetails: state.Properties.propertyDetails,
    filterDetailStates: state.Properties.filterDetailStates,
    filterMaintenanceStates: state.Properties.filterMaintenanceStates,
    propertyDetailsLoading: state.Properties.propertyDetailsLoading,
    view: state.App.view,
  }),
  {
    getPropertyDetails,
    saveProperty,
  }
)(withRouter(PropertyDetails));

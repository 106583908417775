import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router';
import moment from 'moment';

//  Actions
import payoutsActions from '../../redux/payouts/actions';
import { isMobile } from '../../redux/app/actions';
//  Components
import HeaderSort from '../../components/common/HeaderSort';
import LoadingAnimation from '../../components/common/LoadingAnimation';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import { FormattedNumber } from 'react-intl';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faCloudDownload from '@fortawesome/fontawesome-free-solid/faDownload';

//  Styles
import '../../scss/reservations.scss';

import { CSVLink } from 'react-csv';

const { getPayouts, getPayoutsCsv } = payoutsActions;

const date = new Date();

export class Payouts extends Component {
  state = {
    filterMonth: new Date(date.getFullYear(), date.getMonth(), 1).getTime(),
    byYear: false,
  };

  getPayouts = () => {
    const { getPayouts } = this.props;
    const { filterMonth, byYear } = this.state;
    getPayouts(filterMonth, byYear);
  };

  getPayoutsCsv = () => {
    const { getPayoutsCsv } = this.props;
    const { filterMonth, byYear } = this.state;
    getPayoutsCsv(filterMonth, byYear);
  };

  componentDidMount() {
    this.getPayouts();
  }

  componentDidUpdate(prevProps, prevState) {
    const { user } = this.props;
    const { user: prevUser } = prevProps;
    const { filterMonth } = this.state;
    const { byYear } = this.state;
    const { filterMonth: prevFilterMonth } = prevState;
    const { byYear: prevByYear } = prevState;

    if (
      !_.isEqual(user, prevUser) ||
      !_.isEqual(filterMonth, prevFilterMonth) ||
      !_.isEqual(byYear, prevByYear)
    ) {
      console.log('componentDidUpdate');
      this.getPayouts();
    }
  }

  /**
   *  Show data only for the selected month
   *
   *  @param {object} event - select event
   */
  filterMonth = event => {
    // console.log(event.target.name);
    // console.log(event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
    });

    // this.getPayouts();
  };

  toggleChecked = event => {
    // console.log(event.target.name);
    // console.log(event.target.checked);
    this.setState({
      [event.target.name]: event.target.checked,
    });

    // this.getPayouts();
  };

  render() {
    const { payouts, payoutsLoading, view } = this.props;
    const { filterMonth, byYear } = this.state;

    const mobile = isMobile(view);

    const cardClass = `relative rounded-lg bg-white shadow-vp-default flex flex-col ${
      mobile ? 'overflow-hidden w-auto p-8' : 'p-50px m-2'
    }`;
    const titleClassName = `flex justify-between  ${
      mobile ? 'flex-col pb-8' : 'pb-50px'
    }`;
    const tableContainerClass = `${
      mobile ? 'w-full overflow-x-scroll -ml-50px' : 'm-4 w-full'
    }`;
    const tableClass = `text-left ${mobile ? '' : 'min-w-full'}`;
    const rowClass = `cursor-pointer`;
    const cellClass = `border-b border-grey-light ${
      mobile ? 'py-2 px-4' : 'py-4 px-6'
    }`;
    const cellFootClass = `border-b ${mobile ? 'py-2 px-4' : 'py-4 px-6'}`;

    let format = { style: 'currency', currency: 'USD' };

    const payoutsTable =
      payouts &&
      payouts.month_data.map((payout, index) => (
        <tr
          key={index}
          className={rowClass + ' hover:bg-grey-backdrop-transparent '}
        >
          <td className={cellClass}>{payout.name}</td>
          <td className={cellClass}>
            {moment(payout.arrival)
              .utc()
              .format('MM-DD-YYYY')}
          </td>
          <td className={cellClass}>
            {moment(payout.departure)
              .utc()
              .format('MM-DD-YYYY')}
          </td>
          <td className={cellClass} style={{ textAlign: 'right' }}>
            <FormattedNumber {...format} value={payout.rent} />
          </td>
          <td className={cellClass} style={{ textAlign: 'right' }}>
            <FormattedNumber {...format} value={payout.additional_debit * -1} />
          </td>
          <td className={cellClass} style={{ textAlign: 'right' }}>
            <FormattedNumber {...format} value={payout.additional_credit} />
          </td>
          <td className={cellClass} style={{ textAlign: 'right' }}>
            <FormattedNumber {...format} value={payout.service_fee * -1} />
          </td>
          <td className={cellClass} style={{ textAlign: 'right' }}>
            <FormattedNumber {...format} value={payout.mgmt_fee * -1} />
          </td>
          <td className={cellClass} style={{ textAlign: 'right' }}>
            <FormattedNumber {...format} value={payout.owner_payout} />
          </td>
          <td className={cellClass}>{payout.note}</td>
        </tr>
      ));

    let months = [];

    for (let index = -12; index < 12; index++) {
      let monthIndex = date.getMonth() + index;
      let dt = new Date();
      dt.setMonth(monthIndex);
      months.push(new Date(dt.getFullYear(), dt.getMonth(), 1));
    }

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const monthMenu = months.map(month => {
      var newDate = new Date(month);
      return (
        <MenuItem value={newDate.getTime()} key={newDate.getTime()}>
          {monthNames[newDate.getMonth()] + ' ' + newDate.getFullYear()}
        </MenuItem>
      );
    });

    return (
      <div className="flex flex-col w-full h-full overflow-x-hidden">
        <div className={cardClass + ' flex-no-shrink'}>
          <div className={titleClassName}>
            <h1>Payouts</h1>

            <div>
              <CSVLink
                data={payouts ? payouts.month_data : []}
                headers={[
                  'name',
                  'arrival',
                  'departure',
                  'note',
                  'rent',
                  'mgmt_fee',
                  'service_fee',
                  'owner_payout',
                  'additional_debit',
                  'additional_credit',
                ]}
                filename={`payouts.csv`}
                target="_self"
                style={{ marginRight: '20px' }}
              >
                Download CSV
                <FontAwesomeIcon icon={faCloudDownload} />
              </CSVLink>
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={byYear}
                      onChange={this.toggleChecked}
                      color="primary"
                      inputProps={{
                        name: 'byYear',
                        id: 'show-by-year',
                      }}
                    />
                  }
                  label="Show Entire Year"
                  labelPlacement="top"
                />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="filter-month-select">Month</InputLabel>
                <Select
                  value={filterMonth}
                  onChange={this.filterMonth}
                  inputProps={{
                    name: 'filterMonth',
                    id: 'filter-month-select',
                  }}
                >
                  {monthMenu}
                </Select>
              </FormControl>
            </div>
          </div>

          {payoutsLoading && <LoadingAnimation />}

          {payouts && payouts.month_data.length > 0 && (
            <>
              <div className={tableContainerClass}>
                <table className={tableClass}>
                  <thead>
                    <tr className={rowClass}>
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="Name"
                        column="name"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="Arrival"
                        column="arrival"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="Departure"
                        column="departure"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        style={{ textAlign: 'right' }}
                        name="Rent"
                        column="rent"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        style={{ textAlign: 'right' }}
                        name="Debit"
                        column="debit"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        style={{ textAlign: 'right' }}
                        name="Credit"
                        column="credit"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        style={{ textAlign: 'right' }}
                        name="Service Fee"
                        column="sertviceFee"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        style={{ textAlign: 'right' }}
                        name="Mgmt Fee"
                        column="mgmtFee"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        style={{ textAlign: 'right' }}
                        name="Owner Payout"
                        column="payout"
                      />
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="Note"
                        column="note"
                      />
                    </tr>
                  </thead>
                  <tbody>{payoutsTable}</tbody>
                  <tfoot>
                    <tr className={rowClass}>
                      <td className={cellFootClass}>
                        <strong>Totals:</strong>
                      </td>
                      <td className={cellFootClass}></td>
                      <td className={cellFootClass}></td>
                      <td
                        className={cellFootClass}
                        style={{ textAlign: 'right' }}
                      >
                        <FormattedNumber
                          {...format}
                          value={payouts.month_rent}
                        />
                      </td>
                      <td
                        className={cellFootClass}
                        style={{ textAlign: 'right' }}
                      >
                        <FormattedNumber
                          {...format}
                          value={payouts.month_additional_debit * -1}
                        />
                      </td>
                      <td
                        className={cellFootClass}
                        style={{ textAlign: 'right' }}
                      >
                        <FormattedNumber
                          {...format}
                          value={payouts.month_additional_credit}
                        />
                      </td>
                      <td
                        className={cellFootClass}
                        style={{ textAlign: 'right' }}
                      >
                        <FormattedNumber
                          {...format}
                          value={payouts.month_service_fee * -1}
                        />
                      </td>
                      <td
                        className={cellFootClass}
                        style={{ textAlign: 'right' }}
                      >
                        <FormattedNumber
                          {...format}
                          value={payouts.month_mgmt_fee * -1}
                        />
                      </td>
                      <td
                        className={cellFootClass}
                        style={{ textAlign: 'right' }}
                      >
                        <strong>
                          <FormattedNumber
                            {...format}
                            value={payouts.month_owner_payout}
                          />
                        </strong>
                      </td>
                      <td className={cellFootClass}></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </>
          )}

          {payouts && payouts.month_data.length <= 0 && (
            <h4>No Payouts found for this month and property</h4>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  //  Current user
  user: state.Auth.currentUser,
  //  View
  view: state.App.view,
  //  Payouts state
  ...state.Payouts,
});

const mapDispatchToProps = {
  getPayouts,
  getPayoutsCsv,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Payouts));

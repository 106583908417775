import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';

//  Old pages
import UserNotifications from './account/UserNotifications';

import EmailTemplates from './email/EmailTemplates';
import EmailTemplateDetail from './email/EmailTemplateDetail';

import Settings from './account/Settings';
//  New Pages
import {
  Login,
  Reservations,
  ReservationDetails,
  Properties,
  PropertyDetails,
  Tasks,
  Payouts,
  Payments,
  Reports,
  ReportDetails,
  Taxes,
  Calendar,
} from '../containers';

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class Router extends React.Component {
  render() {
    const { history, isLoggedIn } = this.props;

    const restrictedRouteOptions = {
      isLoggedIn,
    };

    return (
      <Switch>
        <Route
          exact
          path="/login"
          render={() => {
            if (isLoggedIn) {
              return <Redirect to={{ pathname: '/reservations' }} />;
            }
            return <Login callback={this.getCurrentUser} history={history} />;
          }}
        />

        {/* Index page is Reservations */}
        <Route
          exact
          path="/"
          render={() => {
            if (isLoggedIn) {
              return <Redirect to={{ pathname: '/reservations' }} />;
            } else {
              return <Redirect to={{ pathname: '/login' }} />;
            }
          }}
        />

        <RestrictedRoute
          {...restrictedRouteOptions}
          exact
          path="/reservations"
          component={Reservations}
        />
        <RestrictedRoute
          {...restrictedRouteOptions}
          path="/reservations/:id"
          component={ReservationDetails}
        />
        <RestrictedRoute
          {...restrictedRouteOptions}
          exact
          path="/tasks"
          component={Tasks}
        />
        <RestrictedRoute
          {...restrictedRouteOptions}
          exact
          path="/payouts"
          component={Payouts}
        />
        <RestrictedRoute
          {...restrictedRouteOptions}
          exact
          path="/payments"
          component={Payments}
        />

        <RestrictedRoute
          {...restrictedRouteOptions}
          exact
          path="/properties"
          component={Properties}
        />
        <RestrictedRoute
          {...restrictedRouteOptions}
          path="/properties/:id"
          component={PropertyDetails}
        />

        <RestrictedRoute
          {...restrictedRouteOptions}
          exact
          path="/emails"
          component={EmailTemplates}
        />
        <RestrictedRoute
          {...restrictedRouteOptions}
          path="/emails/:id"
          component={EmailTemplateDetail}
        />

        <RestrictedRoute
          {...restrictedRouteOptions}
          exact
          path="/taxes"
          component={Taxes}
        />
        <RestrictedRoute
          {...restrictedRouteOptions}
          exact
          path="/reports"
          component={Reports}
        />
        <RestrictedRoute
          {...restrictedRouteOptions}
          path="/reports/:id"
          component={ReportDetails}
        />
        <RestrictedRoute
          {...restrictedRouteOptions}
          exact
          path="/calendar"
          component={Calendar}
        />
        <RestrictedRoute
          {...restrictedRouteOptions}
          exact
          path="/settings"
          component={Settings}
        />
        <RestrictedRoute
          {...restrictedRouteOptions}
          exact
          path="/notifications"
          component={UserNotifications}
        />
      </Switch>
    );
  }
}

export default connect(state => ({
  isLoggedIn: state.Auth.idToken !== null,
}))(Router);

import React from 'react';
import { connect } from 'react-redux';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faCaretUp from '@fortawesome/fontawesome-free-solid/faCaretUp';
import faCaretDown from '@fortawesome/fontawesome-free-solid/faCaretDown';

//  Actions
import propertyActions from '../../redux/properties/actions';

const { setMaintenanceFilter } = propertyActions;

class HeaderPropertyMaintSort extends React.Component {
  sortColumn = event => {
    const {
      name,
      column: col,
      filterMaintenanceStates,
      setMaintenanceFilter,
    } = this.props;

    const newFilter = {
      ...filterMaintenanceStates,
      sorts: {
        name,
        col,
        direction:
          filterMaintenanceStates.sorts.name === name
            ? filterMaintenanceStates.sorts.direction === 'asc'
              ? 'desc'
              : 'asc'
            : 'asc',
      },
    };

    setMaintenanceFilter(newFilter);
  };

  render() {
    const { className, name, filterMaintenanceStates } = this.props;

    const selected = name === filterMaintenanceStates.sorts.name;
    const asc = filterMaintenanceStates.sorts.direction === 'asc';

    return (
      <th className={className} nowrap="nowrap" onClick={this.sortColumn}>
        {name}{' '}
        {selected && <FontAwesomeIcon icon={asc ? faCaretDown : faCaretUp} />}
      </th>
    );
  }
}

export default connect(
  state => ({
    filterMaintenanceStates: state.Properties.filterMaintenanceStates,
  }),
  { setMaintenanceFilter }
)(HeaderPropertyMaintSort);

import { all, takeEvery, fork, call, put } from 'redux-saga/effects';

import actions from './actions';

import PayoutService from '../../services/PayoutService';

/**
 *  Fetch payouts data from API
 *  using current filter state
 */
export function* getPayouts() {
  //  Set loading state for payouts
  yield put({
    type: actions.SET_PAYOUTS_LOADING,
  });

  //  Fetch properties
  yield takeEvery(actions.GET_PAYOUTS, function*({ startDate, byYear }) {
    const payouts = yield call(PayoutService.getPayouts, startDate, byYear);

    // console.log(payouts);

    //  Set loaded properties
    yield put({
      type: actions.SET_PAYOUTS,
      payouts,
    });

    //  Set properties loaded
    yield put({
      type: actions.SET_PAYOUTS_LOADED,
    });
  });
}

export function* getPayoutsCsv() {
  //  Fetch properties
  yield takeEvery(actions.GET_PAYOUTS_CSV, function*({ startDate, byYear }) {
    const payouts = yield call(PayoutService.getPayoutsCsv, startDate, byYear);

    // console.log(payouts);

    //  Set loaded properties
    yield put({
      type: actions.SET_PAYOUTS_CSV,
      payouts,
    });
  });
}

export default function* rootSaga() {
  yield all([fork(getPayouts)]);
  yield all([fork(getPayoutsCsv)]);
}

export function getView( width ) {
  const views = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  }

  let view = "xl";

  if ( width <= views.lg ) {
    view = "lg";
  }

  if ( width <= views.md ) {
    view = "md";
  }

  if ( width <= views.sm ) {
    view = "sm";
  }

  return view;
}

/**
 * Get boolean value that represents
 * if drawer should be collapsed
 */
// export function getCollapsed = ( width, ) => {
//   const { width, setCollapsed } = this.props;
//   // const { openDrawer } = this.props.app;
//   const breakpoint = this.getCollapsedBreakpoint();

//   if ( width > breakpoint ) {
//       setCollapsed( false );
//       // return clone( openDrawer );
//   } else {
//       setCollapsed( true );
//       // return !clone( openDrawer );
//   }
// }

/**
 * returns true if mobile view needs to be rendered here and now
 *
 * @returns {boolean}
 */
export function isMobile( view ) {
  return view === "sm" || view === "md";
}

const actions = {
  COLLPSE_CHANGE: "COLLPSE_CHANGE",
  COLLPSE_OPEN_DRAWER: "COLLPSE_OPEN_DRAWER",
  CHANGE_OPEN_KEYS: "CHANGE_OPEN_KEYS",
  TOGGLE_ALL: "TOGGLE_ALL",
  CHANGE_CURRENT: "CHANGE_CURRENT",
  CLEAR_MENU: "CLEAR_MENU",
  PROPERTY_LOADING: "PROPERTY_LOADING",
  CHANGE_PROPERTY: "CHANGE_PROPERTY",
  CHANGE_LISTING: "CHANGE_LISTING",
  PROPERTY_LOADED: "PROPERTY_LOADED",
  SETUP_DEMO: "SETUP_DEMO",
  RESIZE: "RESIZE",
  SHOW_MENU: "SHOW_MENU",
  HIDE_MENU: "HIDE_MENU",
  SET_COLLAPSED: "SET_COLLAPSED",
  SET_COLLAPSED_BREAKPOINT: "SET_COLLAPSED_BREAKPOINT",

  toggleCollapsed: () => ({
    type: actions.COLLPSE_CHANGE
  }),
  changeProperty: key => {
    return {
      type: actions.CHANGE_PROPERTY,
      key: key
    };
  },
  changeListing: (key) => {
    return {
      type: actions.CHANGE_LISTING,
      key: key
    };
  },
  demoSetup: data => {
    return {
      type: actions.SETUP_DEMO,
      data: data
    };
  },
  toggleAll: ( width, height, collapsedBreakpoint ) => {
    return {
      type: actions.TOGGLE_ALL,
      collapsed: width < collapsedBreakpoint,
      view: getView(width),
      height
    };
  },
  setCollapsedBreakpoint: ( width, pathname, search ) => {
    let collapsedBreakpoint;

    switch ( pathname ) {
      case "/dashboard/settings":
          collapsedBreakpoint = 1400;
          break;

      default:
          collapsedBreakpoint = 1400;
          break;
    }

    return {
      type: actions.SET_COLLAPSED_BREAKPOINT,
      collapsedBreakpoint,
      collapsed: width < collapsedBreakpoint
    }
  },
  setCollapsed: ( collapsed ) => {
    return {
      type: actions.SET_COLLAPSED,
      collapsed
    }
  },
  toggleOpenDrawer: () => ({
    type: actions.COLLPSE_OPEN_DRAWER
  }),
  changeOpenKeys: openKeys => ({
    type: actions.CHANGE_OPEN_KEYS,
    openKeys
  }),
  changeCurrent: current => ({
    type: actions.CHANGE_CURRENT,
    current
  }),
  clearMenu: () => ({ type: actions.CLEAR_MENU }),

  //  Set all viewport related properties on resize
  resize: ( width, height, collapsedBreakpoint ) => {
    return {
      type: actions.RESIZE,
      collapsed: width < collapsedBreakpoint,
      width,
      height
    }
  },

  //
  showMenu: () => {
    return {
      type: actions.SHOW_MENU
    }
  },
  hideMenu: () => {
    return {
      type: actions.HIDE_MENU
    }
  }
};
export default actions;

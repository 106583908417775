import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router';

//  Actions
import reportsActions from '../../redux/reports/actions';
import { isMobile } from '../../redux/app/actions';
//  Components
import HeaderSort from '../../components/common/HeaderSort';
import LoadingAnimation from '../../components/common/LoadingAnimation';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import { faFileAlt } from '@fortawesome/fontawesome-free-solid';

//  Styles
import '../../scss/reservations.scss';

const { getReports } = reportsActions;

export class Reports extends Component {
  state = {};

  getReports = () => {
    const { getReports } = this.props;

    getReports();
  };

  /**
   * Redirect to reportDetail on row click
   *
   * @param {number} reportID - report's ID
   */
  reportDetail = reportID => {
    const { history } = this.props;

    // role check for this function
    // var match = user.roles.some(function(r) {
    //   return r.role_enum === 'ADMIN';
    // });

    // if (match) {
    history.push(`/reports/${reportID}`);
    // }
  };

  componentDidMount() {
    this.getReports();
  }

  componentDidUpdate(prevProps, prevState) {
    const { user } = this.props;
    const { user: prevUser } = prevProps;

    if (!_.isEqual(user, prevUser)) {
      this.getReports();
    }
  }

  render() {
    const { reports, reportsLoading, view } = this.props;

    const mobile = isMobile(view);

    const cardClass = `relative rounded-lg bg-white shadow-vp-default flex flex-col ${
      mobile ? 'overflow-hidden w-auto p-8' : 'p-50px m-2'
    }`;
    const titleClassName = `flex justify-between  ${
      mobile ? 'flex-col pb-8' : 'pb-50px'
    }`;
    const tableContainerClass = `${
      mobile ? 'w-full overflow-x-scroll -ml-50px' : 'm-4 w-full'
    }`;
    const tableClass = `text-left ${mobile ? '' : 'min-w-full'}`;
    const rowClass = `cursor-pointer`;
    const cellClass = `border-b border-grey-light ${
      mobile ? 'py-2 px-4' : 'py-4 px-6'
    }`;

    const reportsTable =
      reports &&
      reports.map((report, index) => (
        <tr
          key={index}
          className={rowClass + ' hover:bg-grey-backdrop-transparent '}
          onClick={() => {
            this.reportDetail(report.id);
          }}
        >
          <td className={cellClass}>
            <FontAwesomeIcon icon={faFileAlt} /> {report.name}
          </td>
        </tr>
      ));

    return (
      <div className="flex flex-col w-full h-full overflow-x-hidden">
        <div className={cardClass + ' flex-no-shrink'}>
          <div className={titleClassName}>
            <h1>Reports</h1>
          </div>

          {reportsLoading && <LoadingAnimation />}

          {reports && reports.length > 0 && (
            <>
              <div className={tableContainerClass}>
                <table className={tableClass}>
                  <thead>
                    <tr className={rowClass}>
                      <HeaderSort
                        className={
                          cellClass + ' text-bold text-slate hover:text-coral'
                        }
                        name="Name"
                        column="name"
                      />
                    </tr>
                  </thead>
                  <tbody>{reportsTable}</tbody>
                </table>
              </div>
            </>
          )}

          {reports && reports.length <= 0 && <h4>No Reports found</h4>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  //  Current user
  user: state.Auth.currentUser,
  //  View
  view: state.App.view,
  //  Reports state
  ...state.Reports,
});

const mapDispatchToProps = {
  getReports,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Reports));

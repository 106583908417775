import { all, takeEvery, fork, call, put } from 'redux-saga/effects';

import actions from './actions';

import TaxesService from '../../services/TaxesService';

/**
 * Get reservations filters state
 *
 * @param {object} state - Redux store state
 * @returns {object} - filters object
 */
export const getFilterState = (state) => {
    return state.Reservations.filterStates;
}

/**
 *  Fetch reservations data from API
 *  using current filter state
 */
export function* getTaxes() {
    //  Set loading state for reservations
    yield put({
        type: actions.SET_TAXES_LOADING
    });

    //  Fetch reservations
    yield takeEvery( actions.GET_TAXES, function*() {
        const taxes = yield call( TaxesService.getTaxes );

        //  Set loaded reservations
        yield put({
            type: actions.SET_TAXES,
            taxes
        });

        //  Set reservations loaded
        yield put({
            type: actions.SET_TAXES_LOADED
        });
    } );
}
export default function* rootSaga() {
    yield all([
      fork(getTaxes),
    ]);
}
const reportsActions = {
  //  Reports index page
  GET_REPORTS: 'GET_REPORTS',
  SET_REPORTS: 'SET_REPORTS',
  SET_REPORTS_LOADING: 'SET_REPORTS_LOADING',
  SET_REPORTS_LOADED: 'SET_REPORTS_LOADED',

  //  Report details page
  GET_REPORT_DETAILS: 'GET_REPORT_DETAILS',
  SET_REPORT_DETAILS_LOADING: 'SET_REPORT_DETAILS_LOADING',
  SET_REPORT_DETAILS: 'SET_REPORT_DETAILS',
  SET_REPORT_DETAILS_LOADED: 'SET_REPORT_DETAILS_LOADED',

  getReports() {
    return {
      type: reportsActions.GET_REPORTS,
    };
  },
  runReport(reportID, startDate, endDate) {
    return {
      type: reportsActions.GET_REPORT_DETAILS,
      reportID,
      startDate,
      endDate,
    };
  },
};

export default reportsActions;

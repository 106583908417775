import {
  all,
  takeEvery,
  takeLatest,
  fork,
  call,
  put,
} from 'redux-saga/effects';

import actions from './actions';

import ReportsService from '../../services/ReportsService';

/**
 *  Fetch reports data from API
 *  using current filter state
 */
export function* getReports() {
  //  Set loading state for reports
  yield put({
    type: actions.SET_REPORTS_LOADING,
  });

  //  Fetch properties
  yield takeEvery(actions.GET_REPORTS, function*() {
    const reports = yield call(ReportsService.getReports);

    //  Set loaded properties
    yield put({
      type: actions.SET_REPORTS,
      reports,
    });

    //  Set properties loaded
    yield put({
      type: actions.SET_REPORTS_LOADED,
    });
  });
}

/**
 * Get details for the selected report
 */
export function* getReportDetails() {
  yield takeLatest(actions.GET_REPORT_DETAILS, function*({
    reportID,
    startDate,
    endDate,
  }) {
    yield put({
      type: actions.SET_REPORT_DETAILS_LOADING,
    });

    const reportDetails = yield call(
      ReportsService.runReport,
      reportID,
      startDate,
      endDate
    );

    yield put({
      type: actions.SET_REPORT_DETAILS,
      reportDetails,
    });

    yield put({
      type: actions.SET_REPORT_DETAILS_LOADED,
    });
  });
}

export default function* rootSaga() {
  yield all([fork(getReports), fork(getReportDetails)]);
}

import SecureAxios from './SecureAxios';

class EmailService {
  getUserTemplates(filterStates) {
    return SecureAxios.get('/server/api/v1/email_templates?filter=' + JSON.stringify(filterStates));
  }
  getTemplate(id) {
    return SecureAxios.get('/server/api/v1/email_templates/' + id);
  }
  saveTemplate(template) {
    return SecureAxios.patch('/server/api/v1/email_templates/' + template.id, {'email_template': template});
  }

}

export default new EmailService();
